import moment from "moment";
import config from '../config/config';
import { DATE_FORMAT } from '../constants';

const { API_BASE_URL } = config;

// eslint-disable-next-line import/prefer-default-export
export const logoutAndClearData = keycloak =>
  keycloak.logout().then(() => {
    window.keycloak = null;
    window.location.href = '/';
  });


  export const getDatesForRange = (startDate, endDate) => {
    const startDateCopy = moment(startDate, DATE_FORMAT.ISO);
    const endDateCopy = moment(endDate, DATE_FORMAT.ISO);
  
    const dates = [];
    while (startDateCopy.isSameOrBefore(endDateCopy, 'day')) {
      dates.push(moment(startDateCopy));
      startDateCopy.add(1, 'days');
    }
    return dates;
  };

export const getISTTime = unixTimeInSeconds =>
  moment.unix(unixTimeInSeconds).format('hh:mm:ssa');

export const getApiRelativeUrl = url =>
  url.substring(API_BASE_URL.length)

export const getCurrentFinancialYear = () => {
  const today = new Date()
  const year = today.getFullYear()
  const month = today.getMonth() + 1 // getMonth() is zero-based

  let startYear
  let endYear
  if (month >= 4) {
    startYear = year
    endYear = year + 1
  } else {
    startYear = year - 1
    endYear = year
  }

  const fyString = `${startYear}-${endYear.toString().slice(-2)}`
  return { value: fyString, label: fyString }
}

export const convertDateIntoLocalString = updatedAt => {
  if (updatedAt === null || updatedAt === '') {
    return ''
  }
  // Parse the date string in "dd/mm/yyyy" format
  const [day, month, year] = updatedAt.split('/')
  const date = new Date(`${year}-${month}-${day}`)

  if (Number.isNaN(date)) {
    return '' // Return empty string for invalid dates
  }
  // console.log('updated date in convertor: ', updatedAt)
  const formattedDate = date.toLocaleDateString('en-GB')
  // console.log('updated formatted: ', formattedDate)
  return formattedDate
}




/* eslint-disable react/self-closing-comp */
/* eslint-disable vars-on-top */
/* eslint-disable eqeqeq */
/* eslint-disable prefer-const */
/* eslint-disable no-var */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-use-before-define */
/* eslint-disable array-callback-return */
/* eslint-disable camelcase */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-lonely-if */
/* eslint-disable object-shorthand */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import {
  Modal,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Form,
  Box,
  TextField,
  Grid,
  FormGroup,
  IconButton,
} from "@material-ui/core";
import WarningIcon from "@mui/icons-material/Warning";
import FormLabel from "@mui/material/FormLabel";
import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";
// for print user in consol
import jwt_decode from "jwt-decode";
import { useSelector } from "react-redux";
// for print user in consol
import axios from "axios";

import clsx from "clsx";
import { useHistory } from "react-router-dom";
// import Table from "../../components/Table/Table";
import Table from "../../components/Table1/Table1";

import styles from "./SupplierDataPurchase.module.css";
import { CustomFormGroup, CustomSelect } from "../../components/FormComponents";
import BackButton from "../../components/BackButton/BackButton";
import {
  DATE_FORMAT,
  API_RESOURCE_URLS,
  DISPLAY_MESSAGES,
  MESSAGE_TYPE,
  MESSAGE_TYPE_CERTIFICATION,
  MESSAGE_TYPE_ANNEXURE,
  RESOURCE_TYPE,
  USER_OPERATIONS,
  Regex,
} from "../../constants";
import { withAllowedOperationsProvider } from "../../hocs";
import { CustomTab, Button, AuthChecker } from "../../atomicComponents";
import { API } from "../../apis/api";
import { buildErrorMessage } from "../../apis/calls";
import { usePopupManager } from "../../providers/PopupManager/PopupManager";
import { GridLoadingSpinner } from "../../components";
import StepBar1 from '../../components/StepBar1/StepBar';
// import { error } from "jquery";

const VendarData = () => {


  const history = useHistory();
  const { showPopup } = usePopupManager();
  // const baseUrl='http://127.0.0.1:8000/file/'
  const baseUrl = useSelector((state) => state.counter.baseUrl); // useSelector
  const auth = useSelector((state) => state.auth); // useSelector
  // console.log('auth in Supplier: ', auth);
  const { userRole, userID, userName : username } = auth
  const [dynamicColumns, setDynamicColumns] = useState([]);
  const [dynamicColumns1, setDynamicColumns1] = useState([]);
  const [plant, setPlant] = useState(null);
  const [flag, show] = useState(false);
  const [checkDisable, setDisable] = useState(true);
  const [Bc, setBC] = useState(null);
  const [projectCode, setProjectCode] = useState(null)
  const [bcArray, setBcArray] = useState([]);
  const [plantArray, setPlantArray] = useState([]);
  const [tableArray, setTableArray] = useState([]);
  const [asnCount, setAsnCount] = useState(0);
  const [Fy, setFY] = useState(null)
  const [fyArray, setFyArray] = useState([])
  const [quarter, setQuarter] = useState(null)
  const [quarterArray, setQuarterArray] = useState([])
  const [projectCodeArray, setProjectCodeArray] = useState([])
  const [latestProject, setLatestProject] = useState("")
  const [selectedProjectCode, setSelectedProjectCode] = useState(null)
  const [recentProjectCodeDescription, setRecentProjectCodeDescription] = useState()
  const [tlmbslSubmit, setTmlbslSubmit] = useState(null)

  const [pliSubmissionStatus, setPliSubmissionStatus] = useState({})
  console.log('pliSubmissionStatus: ', pliSubmissionStatus)  

  const [selectedRow, setSelectedRow] = useState([]); 
  const handleOpen1 = (selected) => {setIsPopupOpen1(true); setSelectedRow(selected)};
  const [isPopupOpen1, setIsPopupOpen1] = useState(false); 
  const handleClose1 = () => setIsPopupOpen1(false);
  const [selectedRows, setSelectedRows] = useState([]);
console.log("selectedRowsselectedRows", selectedRows);
// const [comment, setComment] = useState(""); // Store the comment entered by the user


const [dataList, setDataList] = useState([]);

  const [loading, setLoader] = useState(false);
  const [loader, setLoading] = useState(true);
  const [vendCode, setvendCode] = useState();
  const [datesData, setDates] = useState({
    endDate: "",
    startDate: "",
  });

  // 
  const [isPopupOpenFormModal, setPopupOpenFormModal] = useState(false);
  const handleOpenFormModal = (selected) => {
    setSelectedRows(selected);
    setPopupOpenFormModal(true);
  }
  const handleCloseFormModal = (selected) => {
    setSelectedRows(selected);
    setPopupOpenFormModal(false);
    // setComment(""); // Reset the comment when the popup is closed
  }

  

  const ROW_HEIGHT = 38;
  const [isValidatePopupOpen, setIsValidatePopupOpen] = useState(false)
  const handleOpenValidatePopup = (selected) => {
    setSelectedRows(selected);
    setIsValidatePopupOpen(true)
  }
  const handleCloseValidatePopup = (selected) => {
    setIsValidatePopupOpen(false)
    setSelectedRows(selected);
  }

  const [isSubmissionPopupOpen, setIsSubmissionPopupOpen] = useState(false)
  const handleOpenSubmissionPopup = () => {setIsSubmissionPopupOpen(true)}
  const handleCloseSubmissionPopup = () => {setIsSubmissionPopupOpen(false)}

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const handleOpen = () => setIsPopupOpen(true);
  const handleClose = () => setIsPopupOpen(false);
  const goNext = () => {
    uploadFileData();
  };

  const redirectToApplicationLandingPage = () => {
    history.push("/");
  };

  const setCount = (count) => {
    setAsnCount(count);
  };
  // SubmitStatusUpdate

  const isAdminUser = userRole?.includes('admin')
  const isGDCUser = userRole?.includes('TMLBSL')
  const isPurchaseUser = userRole?.includes('Purchase')
  const isVendorUser = userRole?.includes('Vendor')
  const isPMTUser = userRole?.includes('PMT')
  const isProductLineUser = userRole?.includes('Product-line')
  const isBOEUser = userRole?.includes('BOE')

 useEffect(() => {
    // getData()
    getRecentProjectCode()
    getAllProjectCode()
    // getdate();
    //  Uploadcertification();
  }, []);

  useEffect(() => {

  }, [bcArray])
  console.log('Supplier BC: ', Bc);
  
  useEffect(() => {
    if (selectedProjectCode) {
      // Fetch suppliers related to the selected project code
      getData(selectedProjectCode);  
    }
  }, [selectedProjectCode]);
  
  // Handle dropdown changes
  const handleDropdownChange = (selectedOption, name) => {
    if (name === 'Project_Code') {
      // Update the selected project code
      setSelectedProjectCode(selectedOption);
      setBC(selectedOption);
    }
    else if (name === 'Supplier') {
      // Update the selected supplier
      setBC(selectedOption);
    }
  };

  const apiBody = {
    Project_code: projectCode?.value,
    Supplier_code: Bc?.value,
    Plant_code: plant?.value,
    Financial_year: Fy?.value,
    Quarter: quarter?.value
    }
    useEffect(() => {

    }, [bcArray])
    console.log('Supplier BC: ', Bc);
  const getRecentProjectCode = async (projectCodeOptions) => {
    try {
      // const { data, status } = await axios.get(`${baseUrl}LatestProjectCode/`);
      const { data, status } = await axios.get(`${baseUrl}LatestProjectCodeforProductline/`);
      if (status !== 200) {
        throw new Error("Failed to fetch the latest project code");
      }
      console.log('data in Project_code: ', data)
      const recentProj = {
        label: data?.Project_code,
        value: data?.Project_code,
      }
      setLatestProject(recentProj)
      setSelectedProjectCode(recentProj) // Automatically select the latest project code
      setProjectCode(recentProj)
      // getVcForProjectCode(recentProj)
      // const recentProjectCodeDescription = 
      getData(recentProj)
    } catch (error) {
      // console.error("Error fetching recent project codes:", error);
      showPopup({
        type: MESSAGE_TYPE.FAILURE,
        contextText: MESSAGE_TYPE.FAILURE,
        info: 'Could not fetch Project Code!',
      });
    }
  }

  const getAllProjectCode = async () => {
    try {
      // const { data } = await axios.get(`${baseUrl}AllProjectCode/`);
      const { data } = await axios.get(`${baseUrl}AllProjectCodeforProductline/`);
      const allProjectCodes = data.map(({ Project_code }) => Project_code);
      const uniqueProjects = [...new Set(allProjectCodes)];
      const projectCodeOptions = uniqueProjects.map((value) => ({
        value,
        label: value,
      }));

      setProjectCodeArray(projectCodeOptions)
      // const projectDesc = uniqueProjects?.maps((item) => ( item?.Project_code === latestProject))
      const projectDesc = uniqueProjects.map((item) => { 
        console.log('item: ', item); 
        return item?.Project_code === latestProject})
      console.log('projectDesc: ', projectDesc)
      console.log('projectdesc latest: ', latestProject, data)
      // const lastElement = array[array.length - 1];
      const latestProjectCodeDesc = data[data?.length - 1]?.Project_code_Description
      console.log('projectDesc last: ', latestProjectCodeDesc);
      setRecentProjectCodeDescription(latestProjectCodeDesc)
      // await getRecentProjectCode(projectCodeOptions);
      // const recentProjectCodeDescription = 
    } catch (error) {
      console.error("Error fetching project codes:", error);
    }
  }

  // const submitSupplierDVACollection = () => {

  //   const apiFormatedDataJson = JSON.stringify(apiBody);
  //   // const body = {
  //   const headerss = new Headers();
  //   headerss.append("Content-Type", "application/json");
  //   fetch(`${baseUrl}SubmitStatusUpdate/`, {
  //     method: "POST",
  //     headers: headerss,
  //     body: apiFormatedDataJson
  //   })
  //     .then((response) => response.json())
  //     .then((responseJson) => {
  //       // const endDate = responseJson[0].Collect_DVA_from_vendors_EndDate;
  //       // const startDate = responseJson[0].Collect_DVA_from_vendors_StartDate;
  //       // setDates((prevData)=>({...prevData,endDate,startDate}));
  //       console.log("responseJson", responseJson)
  //       showPopup({
  //         type: MESSAGE_TYPE.SUCCESS,
  //         contextText: MESSAGE_TYPE.SUCCESS,
  //         info: "Supplier DVA Submitted Successfully!",
  //       })
  //     })
  //     .catch((error) => { console.log('error:', error) });
  // };

  // const getdate = () => {

  //   const headerss = new Headers();
  //   headerss.append("Content-Type", "application/json");
  //   fetch(`${baseUrl}Milestone_calendarView/`, {
  //     method: "GET",
  //     headers: headerss,
  //   })
  //     .then((response) => response.json())
  //     .then((responseJson) => {
  //       const endDate = responseJson[0].Collect_DVA_from_vendors_EndDate;
  //       const startDate = responseJson[0].Collect_DVA_from_vendors_StartDate;
  //       setDates((prevData)=>({...prevData,endDate,startDate}));

  //     })
  //     .catch((error) => {});
  // };





  // useEffect(()=>{
  //   console.log("bcArray","effect")

  //   const {userRole,userID }=auth;

  //   if(userRole.includes("Vendor-CV") || userRole.includes("Vendor-PV"))
  //     {
  //    const singleVendor = bcArray.filter(element => element.value ==userID);
  //    if(singleVendor.length>=1)
  //    {
  //     setBC(singleVendor[0])
  //     console.log(bcArray,"render setbc",userID)
  //     setDisable(true)
  //    } else{
  //     console.log(bcArray,"render empty else",userID,singleVendor)

  //    }

  //    } else{
  //     setDisable(false)
  //    }

  // },[bcArray])
  //  useEffect(()=>{
  //   const {userRole,userID }=auth;

  //   if(userRole.includes("Vendor-CV") || userRole.includes("Vendor-PV"))
  //     {
  //       console.log(Bc,"render")
  //    showTable()
  //    } else{
  //     setDisable(false)
  //    }

  // },[Bc])

  const showTable = () => {
    // const { userRole } = auth
    if (userRole[0] === 'admin' || userRole[0] === 'TMLBSL' || userRole[0] === 'GDC' || userRole[0] === 'Purchase') {
      if (projectCode === null || projectCode.value === '') {
        showPopup({
          type: MESSAGE_TYPE.INFORMATION,
          contextText: MESSAGE_TYPE.INFORMATION,
          info: "Project Code is Mandatory",
        })
      } else {
        getSupplierDVAstatusForbutton()
        getTableData();
        show(true);
      }

    } else if (userRole[0] === 'Vendor') {
      if (projectCode === null || projectCode.value === '') {
        showPopup({
          type: MESSAGE_TYPE.INFORMATION,
          contextText: MESSAGE_TYPE.FAILURE,
          info: "Project Code is Mandatory",
        })
      } else if (Bc === null || Bc.value === '') {
        showPopup({
          type: MESSAGE_TYPE.INFORMATION,
          contextText: MESSAGE_TYPE.FAILURE,
          info: "Supplier Code is Mandatory",
        })

      }
      else if (plant === null || plant.value === '') {
        showPopup({
          type: MESSAGE_TYPE.INFORMATION,
          contextText: MESSAGE_TYPE.FAILURE,
          info: "Plant Code is Mandatory",
        })
      } else {
        getSupplierDVAstatusForbutton()
        getTableData();
        show(true);
      }

    }
    else {
      getSupplierDVAstatusForbutton()
      getTableData();
      show(true);

    }


  };

  const getSupplierDVAstatusForbutton = async () => {
    const apiFormatedData = {
      Project_code: projectCode?.value,
      Supplier_code: Bc?.value,
      Plant_code: plant?.value,

    }
    try {
      const response = await axios.post(
        `${baseUrl}SupplierDVAstatusForbutton/`,
        apiFormatedData,
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )
      console.log('Response Status: ', response)
      if (response.status === 200 || response.status === 201) {
        const tableData = response?.data
        console.log('Response Status: ', tableData[0])
        setPliSubmissionStatus(tableData[0])
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        console.error('Error 404: No records found')
      } else {
        console.error('Error fetching table data:', error)
      }
    }
  }

 
  // let Vendor_Code ='';
  const handleSelect = (selectedOption, name) => {
    // alert(selectedOption.value);
    // console.log(selectedOption);
    setvendCode(selectedOption.value);
    if (name == "VC") {
      show(false);

      setBC(selectedOption);
      // getPlantData(selectedOption)
    } else {
      setPlant(selectedOption);
    }
  };

  const getTableData = () => {


    // const apiFormatedData = {
    //   Supplier_Code: Bc?.value,
    //   Project_code: projectCode?.value,
    //   Financial_year: Fy?.value,
    //   Quarter: quarter?.value,
    //   Plant_code: plant?.value
    // };
    var apiFormatedDataJson = JSON.stringify(apiBody);
    let headerss = new Headers();
    headerss.append("Content-Type", "application/json");
    // const url = auth.userRole[0] === 'Vendor' ? 'SupplierDvaCollection' : 'SupplierDvaCollectionAdminGDC'
    const url = auth.userRole[0] === 'Vendor' ? 'ShowVendorData' : 'SupplierDvaCollectionAdminGDCshow'

    fetch(`${baseUrl}${url}/`, {
      method: "POST",
      headers: headerss,
      body: apiFormatedDataJson,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log('table data response', responseJson)
        const tableData = responseJson.data;

        setTableArray(tableData);
        // const excelData = tableData.map(
        //   (
        //     {
        //       Vendor_Code,
        //       Plant_code,
        //       Part_number,
        //       Part_Description,
        //       Selling_Price,
        //       Value_of_Direct_Import_by_us,
        //       Broad_Description_of_parts_imported_by_us,
        //       Value_of_parts_imported_by_our_suppliers,
        //       Broad_Description_of_parts_imported_by_our_suppliers,
        //       Local_Content,
        //       DVA,

        //     },
        //     id
        //   ) => {
        //     let obj = {
        //       id,
        //       Vendor_Code,
        //       Plant_code,
        //       Part_number,
        //       Part_Description,
        //       Selling_Price,
        //       Value_of_Direct_Import_by_us,
        //       Broad_Description_of_parts_imported_by_us,
        //       Value_of_parts_imported_by_our_suppliers,
        //       Broad_Description_of_parts_imported_by_our_suppliers,
        //       Local_Content,
        //       DVA,
        //     };
        //     return obj;
        //   }
        // );

        // setTableArray(excelData)
        // setTableArray(responseJson)
        setLoading(false)
      })
      .catch((error) => {
        console.log(error)

      });
  };

  // const getTableData1 = (value) => {
  //   const apiFormatedData = {
  //     VC: value,
  //   };
  //   var apiFormatedDataJson = JSON.stringify(apiFormatedData);
  //   let headerss = new Headers();
  //   headerss.append("Content-Type", "application/json");
  //   fetch(`${baseUrl}Venodr_wise_Data_By_Vendor_code/`, {
  //     method: "POST",
  //     headers: headerss,
  //     body: apiFormatedDataJson,
  //   })
  //     .then((response) => response.json())
  //     .then((responseJson) => {
  //       if (responseJson.data === null || responseJson.status === 500) {
  //         showPopup({
  //           type: MESSAGE_TYPE.FAILURE,
  //           contextText: MESSAGE_TYPE.FAILURE,
  //           info: "Data not found",

  //         })
  //       } else {
  //         const tableData = responseJson.data;
  //         setTableArray(tableData);
  //       }

  //       // const excelData = tableData.map(
  //       //   (
  //       //     {
  //       //       Vendor_Code,
  //       //       Plant_code,
  //       //       Part_number,
  //       //       Part_Description,
  //       //       Selling_Price,
  //       //       Value_of_Direct_Import_by_us,
  //       //       Broad_Description_of_parts_imported_by_us,
  //       //       Value_of_parts_imported_by_our_suppliers,
  //       //       Broad_Description_of_parts_imported_by_our_suppliers,
  //       //       Local_Content,
  //       //       DVA,

  //       //     },
  //       //     id
  //       //   ) => {
  //       //     let obj = {
  //       //       id,
  //       //       Vendor_Code,
  //       //       Plant_code,
  //       //       Part_number,
  //       //       Part_Description,
  //       //       Selling_Price,
  //       //       Value_of_Direct_Import_by_us,
  //       //       Broad_Description_of_parts_imported_by_us,
  //       //       Value_of_parts_imported_by_our_suppliers,
  //       //       Broad_Description_of_parts_imported_by_our_suppliers,
  //       //       Local_Content,
  //       //       DVA,
  //       //     };
  //       //     return obj;
  //       //   }
  //       // );

  //       // exportExcel(excelData);
  //     })
  //     .catch((error) => {
  //       console.log(error)
  //       setDisable(false)
  //       showPopup({
  //         type: MESSAGE_TYPE.FAILURE,
  //         contextText: MESSAGE_TYPE.FAILURE,
  //         info: `${error.message}`,
  //       });
  //     });
  // };

  // const getAllData = () => {
  //   let headerss = new Headers();
  //   headerss.append("Content-Type", "application/json");
  //   fetch(`${baseUrl}AllSupplierDvaCollectionData/`, {
  //     method: "GET",
  //     headers: headerss,

  //   }).then((response) => response.json())
  //     .then((responseJson) => {
  //       if (responseJson === null || responseJson.length === 0) {
  //         // setTableArray(responseJson)
  //         showPopup({
  //           type: MESSAGE_TYPE.FAILURE,
  //           contextText: MESSAGE_TYPE.FAILURE,
  //           info: "No data available",

  //         })

  //       }
  //       else {
  //         setTableArray(responseJson)
  //         show(true)
  //         setLoading(false)
  //       }

  //     }).catch((error) => {
  //       console.log('error', error)
  //       showPopup({
  //         type: MESSAGE_TYPE.FAILURE,
  //         contextText: MESSAGE_TYPE.FAILURE,
  //         info: 'Error while fetching data.',
  //       })


  //     })

  // }
  // console.log(auth.userRole)

  console.log('latestProject: ', latestProject?.value);
  const getData = (recentProj) => {
    let headerss = new Headers();
    headerss.append("Content-Type", "application/json");
    // const url = auth.userRole[0] === 'Vendor' ? 'SupplierDvaCollection' : 'SupplierDvaCollectionAdminGDC'
    const url = auth.userRole[0] === 'Vendor' ? 'ShowVendorData' : 'SupplierDvaCollectionAdminGDCshow'
    const formattedBody = {
      // Project_code: latestProject?.value
      Project_code: recentProj?.value
    }
    // fetch(`${baseUrl}SupplierDvaCollection/`, {
    fetch(`${baseUrl}LoadVendorcodeandplantwrtprojectcode/`, {
      method: "POST",
      headers: headerss,
      body: JSON.stringify(formattedBody)
      // body: formattedBody
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log('responseJson: ', responseJson);
      // Extract unique values for different fields
        const uniqueSuppliers = [...new Set(responseJson.map(item => item.Supplier_code))].filter(Boolean);
        const uniquePlants = [...new Set(responseJson.map(item => item.Plant_code))].filter(Boolean);
        const uniqueQuarters = [...new Set(responseJson.map(item => item.Quarter))].filter(Boolean);
        const uniqueYears = [...new Set(responseJson.map(item => item.Financial_year))].filter(Boolean);

        // Extract unique values for different fields
        // const uniqueSuppliers = [...new Set(responseJson.map(item => item.Supplier_Code))];
        // const uniqueProjects = [...new Set(responseJson.map(item => item.Project_code))];
        // const uniquePlants = [...new Set(responseJson.map(item => item.Plant_code))];
        // const uniqueQuarters = [...new Set(responseJson.map(item => item.Quarter))];
        // const uniqueYears = [...new Set(responseJson.map(item => item.Financial_year))];
        console.log('Filtered response1: ', uniqueSuppliers)
        console.log('Filtered response2: ', uniquePlants)
        console.log('Filtered response3: ', uniqueQuarters)
        console.log('Filtered response4: ', uniqueYears)

        let temp = []
        if (bcArray.length === 0) {
          // setBcArray(uniqueSuppliers.map(value => ({ value, label: value })));
          console.log('MApping vendor: ', uniqueSuppliers.map(value => ({ value, label: value })))
          temp = uniqueSuppliers.map(value => ({ value, label: value }))
          setBcArray(uniqueSuppliers.map(value => ({ value, label: value })));
        }
        console.log('Mapping bcArray:', bcArray, 'temp: ', temp)
        // setProjectCodeArray(uniqueProjects.map(value => ({ value, label: value })));
        setBcArray(uniqueSuppliers.map(value => ({ value, label: value })));
        console.log(projectCodeArray)
        setPlantArray(uniquePlants.map(value => ({ value, label: value })));
        setQuarterArray(uniqueQuarters.map(value => ({ value, label: value })));
        setFyArray(uniqueYears.map(value => ({ value, label: value })));

        // Extract userRole and userID from auth object
        // const { userRole, userID } = auth;
        console.log('User Role:', userRole, 'User ID:', userID);

        // Check user role to conditionally set BC state
        if (userRole.includes("Vendor-CV") || userRole.includes("Vendor")) {
          const singleVendor = bcArray.find(item => item.value === userID);
          const singleVendor1 = temp.find(item => item.value === userID);
          console.log('singleVendor: ', singleVendor, singleVendor1);
          if (singleVendor1) {
            setBC(singleVendor1);
            setDisable(true); // Disable UI elements
          }
        } else {
          setDisable(false); // Enable UI elements
        }
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        // Handle error (e.g., show error message)
      });
  };

  // const getData = () => {
  //   let headerss = new Headers();
  //   headerss.append("Content-Type", "application/json");
  //   fetch(`${baseUrl}SupplierDvaCollection/`, {
  //     method: "GET",
  //     headers: headerss,
  //   })
  //     .then((response) => response.json())
  //     .then((responseJson) => {
  //       const unique_Supplier = []
  //       const Supplier = responseJson.filter(({ Supplier_Code }) => {
  //         if (!unique_Supplier.includes(Supplier_Code)) {
  //           unique_Supplier.push(Supplier_Code); 
  //           return true; 
  //         }
  //         return false; 
  //       }).map(({ Supplier_Code }) => ({
  //         value: Supplier_Code,
  //         label: Supplier_Code,
  //       }));
  //       setBcArray(Supplier)
  //       console.log(bcArray)

  //       const projectCode_unique = []
  //       const project_Code_Array = responseJson.filter(({Project_code})=>{
  //         if (!projectCode_unique.includes(Project_code)) {
  //           projectCode_unique.push(Project_code); // Add to unique plants array
  //           return true; // Include this item in the filtered result
  //         }
  //         return false; 
  //       }).map(({Project_code})=>({ value: Project_code,label: Project_code}) )
  //       setProjectCodeArray(project_Code_Array)


  //       // const plant_Array = responseJson.filter(({ Plant_code }) => ({
  //       //   value: Plant_code,
  //       //   label: Plant_code,
  //       // }));
  //       const uniquePlants = []
  //       const plant_Array = responseJson.filter(({ Plant_code }) => {
  //         if (!uniquePlants.includes(Plant_code)) {
  //           uniquePlants.push(Plant_code); 
  //           return true; 
  //         }
  //         return false; 
  //       }).map(({ Plant_code }) => ({
  //         value: Plant_code,
  //         label: Plant_code,
  //       }));


  //       setPlantArray(plant_Array);

  //       const unique_quarter = []
  //       const quarter_array = responseJson.filter(({ Quarter }) => {
  //         if (!unique_quarter.includes(Quarter)) {
  //           unique_quarter.push(Quarter); 
  //           return true; 
  //         }
  //         return false; 
  //       }).map(({ Quarter }) => ({
  //         value: Quarter,
  //         label: Quarter,
  //       }));
  //       setQuarterArray(quarter_array)

  //       const unique_Fy = []
  //       const F_year = responseJson.filter(({ Financial_year }) => {
  //         if (!unique_Fy.includes(Financial_year)) {
  //           unique_Fy.push(Financial_year); 
  //           return true; 
  //         }
  //         return false; 
  //       }).map(({ Financial_year }) => ({
  //         value: Financial_year,
  //         label: Financial_year,
  //       }));
  //       setFyArray(F_year)

  //       let { userRole, userID } = auth;
  //       console.log('single userRole', userRole, 'userID', userID);
  //       // const item1 = bcArray.find((item)=> item.value === userID)
  //       // // setBC(vendorList[0])
  //       // if(item1){
  //       //   setBC(item1)
  //       // }

  //       // setBC(userID)
  //       if (userRole.includes("Vendor-CV") || userRole.includes("Vendor-CV") || userRole.includes("Vendor")) {
  //         // const singleVendor = bcArray.filter(
  //         //   (element) => element.value == userID
  //         // );
  //         const singleVendor = bcArray.find((item)=> item.value === userID)
  //         console.log('single vendor list', singleVendor);
  //         console.log('single ListArray', bcArray);
  //         // if (singleVendor.length >= 1) {
  //         if (singleVendor) {  
  //           setBC(singleVendor);
  //           console.log('single Vendor', singleVendor[0]);
  //           setDisable(true);
  //           // show(true); // table show
  //           // getTableData1(singleVendor[0].value);
  //         }
  //       } else {
  //         // setTableArray(responseJson)
  //         setDisable(false);
  //       }
  //       console.log(projectCodeArray)
  //     })
  //     .catch((error) => {});
  // };

  const uploadFileData = () => {
    var input = document.createElement("input");
    input.type = "file";
    input.id = "file";
    input.setAttribute(
      "accept",
      ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
    );
    input.click();
    input.onchange = (e) => {
      setLoader(true)
      const formData = new FormData();
      formData.append("file", e.target.files[0]);
      fetch(`${baseUrl}SupplierDataaddition/`, {
        method: "POST",
        body: formData,
      })
        .then((response) => {
          // eslint-disable-next-line radix
          let status1 = parseInt(response.status);
          if (status1 == "201") {
            //   document.removeChild(input);
            getTableData();
            showPopup({
              type: MESSAGE_TYPE.SUCCESS,
              contextText: MESSAGE_TYPE.SUCCESS,
              info: "file uploaded sucessfully",
            });
          } else {
            showPopup({
              type: MESSAGE_TYPE.SUCCESS,
              contextText: MESSAGE_TYPE.SUCCESS,
              info: "file uploaded failed",
            });
          }
        })

        .catch((error) => { });
    };
  };

  const exportExcel = async (excelData) => {
    // console.log('excelData: ', excelData);
    // const modifiedExcelData = excelData.map((row, index) => {
    //   const modifiedRow = { 'id': index, ...row };
    //   return modifiedRow;
    // });

    // let newExcelData

    // const vendorExcel = (row) =>  ({})
    // const otherThanVendorExcel = (row) => ({
    //   id: row.id,
    //   Supplier_Code: row.Supplier_Code,
    //   Project_code: row.Project_code,
    //   Plant_code: row.Plant_code,
    //   Quarter: row.Quarter,
    //   Financial_year: row.Financial_year,
    // })
    // console.log('other Vendor: ', otherThanVendorExcel);
    // if (isVendorUser) {
    const newExcelData = excelData?.map(
      (
        {
          Project_code,
          Plant_code,
          Supplier_code,
          Financial_year,
          Quarter,
          Part_no,
          Part_description,
          Invoice_selling_price,
          Supplier_selling_price,
          Vendor_remark,
          Value_of_Direct_Import_by_us,
          Broad_Description_of_parts_imported_by_us,
          Value_of_parts_imported_by_our_suppliers,
          Broad_Description_of_parts_imported_by_our_suppliers,
          Local_Content,
          DVAPercentage,
          Foreign_Exchange_Rate,
          Currency_Name,
          Reference_Date
        },
        id
      ) => {
        let obj = {
          id,
          Project_code,
          Plant_code,
          Supplier_code,
          Financial_year,
          Quarter,
          Part_no,
          Part_description,
          Invoice_selling_price,
          Supplier_selling_price,
          Vendor_remark,
          Value_of_Direct_Import_by_us,
          Broad_Description_of_parts_imported_by_us,
          Value_of_parts_imported_by_our_suppliers,
          Broad_Description_of_parts_imported_by_our_suppliers,
          Local_Content,
          DVAPercentage,
          Foreign_Exchange_Rate,
          Currency_Name,
          Reference_Date
        }
        return obj
      }
    )
    
    const excelDataExceptVendor = excelData?.map(
      (
        {
          Project_code,
          Plant_code,
          Supplier_code,
          Financial_year,
          Quarter,
          Part_no,
          Part_description,
          Invoice_selling_price,
          Supplier_selling_price,
          Vendor_remark,
          Value_of_Direct_Import_by_us,
          Broad_Description_of_parts_imported_by_us,
          Value_of_parts_imported_by_our_suppliers,
          Broad_Description_of_parts_imported_by_our_suppliers,
          Local_Content,
          DVAPercentage,
          Foreign_Exchange_Rate,
          Currency_Name,
          Reference_Date,


          Ca_Sa,
          UDIN,
          DVA_certifying_egency,
          Supplier_invoice_no,
          Supplier_net_value,
          Supplier_discounted_value,
          Value_matched_Notmatched,
          
          Invoice_submitted,
          Invoice_considered_System_Supplier,
          Invoice_no_GDC,
          // GR_Date,
          // GR_No,
          // PO_Date,
          // PO_No,
          // System_Invoice_No_R,
          // System_Invoice_No_S,
          TMLBSL_approve_reject,
          TMLBSL_approve_reject_remark,
          Purchase_approve_reject,
          Purchase_approve_reject_remark,
          PMT_approve_reject,
          PMT_approve_reject_remark
        },
        id
      ) => {
        let obj = {
          id,
          Project_code,
          Plant_code,
          Supplier_code,
          Financial_year,
          Quarter,
          Part_no,
          Part_description,
          Invoice_selling_price,
          Supplier_selling_price,
          Vendor_remark,
          Value_of_Direct_Import_by_us,
          Broad_Description_of_parts_imported_by_us,
          Value_of_parts_imported_by_our_suppliers,
          Broad_Description_of_parts_imported_by_our_suppliers,
          Local_Content,
          DVAPercentage,
          Foreign_Exchange_Rate,
          Currency_Name,
          Reference_Date,


          Ca_Sa,
          UDIN,
          DVA_certifying_egency,
          Supplier_invoice_no,
          Supplier_net_value,
          Supplier_discounted_value,
          Value_matched_Notmatched,
          
          Invoice_submitted,
          Invoice_considered_System_Supplier,
          Invoice_no_GDC,
          // GR_Date,
          // GR_No,
          // PO_Date,
          // PO_No,
          // System_Invoice_No_R,
          // System_Invoice_No_S,
          TMLBSL_approve_reject,
          TMLBSL_approve_reject_remark,
          Purchase_approve_reject,
          Purchase_approve_reject_remark,
          PMT_approve_reject,
          PMT_approve_reject_remark
        }
        return obj
      }
    )

    

    var fileTypel =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    var fileExt = ".xlsx";
    // const ws = XLSX.utils.json_to_sheet(modifiedExcelData);
    // const ws = XLSX.utils.json_to_sheet(isVendorUser ? newExcelData : excelDataExceptVendor);
    const ws = XLSX.utils.json_to_sheet(isVendorUser || isAdminUser ? newExcelData : excelDataExceptVendor);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { booktype: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileTypel });
    FileSaver.saveAs(data, `${Bc?.value}${fileExt}`);
  };




  const baseDefaultColumns = [
    {
      width: 130,
      title: "Project Code",
      field: "Project_code",
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 130,
      title: "Plant code",
      field: "Plant_code",
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 130,
      title: "Supplier Code",
      field: "Supplier_code",
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 140,
      title: "Financial Year",
      field: "Financial_year",
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 140,
      title: "Quarter",
      field: "Quarter",
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 130,
      title: "Part No.",
      field: "Part_no",
      enableSearch: true,
      enableFilter: true,
    },
    
    {
      width: 210,
      title: "Part Description",
      field: "Part_description",
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 200,
      title: "BOM Selling Price",
      field: "Invoice_selling_price",
      enableSearch: true,
      enableFilter: true,
    },
    // {
    //   width: 200,
    //   title: "System Selling Price",
    //   field: "Selling_Price",
    //   enableSearch: true,
    //   enableFilter: true,
    // },
    {
      width: 200,
      title: "Selling Price INR (excluding GST)",
      field: "Supplier_selling_price",
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 200,
      title: "Vendor Remark",
      field: "Vendor_remark",
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 200,
      title: "Value of Direct Import by us(in INR)*",
      field: "Value_of_Direct_Import_by_us",
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 200,
      title: "Broad Description of parts imported by us",
      field: "Broad_Description_of_parts_imported_by_us",
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 200,
      title: "Value of parts imported by our suppliers (in INR)* ",
      field: "Value_of_parts_imported_by_our_suppliers",
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 200,
      title: "Broad Description of parts imported by our suppliers",
      field: "Broad_Description_of_parts_imported_by_our_suppliers",
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 130,
      title: "Local Content",
      field: "Local_Content",
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 90,
      title: "DVA",
      field: "DVAPercentage",
      enableSearch: true,
      enableFilter: true,
    },

    // {
    //   width: 140,
    //   title : "MPFD",
    //   field : "MPFD",
    //   enableSearch : true,
    //   enableFilter : true,
    // },
    // {
    //   width: 150,
    //   title: "T2 Import INR",
    //   field: "T2_Import",
    //   enableSearch: true,
    //   enableFilter: true,
    // },
    // {
    //   width: 120,
    //   title: "Exception",
    //   field: "Exception",
    //   enableSearch: true,
    //   enableFilter: true,
    // },
    // {
    //   width: 150,
    //   title: "Exception Classification ",
    //   field: "Exception_Classification",
    //   enableSearch: true,
    //   enableFilter: true,
    // },
    // {
    //   width: 120,
    //   title: "Total Import INR",
    //   field: "Total_Import",
    //   enableSearch: true,
    //   enableFilter: true,
    // },
    // {
    //   width: 120,
    //   title: "DVA",
    //   field: "DVA",
    //   enableSearch: true,
    //   enableFilter: true,
    // },
    // {
    //   width: 120,
    //   title: "Claimed for PLI",
    //   field: "Claimed_for_PLI",
    //   enableSearch: true,
    //   enableFilter: true,
    // },
    // {
    //   width: 120,
    //   title: "Start Date",
    //   field: "Start_Date",
    //   enableSearch: true,
    //   enableFilter: true,
    // },
    // {
    //   width: 120,
    //   title: "Explanation",
    //   field: "Explanation",
    //   enableSearch: true,
    //   enableFilter: true,
    // },

    {
      width: 140,
      title: "Foreign exchange rate",
      field: "Foreign_Exchange_Rate",
      enableSearch: true,
      enableFilter: true,
    }, {
      width: 140,
      title: "Currency Name",
      field: "Currency_Name",
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 140,
      title: "Currency Reference Date",
      field: "Reference_Date",
      enableSearch: true,
      enableFilter: true,
    },
    /* Approve/Reject Column Section */
    {
      width: 140,
      title: "TMLBSL Approve/Reject",
      field: "TMLBSL_approve_reject",
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 140,
      title: "TMLBSL Approve/Reject Remark",
      field: "TMLBSL_approve_reject_remark",
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 140,
      title: "Purchase Approve/Reject",
      field: "Purchase_approve_reject",
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 140,
      title: "Purchase Approve/Reject Remark",
      field: "Purchase_approve_reject_remark",
      enableSearch: true,
      enableFilter: true,
    },
    // {
    //   width: 140,
    //   title: "PMT Approve/Reject",
    //   field: "PMT_approve_reject",
    //   enableSearch: true,
    //   enableFilter: true,
    // },
    // {
    //   width: 140,
    //   title: "PMT Approve/Reject Remark",
    //   field: "PMT_approve_reject_remark",
    //   enableSearch: true,
    //   enableFilter: true,
    // },
  ];
  const baseDefaultColumnsGDC = [
    {
      width: 130,
      title: "Project Code",
      field: "Project_code",
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 130,
      title: "Plant code",
      field: "Plant_code",
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 130,
      title: "Supplier Code",
      field: "Supplier_code",
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 140,
      title: "Financial Year",
      field: "Financial_year",
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 140,
      title: "Quarter",
      field: "Quarter",
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 130,
      title: "Part No.",
      field: "Part_no",
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 210,
      title: "Part Description",
      field: "Part_description",
      enableSearch: true,
      enableFilter: true,
    },
    // userRole?.includes('TMLBSL') &&
    {
      width: 200,
      title: "BOM Selling Price",
      field: "Invoice_selling_price",
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 200,
      title: "Selling Price INR (excluding GST)",
      field: "Supplier_selling_price",
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 200,
      title: "Vendor Remark",
      field: "Vendor_remark",
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 200,
      title: "Value of Direct Import by us(in INR)*",
      field: "Value_of_Direct_Import_by_us",
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 200,
      title: "Broad Description of parts imported by us",
      field: "Broad_Description_of_parts_imported_by_us",
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 200,
      title: "Value of parts imported by our suppliers (in INR)* ",
      field: "Value_of_parts_imported_by_our_suppliers",
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 200,
      title: "Broad Description of parts imported by our suppliers",
      field: "Broad_Description_of_parts_imported_by_our_suppliers",
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 130,
      title: "Local Content",
      field: "Local_Content",
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 90,
      title: "DVA",
      field: "DVAPercentage",
      enableSearch: true,
      enableFilter: true,
    },
    
    // {
    //   width: 140,
    //   title : "MPFD",
    //   field : "MPFD",
    //   enableSearch : true,
    //   enableFilter : true,
    // },
    // {
    //   width: 150,
    //   title: "T2 Import INR",
    //   field: "T2_Import",
    //   enableSearch: true,
    //   enableFilter: true,
    // },
    // {
    //   width: 120,
    //   title: "Exception",
    //   field: "Exception",
    //   enableSearch: true,
    //   enableFilter: true,
    // },
    // {
    //   width: 150,
    //   title: "Exception Classification ",
    //   field: "Exception_Classification",
    //   enableSearch: true,
    //   enableFilter: true,
    // },
    // {
    //   width: 120,
    //   title: "Total Import INR",
    //   field: "Total_Import",
    //   enableSearch: true,
    //   enableFilter: true,
    // },
    // {
    //   width: 120,
    //   title: "DVA",
    //   field: "DVA",
    //   enableSearch: true,
    //   enableFilter: true,
    // },
    // {
    //   width: 120,
    //   title: "Claimed for PLI",
    //   field: "Claimed_for_PLI",
    //   enableSearch: true,
    //   enableFilter: true,
    // },
    // {
    //   width: 120,
    //   title: "Start Date",
    //   field: "Start_Date",
    //   enableSearch: true,
    //   enableFilter: true,
    // },
    // {
    //   width: 120,
    //   title: "Explanation",
    //   field: "Explanation",
    //   enableSearch: true,
    //   enableFilter: true,
    // },
    
    {
      width: 140,
      title: "Foreign exchange rate",
      field: "Foreign_Exchange_Rate",
      enableSearch: true,
      enableFilter: true,
    }, {
      width: 140,
      title: "Currency Name",
      field: "Currency_Name",
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 140,
      title: "Currency Reference Date",
      field: "Reference_Date",
      enableSearch: true,
      enableFilter: true,
    },

    {
      width: 140,
      title: "Reference CA_SA",
      field: "Ca_Sa",
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 140,
      title: "CA_SA",
      field: "Ca_Sa",
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 140,
      title: "UDIN",
      field: "UDIN",
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 140,
      title: "DVA certifying agency",
      field: "DVA_certifying_egency",
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 140,
      title: "Invoice No. (Supplier)",
      field: "Supplier_invoice_no",
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 140,
      title: "Net Value",
      field: "Supplier_net_value",
      enableSearch: true,
      enableFilter: true,

    }, {
      width: 140,
      title: "Discounted Value",
      field: "Supplier_discounted_value",
      enableSearch: true,
      enableFilter: true,

    },
    {
      width: 140,
      title: "Value Matched/Not Matched",
      field: "Value_matched_Notmatched",
      enableSearch: true,
      enableFilter: true,

    },
    // {
    //   width: 140,
    //   title: "Remarks",
    //   field: "Remarks",
    //   enableSearch: true,
    //   enableFilter: true,

    // },
    {
      width: 140,
      title: "Invoices Submitted",
      field: "Invoice_submitted",
      enableSearch: true,
      enableFilter: true,

    },
    {
      width: 140,
      title: "Invoice Considered(System/Supplier)",
      field: "Invoice_considered_System_Supplier",
      enableSearch: true,
      enableFilter: true,

    },
    {
      width: 140,
      title: "Invoice No(GDC)",
      field: "Invoice_no_GDC",
      enableSearch: true,
      enableFilter: true,
    },

    /* For except Vendor */
    
    {
      width: 140,
      title: "GR Date",
      field: "Invoice_No_GDC",
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 140,
      title: "GR No",
      field: "Invoice_No_GDC",
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 140,
      title: "PO Date",
      field: "Invoice_No_GDC",
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 140,
      title: "PO No",
      field: "Invoice_No_GDC",
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 140,
      title: "System Invoice No (R)",
      field: "Invoice_No_GDC",
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 140,
      title: "System Invoice No (S)",
      field: "Invoice_No_GDC",
      enableSearch: true,
      enableFilter: true,
    },
    /* Approve/Reject Column Section */
    {
      width: 140,
      title: "TMLBSL Approve/Reject",
      field: "TMLBSL_approve_reject",
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 140,
      title: "TMLBSL Approve/Reject Remark",
      field: "TMLBSL_approve_reject_remark",
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 140,
      title: "Purchase Approve/Reject",
      field: "Purchase_approve_reject",
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 140,
      title: "Purchase Approve/Reject Remark",
      field: "Purchase_approve_reject_remark",
      enableSearch: true,
      enableFilter: true,
    },
    // {
    //   width: 140,
    //   title: "PMT Approve/Reject",
    //   field: "PMT_approve_reject",
    //   enableSearch: true,
    //   enableFilter: true,
    // },
    // {
    //   width: 140,
    //   title: "PMT Approve/Reject Remark",
    //   field: "PMT_approve_reject_remark",
    //   enableSearch: true,
    //   enableFilter: true,
    // },
    // {
    //   width: 140,
    //   title : "MPFD",
    //   field : "MPFD",
    //   enableSearch : true,
    //   enableFilter : true,
    // },
    // {
    //   width: 150,
    //   title: "T2 Import INR",
    //   field: "T2_Import",
    //   enableSearch: true,
    //   enableFilter: true,
    // },
    // {
    //   width: 120,
    //   title: "Exception",
    //   field: "Exception",
    //   enableSearch: true,
    //   enableFilter: true,
    // },
    // {
    //   width: 150,
    //   title: "Exception Classification ",
    //   field: "Exception_Classification",
    //   enableSearch: true,
    //   enableFilter: true,
    // },
    // {
    //   width: 120,
    //   title: "Total Import INR",
    //   field: "Total_Import",
    //   enableSearch: true,
    //   enableFilter: true,
    // },
    // {
    //   width: 120,
    //   title: "DVA",
    //   field: "DVA",
    //   enableSearch: true,
    //   enableFilter: true,
    // },
    // {
    //   width: 120,
    //   title: "Claimed for PLI",
    //   field: "Claimed_for_PLI",
    //   enableSearch: true,
    //   enableFilter: true,
    // },
    // {
    //   width: 120,
    //   title: "Start Date",
    //   field: "Start_Date",
    //   enableSearch: true,
    //   enableFilter: true,
    // },
    // {
    //   width: 120,
    //   title: "Explanation",
    //   field: "Explanation",
    //   enableSearch: true,
    //   enableFilter: true,
    // },
    // {
    //   width: 140,
    //   title : "CA_SA",
    //   field : "CA_SA",
    //   enableSearch : true,
    //   enableFilter : true,

    // },
    // {
    //   width: 140,
    //   title : "UDIN",
    //   field : "UDIN",
    //   enableSearch : true,
    //   enableFilter : true,

    // },
    // {
    //   width: 140,
    //   title : "DVA_certifying_agency",
    //   field : "DVA_certifying_agency",
    //   enableSearch : true,
    //   enableFilter : true,

    // },

  ]

 
const validationAPIFunction = async () => {
  let url;
  const dte = new Date();
  const formattedDate = dte.toLocaleDateString('en-GB').replace(/\//g, '-');
  console.log("username", username);
  console.log('Approve button clicked!');
  console.log('Selected Rows before submission:', selectedRows); 

  let apiBodyValidated = {
    dva_id: selectedRows.map(row => row.id).filter(id => id) 
  };

  if (apiBodyValidated.dva_id.length === 0) {
    console.error("No valid dva_id found!");
    showPopup({
      type: MESSAGE_TYPE.FAILURE,
      contextText: MESSAGE_TYPE.FAILURE,
      info: 'No valid dva_id found!',
    });
    return;
  }

  if (userRole?.includes("Purchase")) {
    apiBodyValidated = {
      ...apiBodyValidated, 
      Purchase_submitted_Name: username,
      Purchase_submitted_Date: formattedDate,
    };
    url = 'PurchaseSubmissionstatus';
  } else if (userRole?.includes('TMLBSL')) {
    apiBodyValidated = {
      ...apiBodyValidated, 
      TMLBSL_submitted_Name: username,
      TMLBSL_submitted_Date: formattedDate,
    };
    url = 'TMLBSLSubmissionstatus';
  }

  const apiFormattedDataJson = JSON.stringify(apiBodyValidated);
  const headerss = {
    'Content-Type': 'application/json',
  };
  setLoader(true); // Start the loader
  try {
    const response = await axios.post(`${baseUrl}${url}/`, apiFormattedDataJson, { headers: headerss });
    console.log('response from axios: ', response);

    if (response.data?.ok || response?.status === 200) {
      setLoader(false); // Stop the loader
      showPopup({
        type: MESSAGE_TYPE.SUCCESS,
        contextText: MESSAGE_TYPE.SUCCESS,
        info: 'Data validation successful!',
      });
    } else {
      showPopup({
        type: MESSAGE_TYPE.FAILURE,
        contextText: MESSAGE_TYPE.FAILURE,
        info: 'Data validation failed!',
      });
    }

    history.push('/');
  } catch (error) {
    setLoader(false); // Stop the loader
    console.error('API error: ', error);
    showPopup({
      type: MESSAGE_TYPE.FAILURE,
      contextText: MESSAGE_TYPE.FAILURE,
      info: 'An error occurred during data validation!',
    });
  }
};

const goNextForm = async (comment) => {
    let url;
    const dte = new Date();
    const formattedDate = dte.toLocaleDateString('en-GB').replace(/\//g, '-');
  
    console.log('Selected Rows before sending:', selectedRows);
  
   
    let apiBody1 = {
      dva_id: selectedRows.map(row => row.id).filter(id => id) ,
      // TMLBSL_approve_reject_remark: comment // Add the comment (remark) to the payload
    };
  
   
    if (apiBody1.dva_id.length === 0) {
      console.error("No valid dva_id found!");
      showPopup({
        type: MESSAGE_TYPE.FAILURE,
        contextText: MESSAGE_TYPE.FAILURE,
        info: 'No valid dva_id found!',
      });
      return;
    }
  
    
    if (userRole?.includes("Purchase")) {
      apiBody1 = {
        ...apiBody1, 
        Purchase_approve_reject_remark: comment,
        Purchase_submitted_Name: formattedDate, 
        Purchase_submitted_Date: username, 
      };
      url = 'SendBackPurchaseStatusForSupplierDva'; 
    } else if (userRole?.includes('TMLBSL')) {
      apiBody1 = {
        ...apiBody1, 
        TMLBSL_approve_reject_remark: comment,
        TMLBSL_submitted_Name: formattedDate, 
        TMLBSL_submitted_Date: username, 
       
        
      };
      url = 'SendBackTMLBSLStatusForSupplierDva'; 
    }
  
    
    const apiFormattedDataJson = JSON.stringify(apiBody1);
    const headerss = {
      'Content-Type': 'application/json'
    };
  
    setLoader(true); // Start the loader
    try {
      const response = await axios.post(`${baseUrl}${url}/`, apiFormattedDataJson, { headers: headerss });
      console.log('response from axios: ', response);
  
     
      if (response.data?.ok || response?.data?.status === 'Records updated successfully' || response?.status === 200) {
        setLoader(false); // Stop the loader
        showPopup({
          type: MESSAGE_TYPE.SUCCESS,
          contextText: MESSAGE_TYPE.SUCCESS,
          info: 'Data sent back for Re-Submission',
        });
      } else {
        showPopup({
          type: MESSAGE_TYPE.FAILURE,
          contextText: MESSAGE_TYPE.FAILURE,
          info: 'Could not send data for Re-Submission!',
        });
      }
  
     
      history.push('/');
    } catch (error) {
      console.error('API error: ', error);
      setLoader(false); // Stop the loader
      showPopup({
        type: MESSAGE_TYPE.FAILURE,
        contextText: MESSAGE_TYPE.FAILURE,
        info: 'An error occurred while sending data for Re-Submission!',
      });
    }
  };

  const formattedCurrentDate = new Date().toLocaleDateString('en-GB').split('/').join('/')
  const submitSupplierDVACollection = async () => {
    const newAPIBody = {
      ...apiBody,
      Vendor_submitted_Date: formattedCurrentDate
    }
    const apiFormattedDataJson = JSON.stringify(newAPIBody);
    const headerss = {
      'Content-Type': 'application/json'
    };
    setLoader(true); // Start the loader
    try {
      // const response = await axios.post(`${baseUrl}SubmitStatusUpdate/`, apiFormattedDataJson, { headers: headerss });
      const response = await axios.post(`${baseUrl}SupplierSubmissionstatus/`, apiFormattedDataJson, { headers: headerss });
      
      // Handle the response
      console.log("responseJson", response.data);
      setLoader(false); // Stop the loader
      showPopup({
        type: MESSAGE_TYPE.SUCCESS,
        contextText: MESSAGE_TYPE.SUCCESS,
        info: "Supplier DVA Submitted Successfully!",
      })
      history.push('/')
    } catch (error) {
      console.error('API error:', error);
      setLoader(false); // Stop the loader in case of an error
      showPopup({
        type: MESSAGE_TYPE.FAILURE,
        contextText: MESSAGE_TYPE.FAILURE,
        info: 'An error occurred while submitting Supplier DVA!'
      });
    }
  };
  

  const redirectToCreateProjectPage = () => history.push("/");
  const renderHTML = () => (
    <div className={styles.formGroup}>
{
        loading && (
          <div className={styles.gridLoader}>
            <GridLoadingSpinner />
          </div>
        )
      }
      <Grid
        container
        spacing={1}

        style={{ display: 'flex', justifyContent: "left" }}
        columns={12}
      >
        <Grid
          item
          // sm={12}
          md={3}
          lg={3}
          // style={{ marginTop: "5px", marginBottom: '5px' }}
        >
          <div className={styles.rowDiv}>
            <div className={clsx(styles.lableDiv)} style={{ width: '54%' }}>
              <label className={styles.label}>Project Code</label>
            </div>
            <div className={styles.formDiv}>
              <CustomSelect
                name='Project Code'
                options={projectCodeArray}
                // isDisabled={checkDisable}
                className={clsx(styles.select, styles.sel1)}
                // value={projectCode}
                value={selectedProjectCode}
                defaultValue={latestProject}
                isMulti={false}
                isMandatory={
                  auth.userRole[0] === 'Vendor' || auth.userRole[0] === 'admin'
                }
                isClearable
                // onChange={selectedOption => {
                //   // handleSelect(e, "VC");
                //   setProjectCode(selectedOption)
                //   setSelectedProjectCode(selectedOption)
                //   getData(selectedOption)
                onChange={(selectedOption) => {
                  setSelectedProjectCode(selectedOption); // Update selected project code
                  setBC(null); // Reset supplier value
                  setBcArray([]); // Clear supplier array
                // getData(selectedOption)
                }}
              />
            </div>
          </div>
        </Grid>

        <Grid 
          item 
          md={3} 
          lg={3} 
          // sm={12} 
          // style={{ marginTop: "5px", marginBottom: '5px' }}
        >
          <div className={styles.rowDiv}>
            <div className={clsx(styles.lableDiv)} style={{ width: "33%" }}>
              <label className={styles.label}>Supplier</label>
            </div>
            <div className={styles.formDiv} >
              <CustomSelect
                name="bc"
                options={bcArray}
                isDisabled={checkDisable}
                isMandatory={auth.userRole[0] === 'Vendor'}
                // isMandatory= {auth.userRole[0] === 'Vendor' || auth.userRole[0] === 'admin'}
                className={clsx(styles.select, styles.sel1)}
                value={Bc}
                isMulti={false}
                isClearable
                // onChange={(selectedOption) => {
                //   // handleSelect(e, "VC");
                //   setBC(selectedOption)
                // }}
                onChange={selectedOption => handleDropdownChange(selectedOption, 'Supplier')}
              />
            </div>
          </div>
        </Grid>

        <Grid
          item
          md={3}
          lg={3}
          // sm={12}
          // style={{ marginTop: '5px', marginBottom: '5px' }}
        >
          <div className={styles.rowDiv}>
            <div className={clsx(styles.lableDiv)} style={{ width: '22%' }}>
              <label className={styles.label}>Plant</label>
            </div>
            <div className={styles.formDiv}>
              <CustomSelect
                name='Plant'
                options={plantArray}
                // isDisabled={checkDisable}
                className={clsx(styles.select, styles.sel1)}
                value={plant}
                isMulti={false}
                isClearable
                isMandatory={auth.userRole[0] === 'Vendor'}
                onChange={selectedOption => {
                  // handleSelect(e, "VC");
                  setPlant(selectedOption)
                }}
              />
            </div>
          </div>
        </Grid>

        {/* <Grid item sm={12} md={5} lg={4} style={{ marginTop: "5px", marginBottom: '5px' }}> */}
          {/* <div className={styles.rowDiv}>
            <div className={clsx(styles.lableDiv)} style={{ width: "40%" }}>
              <label className={styles.label}>Financial Year</label>
            </div>
            <div className={styles.formDiv} >
              <CustomSelect
                name="Fy"
                options={fyArray}
                // isDisabled={checkDisable}
                className={clsx(styles.select, styles.sel1)}
                value={Fy}
                isMulti={false}
                // isMandatory = {auth.userRole === ''}
                isClearable
                onChange={(selectedOption) => {
                  // handleSelect(e, "VC");
                  setFY(selectedOption)
                }}
              />
            </div>
          </div> */}
        {/* </Grid> */}
        {/* <Grid item xs={12} md={5} lg={4} style={{ marginTop: "5px", marginBottom: '5px' }}> */}
          {/* <div className={styles.rowDiv}>
            <div className={clsx(styles.lableDiv)} style={{ width: "40%" }}>
              <label className={styles.label}>Quarter</label>
            </div>
            <div className={styles.formDiv}>
              <CustomSelect
                name="Quarter"
                options={quarterArray}
                // isDisabled={checkDisable}
                className={clsx(styles.select, styles.sel1)}
                value={quarter}
                isMulti={false}
                isClearable
                onChange={(selectedOption) => {
                  // handleSelect(e, "VC");
                  setQuarter(selectedOption)
                }}
              />
            </div>
          </div> */}
        {/* </Grid> */}

        {/* <Grid item md={2} lg={2} sm={12}>
         */}


      <Grid
        item
        md={3}
        lg={3}
        // sm={12}
        // style={{ marginTop: '5px', marginBottom: '5px' }}
      >
        <div
          style={{
            marginLeft: '5px',
            paddingRight: '',
            marginTop: '1px',
            display: 'flex',
            justifyContent: 'right'
          }}
        >
          <AuthChecker operation='search'>
            {isAuthorized => (
              <Button
                className={clsx(styles.actionButton, styles.primaryActionButton)}
                style={{ marginRight: '12px !important' }}
                variant='primary'
                //   onClick={() => runPreSubmissionChecks() && handleSubmit()}
                onClick={showTable}
                disabled={!isAuthorized}
                data-testid='confirm-action'
              >
                Show Data
              </Button>
            )}
          </AuthChecker>
          <AuthChecker operation='search'>
            {isAuthorized => (
              <Button
                className={clsx(styles.actionButton, styles.primaryActionButton)}
                variant='primary'
                //   onClick={() => runPreSubmissionChecks() && handleSubmit()}
                onClick={() => exportExcel(tableArray)}
                disabled={!isAuthorized}
                data-testid='confirm-action'
                style={{ marginLeft: '5px' }}
              >
                Download
              </Button>
            )}
          </AuthChecker>
          {/* <AuthChecker operation="search">
                  {isAuthorized => (
                    <Button
                      className={clsx(
                        styles.actionButton,
                        styles.primaryActionButton,
                      )}
                      style={{marginRight:"12px !important",marginLeft:"5px"}}
                      variant="primary"
                      //   onClick={() => runPreSubmissionChecks() && handleSubmit()}
                      onClick={()=>{
                        getAllData()
                      }}
                        disabled={!isAuthorized}
                      
                      data-testid="confirm-action"
                    >
                      Show All
                    </Button>
                  )}
        
                </AuthChecker> */}
          {/* {!auth.userRole.includes('Vendor') ? (
            <AuthChecker operation='search'>
              {isAuthorized => (
                <Button
                  className={clsx(styles.actionButton, styles.primaryActionButton)}
                  style={{ marginRight: '12px !important', marginLeft: '5px' }}
                  variant='primary'
                  onClick={() => {
                    getAllData()
                  }}
                  disabled={!isAuthorized}
                  data-testid='confirm-action'
                >
                  Show All
                </Button>
              )}
            </AuthChecker>
          ) : null} */}

          {/* {
              loader==false&&
          
                <>
              <AuthChecker operation="download">

                {isAuthorized => (
                    <Button
                      className={clsx(
                        styles.actionButton,
                        styles.primaryActionButton,
                      )}
                      variant="primary"
                      //   onClick={() => runPreSubmissionChecks() && handleSubmit()}
                      onClick={()=>{

                        if(tableArray.length>=1){exportExcel(tableArray);}
                      }}
                        disabled={!isAuthorized}
                      data-testid="confirm-action"
                      style={{marginLeft:"5px"}}

                    >
                      download
                    </Button>
                  )}
        
                </AuthChecker>
            
            </> 
          } */}
        </div>
      </Grid>


      </Grid>

    </div>
  );

  // const downloadFile = () => {
  //   const headerss = new Headers({
  //     'Content-Type': 'application/json'
  //   })
  //   // const formData = new FormData();
  //   // formData.append("file", e.target.files[0]);
  //   // formData.append("Supplier_Code",Bc?.value)
  //   console.log(Bc?.value)
  //   const body = JSON.stringify({
  //     Project_code: projectCode?.value,
  //     Supplier_code: Bc?.value,
  //     Plant_code: plant?.value,
  //     TMLBSL_submitted_Name: tlmbslSubmit?.value,
  //   })
  //   // fetch(`${baseUrl}SupplierDvaCollection_PDF/`, {
  //   fetch(`${baseUrl}SupplierDva_PDF/`, {
  //     method: 'POST',
  //     headers: headerss,
  //     body

  //   }).then((response) => {

  //     const success = response.ok;

  //     response
  //       .blob()
  //       .then((blob) => {
  //         // Creating new object of PDF file
  //         const fileURL = window.URL.createObjectURL(blob);
  //         // Setting various property values

  //         let alink = document.createElement("a");
  //         alink.href = fileURL;
  //         alink.download = "Certificate.pdf";
  //         alink.click();

  //       }).then(() => {
  //         if (success) {
  //           showPopup({
  //             type: MESSAGE_TYPE.SUCCESS,
  //             contextText: MESSAGE_TYPE.SUCCESS,
  //             info: "File is downloaded successfully",
  //           });
  //         }

  //       })
  //       .catch((error) => {
  //         // catch any unexpected errors
  //         console.log(error);
  //         showPopup({
  //           type: MESSAGE_TYPE.FAILURE,
  //           contextText: MESSAGE_TYPE.FAILURE,
  //           info: "Document download failed.",
  //         });
  //       });
  //   });
  // };
  const downloadFile = async () => {
    const body = {
      Supplier_code: Bc?.value,
      Project_code: projectCode?.value,
      Plant_code: plant?.value,
    };
  
    try {
      // const response = await axios.post(`${baseUrl}SupplierDeclaration_PDFGen/`, body, {
      const response = await axios.post(`${baseUrl}SupplierDva_PDF/`, body, {
        headers: {
          'Content-Type': 'application/json',
        },
        // responseType: 'blob', // Ensure axios returns the response as a blob for file download
      });
  
      const success = response.status >= 200 && response.status < 300; // Check if the response status is 2xx
  
      // Handling the file download
      // const blob = new Blob([response.data], { type: 'application/pdf' });
      // const fileURL = window.URL.createObjectURL(blob);

      // let filename = response.headers['x-filename']
      // console.log('Response header: ', response.headers['x-filename'])
      // console.log('File name: ', filename)
      const blob = response.data?.file_url
      console.log('file from pli: ', response);
      
      const alink = document.createElement('a');
      alink.href = blob;
      alink.download = `${projectCode?.value}_${Bc?.value}_${username}.pdf`;
      // alink.click();
      document.body.appendChild(alink)
      alink.dispatchEvent(
        new MouseEvent('click', {
          bubbles: true,
          cancelable: true,
          view: window
        })
      )
      document.body.removeChild(alink)
  
      if (success) {
        showPopup({
          type: MESSAGE_TYPE.SUCCESS,
          contextText: MESSAGE_TYPE.SUCCESS,
          info: 'File is successfully downloaded',
        });
      }
    } catch (error) {
      if (error.response) {
        const { status } = error.response;
  
        if (status === 400) {
          console.error('Bad Request: Invalid data provided.', error.response.data);
          showPopup({
            type: MESSAGE_TYPE.FAILURE,
            contextText: MESSAGE_TYPE.FAILURE,
            info: 'Bad request. Please check the supplied data.',
          });
        } else if (status === 401) {
          console.error('Unauthorized: Access is denied.', error.response.data);
          showPopup({
            type: MESSAGE_TYPE.FAILURE,
            contextText: MESSAGE_TYPE.FAILURE,
            info: 'Unauthorized. Please login to access this resource.',
          });
        } else if (status === 500) {
          console.error('Server Error: An error occurred on the server.', error.response.data);
          showPopup({
            type: MESSAGE_TYPE.FAILURE,
            contextText: MESSAGE_TYPE.FAILURE,
            info: 'Server error. Please try again later.',
          });
        } else {
          console.error('Error:', error.response.data);
          showPopup({
            type: MESSAGE_TYPE.FAILURE,
            contextText: MESSAGE_TYPE.FAILURE,
            info: 'An unexpected error occurred.',
          });
        }
      } else {
        console.error('Network Error:', error.message);
        showPopup({
          type: MESSAGE_TYPE.FAILURE,
          contextText: MESSAGE_TYPE.FAILURE,
          info: 'Network error. Please check your connection.',
        });
      }
    }
  };
  const getUrl = (code) => {
    var msg = code;

    return <div>
      <p>Certificate Uploaded Successfully</p>
      <a href={msg} target="_blank" rel="noreferrer">
        {msg}
      </a>
    </div>
  }

  // const uploadDocument = () => {
  //   var input = document.createElement("input");
  //   input.type = "file";
  //   input.id = "file";
  //   input.setAttribute("accept", ".xlsx");
  //   input.click();


  //   input.onchange = (e) => {
  //     const formData = new FormData();
  //     formData.append("file", e.target.files[0]);

  //     fetch(`${baseUrl}SupplierDVAdataUpload/`, {
  //       method: "PUT",
  //       body: formData,
  //   })
  //   .then((response) => {
  //       console.log(response);
  //       // return response.json(); // Parse JSON response
  //       if(response.ok){
  //         return response.json()
  //       }
  //       if(response.status === 400){
  //         return response.json().then(data => {
  //           console.log(data)
  //           throw new Error(data.status || 'Local Content does not match the calculated value');
  //       });

  //       }
  //       throw new Error('Network Error')

  //   })
  //   .then((myResponse) => {
  //       console.log('MyResponse', myResponse);
  //       getTableData()    
  //       showPopup({
  //                 type: MESSAGE_TYPE.SUCCESS,
  //                 contextText: MESSAGE_TYPE.SUCCESS,
  //                 info: "Document Uploaded Successfully"
  //             });


  //   })
  //   .catch((error) => {
  //       showPopup({
  //           type: MESSAGE_TYPE.FAILURE,
  //           contextText: MESSAGE_TYPE.FAILURE,
  //           info: `Error : ${error.message}`,
  //       });
  //   });
  //   };

  // }
  // const uploadDocument = () => {
  //   var input = document.createElement("input");
  //   input.type = "file";
  //   input.id = "file";
  //   input.setAttribute("accept", ".xlsx");
  //   input.click();


  //   input.onchange = (e) => {
  //     const formData = new FormData();
  //     formData.append("file", e.target.files[0]);
  //     // const url = auth.userRole[0] === 'Vendor' ? 'SupplierDVAdataUpload' : 'SupplierDvaCollectionAdminGDC'
  //     const url = auth.userRole[0] === 'Vendor' ? 'UploadSupplierData' : 'SupplierDvaCollectionAdminGDC'
  //     fetch(`${baseUrl}${url}/`, {
  //       // fetch(`${baseUrl}SupplierDVAdataUpload/`, {
  //       // method: "PUT",
  //       method: "POST",
  //       body: formData,
  //     })
  //       .then((response) => {
  //         console.log(response);
  //         // return response.json(); // Parse JSON response
  //         if (response.ok) {
  //           return response.json()
  //         }
  //         if (response.status === 400) {
  //           return response.json().then(data => {
  //             console.log(data)
  //             const error = data.status.match(/="(.*?)"/)[1];
  //             throw new Error(error || 'Local Content does not match the calculated value');
  //           });

  //         } if (response.status === 500) {
  //           return response.json().then(data => {
  //             console.log(data)
  //             throw new Error(data.status);
  //           });

  //         }
  //         return response.json().then(data => {
  //           console.log(data)
  //           throw new Error(data.statusText);
  //         });

  //       })
  //       .then((myResponse) => {
  //         console.log('MyResponse', myResponse);
  //         showPopup({
  //           type: MESSAGE_TYPE.SUCCESS,
  //           contextText: MESSAGE_TYPE.SUCCESS,
  //           info: "Document Uploaded Successfully"
  //         });

  //         getTableData()
  //       })
  //       .catch((error) => {
  //         showPopup({
  //           type: MESSAGE_TYPE.FAILURE,
  //           contextText: MESSAGE_TYPE.FAILURE,
  //           info: `Error : ${error.message}`,
  //         });
  //       });
  //   };

  // }


  const uploadDocument = () => {
    const input = document.createElement('input')
    input.type = 'file'
    input.id = 'file'
    input.setAttribute('accept', '.xlsx')
    input.click()

    input.onchange = async e => {
      const formData = new FormData()
      formData.append('file', e.target.files[0])


 
    const url =
    auth.userRole[0] === 'Vendor'
      ? 'UploadSupplierData'
      : auth.userRole[0] === 'TMLBSL'
      ? 'UploadTMLBSLdata'
      // : 'UploadPurchasedata'; // Assuming UploadPurchaseData for Purchase role
      : 'UploadSupplierData'; // Assuming UploadSupplierData for Supplier role

      try {
        const response = await axios.post(`${baseUrl}${url}/`, formData, {
          headers: { 'Content-Type': 'multipart/form-data' }
        })

        console.log('Upload Response:', response)

        if (response.status === 200 || response.status === 201) {
          showPopup({
            type: MESSAGE_TYPE.SUCCESS,
            contextText: MESSAGE_TYPE.SUCCESS,
            info: 'Document Uploaded Successfully'
          })
          getTableData()
        }
      } catch (error) {
        if (error.response) {
          const { status, data } = error.response
          console.log('Error Response:', data)

          let errorMessage = 'An error occurred during the document upload.'

          if (status === 400) {
            errorMessage = 
              data?.status
              // data?.status.match(/="(.*?)"/)?.[1] ||
              // 'Local Content does not match the calculated value'
          } else if (status === 500) {
            errorMessage = data?.status || 'Server encountered an issue.'
          } else {
            errorMessage = `Unexpected error: ${
              data?.statusText || error.message
            }`
          }

          showPopup({
            type: MESSAGE_TYPE.FAILURE,
            contextText: MESSAGE_TYPE.FAILURE,
            // info: `Error: ${errorMessage}`
            info: errorMessage
          })
        } else {
          showPopup({
            type: MESSAGE_TYPE.FAILURE,
            contextText: MESSAGE_TYPE.FAILURE,
            info: `Error: ${error.message || 'Document upload failed'}`
          })
        }
      }
    }
  }

  


  const Uploadcertification = () => {
    var input = document.createElement("input");
    input.type = "file";
    input.id = "file";
    input.setAttribute("accept", ".pdf");
    input.click();


    input.onchange = (e) => {
      const formData = new FormData();
      formData.append("file", e.target.files[0]);
      formData.append("Project_code", projectCode?.value);
      formData.append("Supplier_code", Bc?.value);
      formData.append("Plant_code", plant?.value);
      // fetch(`${baseUrl}SupplierDvaCollectionPdfUploadToBucket/`, {
      fetch(`${baseUrl}SupplierDvaCollectionPdfUploadToS3/`, {
        method: "POST",
        body: formData,
      })
        .then((response) => response.json())
        .then((responseJson) => {
          console.log(responseJson);
          //  var certificateurlstampurl = 'https://tml-test-digital-invoice-s3.s3.amazonaws.com/Certificate%20%2838%29_23-12-2022T11%3A02%3A38.pdf?response-content-disposition=attachment&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAQGDNMWVUMVQVJ4YB%2F20221223%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20221223T110240Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=4da98e95872b5a7d4f2b15a2f524ce1d18178ba15b0923a4ca1b8605ad79bd1a';

          if (responseJson) {
            showPopup({
              type: MESSAGE_TYPE.SUCCESS,
              //  contextText:getUrl(certificateUrl),
              contextText: "success",
              info: "Certificate Uploaded Successfully"


            });
          } else {
            showPopup({
              type: MESSAGE_TYPE_CERTIFICATION.FAILURE,
              contextText: MESSAGE_TYPE_CERTIFICATION.FAILURE,
              info: responseJson.url,
            });
          }

          // eslint-disable-next-line radix
          // let status1 = parseInt(response.status);
          //  console.log(response.status)
          // if (status1 == "200") {
          //   //   document.removeChild(input);
          // //  getTableData();

          // }
        })

        .catch((error) => {

          showPopup({

            type: MESSAGE_TYPE_CERTIFICATION.FAILURE,
            contextText: MESSAGE_TYPE_CERTIFICATION.FAILURE,
            //  info: responseJson.url,
          });
        });
    };
  };
  const Uploadannexure = () => {
    var input = document.createElement("input");
    input.type = "file";
    input.id = "file";
    input.setAttribute("accept", ".pdf");
    input.click();
    input.onchange = (e) => {
      const formData = new FormData();
      formData.append("file", e.target.files[0]);
      formData.append("Project_code", projectCode?.value)
      formData.append("Supplier_code", Bc?.value);
      formData.append("Plant_code", plant?.value)
      // fetch(`${baseUrl}SupplierDvaCollectionAnnexureUploadToBucket/`, {
      fetch(`${baseUrl}SupplierDvaCollectionAnnexureUploadToS3/`, {
        method: "POST",
        body: formData,
      })
        .then((response) => response.json())
        .then((responseJson) => {
          console.log(responseJson);
          if (responseJson) {
            showPopup({
              type: MESSAGE_TYPE.SUCCESS,
              contextText: MESSAGE_TYPE.SUCCESS,
              // info: MESSAGE_TYPE_ANNEXURE.SUCCESS,
              info: 'Document Submitted Successfully!'
              //   info: responseJson.url,
            });
          } else {
            showPopup({
              type: MESSAGE_TYPE_ANNEXURE.FAILURE,
              contextText: MESSAGE_TYPE_ANNEXURE.FAILURE,
              info: MESSAGE_TYPE_ANNEXURE.FAILURE
            });
          }


        })

        .catch((error) => {
          console.log('error:', error)
        });
    };
  };

  const downloadDvaCertificate = () => {
    const formData = new FormData()
    formData.append('Project_code', projectCode?.value)
    formData.append('Supplier_code', Bc?.value)
    formData.append('Plant_code', plant?.value)
    // const data = JSON.stringify({
    //   "project_code" : projectCode,
    //   "supplier_code" : Bc,
    //   "plant_code" : plant
    // })

    // fetch(`${baseUrl}DownloadSupplierDvaCollectionPDF/`, {
    fetch(`${baseUrl}DownloadSupplierDvaCollectionPDFfile/`, {
      method: 'POST',
      // body: data,
      body: formData
    })
      .then(async response => {
        const res = await response.json()

        // console.log('DownloadSupplierAfsFile res: ', res)
        // Error case response
        // Object { error: "An error occurred while processing the request" }

        if (res?.error) {
          showPopup({
            type: MESSAGE_TYPE.FAILURE,
            contextText: MESSAGE_TYPE.FAILURE,
            info: 'Failed to download DVA Certificate'
          })
        } else {
          // eslint-disable-next-line camelcase
          const aws_url = res?.url
          const link = document.createElement('a')
          // eslint-disable-next-line camelcase
          link.href = aws_url
          document.body.appendChild(link)
          link.dispatchEvent(
            new MouseEvent('click', {
              bubbles: true,
              cancelable: true,
              view: window
            })
          )
          document.body.removeChild(link)
        }
      })
      .catch(error => {
        console.log('Error while downloading Dva Certificate: ', error)
        showPopup({
          type: MESSAGE_TYPE.INFORMATION,
          contextText: MESSAGE_TYPE.INFORMATION,
          info: 'Error while downloading Certificate'
        })
      })
  }
  const downloadDvaDocuments = () => {
    const formData = new FormData()
    formData.append('Project_code', projectCode?.value)
    formData.append('Supplier_code', Bc?.value)
    formData.append('Plant_code', plant?.value)
    // const data = JSON.stringify({
    //   "project_code" : projectCode,
    //   "supplier_code" : Bc,
    //   "plant_code" : plant
    // })

    // fetch(`${baseUrl}DownloadSupplierDvaCollectionAnnexurePDF/`, {
    fetch(`${baseUrl}DownloadSupplierDvaCollectionAnnexurePDFfile/`, {
      method: 'POST',
      // body: data,
      body: formData
    })
      .then(async response => {
        const res = await response.json()

        // console.log('DownloadSupplierAfsFile res: ', res)
        // Error case response
        // Object { error: "An error occurred while processing the request" }

        if (res?.error) {
          showPopup({
            type: MESSAGE_TYPE.FAILURE,
            contextText: MESSAGE_TYPE.FAILURE,
            info: 'Failed to Download Document'
          })
        } else {
          // eslint-disable-next-line camelcase
          const aws_url = res?.url
          const link = document.createElement('a')
          // eslint-disable-next-line camelcase
          link.href = aws_url
          document.body.appendChild(link)
          link.dispatchEvent(
            new MouseEvent('click', {
              bubbles: true,
              cancelable: true,
              view: window
            })
          )
          document.body.removeChild(link)
        }
      })
      .catch(error => {
        console.log('Error while downloading Dva Documents: ', error)
        showPopup({
          type: MESSAGE_TYPE.INFORMATION,
          contextText: MESSAGE_TYPE.INFORMATION,
          info: 'Error while downloading Document'
        })
      })
  }

  const secondaryActions = [
    {
      name: "Upload",
      authOperation: "upload",
      shouldEnable: (selected) => true,
      // shouldEnable : (selected,userRole) => auth.userRole.includes('admin','Vendor')  ,
      actionFn: (selected) => {
        // handleOpen();
        uploadDocument()
      },
      customClass: "",
    },
    
    {
      name: "Generate Certificate",
      authOperation: "submit",
      shouldEnable: (selected) => true,
      // shouldEnable : (selected,userRole) => auth.userRole.includes('admin','Vendor')  ,
      actionFn: (selected) => {
        downloadFile();
      },
      customClass: "",
    },
    {
      name: "Upload Certificate",
      authOperation: "uploadCertificate",
      shouldEnable: (selected) => true,
      // shouldEnable : (selected,userRole) => auth.userRole.includes('admin','Vendor'),
      actionFn: (selected) => {
        Uploadcertification();
      },
      customClass: "",
    },
    {
      name: "Other Documents",
      authOperation: "uploadAnnexure",
      shouldEnable: (selected) => true,
      // shouldEnable : (selected,userRole) => auth.userRole.includes('admin','Vendor')  ,
      actionFn: (selected) => {
        Uploadannexure();
      },
      customClass: "",
    },
    {
      name: "Download Certificate",
      // authOperation: "uploadAnnexure",
      authOperation: "download",
      shouldEnable: (selected) => true,
      // shouldEnable : (selected,userRole) => auth.userRole.includes('admin','TMLBSL','GDC','Purchase'),
      actionFn: (selected) => {
        downloadDvaCertificate();
      },
      customClass: "",
    },
   
    {
      name: "Download Documents",
      // authOperation: "uploadAnnexure",
      authOperation: "download",
      shouldEnable: (selected) => true,
      // shouldEnable: (selected) => auth.userRole.some(role => ['admin', 'TMLBSL', 'GDC', 'Purchase'].includes(role)) ,
      actionFn: (selected) => {
        // Uploadannexure();
        downloadDvaDocuments()
      },
      customClass: "",
    },

  ]
  const VendorSubmitStatus1 = pliSubmissionStatus?.Vendor_submitted_status;
  const primaryActions = [
    {
      name: "Submit Data",
      authOperation: "submit",
      shouldEnable: () => !isSubmitDataDisabled,  
      actionFn: handleOpenSubmissionPopup,
      // shouldEnable: selected => selected.length >=1,
      // actionFn: selected => {  handleOpen1(selected)}
      
    },
    {
      name: "Reject",
      authOperation: "submit",
      // shouldEnable: selected => selected.length >=1,
      shouldEnable: selected => selected.length >= 1 && VendorSubmitStatus1 === 'Submitted',
      actionFn: selected => {  handleOpenFormModal(selected)},
      customClass:"btnDelete"
      
    },
    {
      name: "Approve",
      authOperation: "submit",
      //  shouldEnable: selected => selected.length >=1,
      shouldEnable: selected => selected.length >= 1 && VendorSubmitStatus1 === 'Submitted',
      actionFn: selected => {  handleOpenValidatePopup(selected)},
      
    }, 
    
    
  ]
  function submitButtonFlag() {
    const TMLBSLSubmitStatus = pliSubmissionStatus?.TMLBSL_submitted_status;
    const PurchaseSubmitStatus = pliSubmissionStatus?.Purchase_submitted_status;
   const VendorSubmitStatus = pliSubmissionStatus?.Vendor_submitted_status;
  
    const isSubmitDataDisabled =
    //  (TMLBSLSubmitStatus === 'Submitted' || PurchaseSubmitStatus === 'Submitted' || VendorSubmitStatus === 'Submitted' );
     ( VendorSubmitStatus === 'Submitted');
   return {
      isSubmitDataDisabled
    };
  }
  
  // Destructure the flag for Submit Data
  const { isSubmitDataDisabled } = submitButtonFlag();
  
  const handleSecondaryActionOnRole = () => {
    // if (auth.userRole.includes('admin')) {
    //   return secondaryActions;
    // }
    if (auth.userRole.includes('admin')) {
      return secondaryActions.filter((item) => item.name !== 'Download Documents' && item.name !== 'Download Certificate');
    }
    if (auth.userRole.includes('GDC') || auth.userRole.includes('TMLBSL') || auth.userRole.includes('Purchase')) {
      return secondaryActions.filter((item) => item.name === 'Download Documents' || item.name === 'Download Certificate'|| item.name === 'Edit');
    }
    if (auth.userRole.includes('Vendor')) {
      return secondaryActions.filter((item) => item.name !== 'Download Documents' && item.name !== 'Download Certificate');
    }
    return secondaryActions;
  };
  const handlePrimaryActionOnRole = () => {
    if (auth.userRole.includes('Vendor')   || auth.userRole.includes('admin')) {
      return primaryActions.filter((item) => item.name === 'Submit Data' )
    }
    // if (auth.userRole.includes('admin')) {
    //   return primaryActions.filter((item) => item.name === 'Submit Data' || item.name === 'Approve' || item.name === 'Reject')
    // }
    if (auth.userRole.includes('GDC') || auth.userRole.includes('TMLBSL') || auth.userRole.includes('Purchase')
       || auth.userRole.includes('admin')
      ) {
      return primaryActions.filter((item) => item.name === 'Approve' || item.name === 'Reject')
    }
     if (auth.userRole.includes('admin')) {
      return primaryActions.filter((item) => item.name === 'Submit Data' )
    }
    return primaryActions
  }


const handleRowSelect = (row) => {
  console.log("row", row);
  const rowId = row.id; 
  setSelectedRows(prevSelectedRows => {
    const newSelectedRows = prevSelectedRows.includes(rowId)
      ? prevSelectedRows.filter(id => id !== rowId) 
      : [...prevSelectedRows, rowId]; 

    return newSelectedRows; 
  });
};
// const selectedRowData = tableArray.filter(row => selectedRows.includes(row.id)); // Define selectedRowData based on selected row IDs

  console.log('tableArray',tableArray);

 return (
    <>
      <div className={styles.container} style={{ boxSizing: "border-box" }}>
        <div>
          <div className={styles.backButtonLastRefreshedDateWrapper} style={{ marginTop: '10px' }}>
            <BackButton
              action="Back"
              handleClick={redirectToApplicationLandingPage}
              style={{ marginBottom: '0px' }}
            />
            <div
              style={{ width: '100%' }}
            // style={{ display: 'flex', flexDirection: 'row-reverse' }}
            >
              <div className={styles.topDiv}>
                <div className={styles.processHeader} style={{ display: 'flex', height: '3em' }}>
                  <div style={{ width: "100%" }}>
                    <CustomTab title="Supplier DVA" withOutCount isSelected />
                  </div>

                  {/* <StepBar1 enable={5} /> */}

                </div>
              </div>
            </div>


          </div>


        </div>
        <div>
          <div className={styles.formContainer}>
            <CustomFormGroup body={renderHTML()} />
          </div>
        </div>
        {flag && (
          <div
            className={styles.tableView}
            style={{ height: "86%", padding: "0 2em" }}
          >
            <Table
              // columns={[...baseDefaultColumns]}
              // defaultColumns={[...baseDefaultColumns]}
              // columns={!auth.userRole.includes('Vendor') ? [...baseDefaultColumnsGDC] : [...baseDefaultColumns]}
              // defaultColumns={!auth.userRole.includes('Vendor') ? [...baseDefaultColumnsGDC] : [...baseDefaultColumns]}
              columns={auth.userRole.includes('Vendor') || auth.userRole.includes('admin') 
                ? [...baseDefaultColumns] 
                : [...baseDefaultColumnsGDC]}
              defaultColumns={auth.userRole.includes('Vendor') || auth.userRole.includes('admin') 
                ? [...baseDefaultColumns] 
                : [...baseDefaultColumnsGDC]}
              rowHeight={38}
              rows={tableArray}
              primaryAction={handlePrimaryActionOnRole()}
              secondaryActions={handleSecondaryActionOnRole()}
              setAsnCount={setCount}
              isDataLoading={loader}
              actionButtons
              onRowSelect={(e)=>{console.log("eeeeeeee", e);handleRowSelect(e)}} 
              selectedRows={selectedRows}  
               removeRowSelection={false}   // Allow row selection
            />
          </div>
        )}
      </div>
      {/* <Button
            className={clsx(
              styles.actionButton,
              styles.primaryActionButton,
            )}
            variant="primary"
            // onClick={onDelete}
          >
            Yes
          </Button> */}
      <ResetModalF
        isPopupOpen={isPopupOpenFormModal}
        handleCloseFormModal={handleCloseFormModal}
        goNextForm={goNextForm}
      />

      <ResetModal
        isPopupOpen={isPopupOpen}
        handleClose={handleClose}
        goNext={goNext}
      />
      <ValidationModal
        isValidatePopupOpen={isValidatePopupOpen}
        handleCloseValidatePopup={handleCloseValidatePopup}
        validationAPIFunction={validationAPIFunction}
      />
      <SubmitDataModal
        isSubmissionPopupOpen={isSubmissionPopupOpen}
        handleCloseSubmissionPopup={handleCloseSubmissionPopup}
        submitSupplierDVACollection={submitSupplierDVACollection}
      />
    </>
  );
};
function ValidationModal({ isValidatePopupOpen, handleCloseValidatePopup, validationAPIFunction }) {
  return (
    <Dialog
      open={isValidatePopupOpen}
      className={styles.popContainer}
      classes={{
        paper: styles.popupBox,
      }}
      data-testid="reset-popup"
    >
      <>
        <DialogTitle>
          <span className={styles.title}>
            <WarningIcon style={{ height: '18px', width: '18px' }} />
            <span style={{ marginLeft: "8px" }} className={styles.txt}>Confirm Validation</span>
          </span>
        </DialogTitle>
        <DialogContent className={styles.content}>
          <div>
            {/* <p> On confirmation, All existing data will be overwritten by the uploaded data.</p> */}
            <p>On confirmation, data will be validated.</p>
            <p>Are you sure ?</p>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            className={clsx(
              styles.actionButton,
              styles.transparentButton
            )}
            onClick={handleCloseValidatePopup}
          >Cancel</Button>
          <Button
            className={clsx(
              styles.actionButton,
              styles.primaryActionButton,
            )}
            variant="primary"
            onClick={(e) => {
              e.preventDefault();
              handleCloseValidatePopup()
              validationAPIFunction()
            }}
          >
            Yes
          </Button>
        </DialogActions>
      </>
    </Dialog>
  )
}
function SubmitDataModal({ isSubmissionPopupOpen, handleCloseSubmissionPopup, submitSupplierDVACollection }) {
  return (
    <Dialog
      open={isSubmissionPopupOpen}
      className={styles.popContainer}
      classes={{
        paper: styles.popupBox,
      }}
      data-testid="reset-popup"
    >
      <>
        <DialogTitle>
          <span className={styles.title}>
            <WarningIcon style={{ height: '18px', width: '18px' }} />
            <span style={{ marginLeft: "8px" }} className={styles.txt}>Confirm Submission</span>
          </span>
        </DialogTitle>
        <DialogContent className={styles.content}>
          <div>
            {/* <p> On confirmation, All existing data will be overwritten by the uploaded data.</p> */}
            <p>On confirmation, data will be Submitted.</p>
            <p>Are you sure ?</p>
            
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            className={clsx(
              styles.actionButton,
              styles.transparentButton
            )}
            onClick={handleCloseSubmissionPopup}
          >Cancel</Button>
          <Button
            className={clsx(
              styles.actionButton,
              styles.primaryActionButton,
            )}
            variant="primary"
            onClick={(e) => {
              e.preventDefault();
              handleCloseSubmissionPopup()
              submitSupplierDVACollection()
            }}
          >
            Yes
          </Button>
        </DialogActions>
      </>
    </Dialog>
  )
}
// function ResetModalF({ isPopupOpen, handleCloseFormModal, goNextForm }) {
//   return (
//     <Dialog
//       open={isPopupOpen}
//       className={styles.popContainer}
//       classes={{
//         paper: styles.popupBox,
//       }}
//       data-testid="reset-popup"
//     >
//       <>
//         <DialogTitle>
//           <span className={styles.title}>
//             <WarningIcon style={{ height: '18px', width: '18px' }} />
//             <span style={{ marginLeft: "8px" }} className={styles.txt}>Confirm Send Back</span>
//           </span>
//         </DialogTitle>
//         <DialogContent className={styles.content}>
//           <div>
//             {/* <p> On confirmation, All existing data will be overwritten by the uploaded data.</p> */}
//             <p>On confirmation, data will be sent back for Re-Submission</p>
//             <p>Are you sure ?</p>
//           </div>
//         </DialogContent>
//         <DialogActions>
//           <Button
//             className={clsx(
//               styles.actionButton,
//               styles.transparentButton
//             )}
//             onClick={handleCloseFormModal}
//           >Cancel</Button>
//           <Button
//             className={clsx(
//               styles.actionButton,
//               styles.primaryActionButton,
//             )}
//             variant="primary"
//             onClick={(e) => {
//               e.preventDefault();
//               handleCloseFormModal()
//               goNextForm()
//             }}
//           >
//             Yes
//           </Button>
//         </DialogActions>
//       </>
//     </Dialog>
//   )
// }



function ResetModalF({ isPopupOpen, handleCloseFormModal, goNextForm }) {
  // State to store the comment input
  const [comment, setComment] = useState('');
console.log("comment",comment)
  // Handle the comment input change
  const handleCommentChange = (event) => {
    setComment(event.target.value);
  };

  // Function to submit data with comment and close the popup
  const handleSubmitWithComment = () => {
    // Ensure the comment is passed as part of the function
    goNextForm(comment);

    // Clear the comment and close the popup
    // setComment('');
    handleCloseFormModal();
  };

  return (
    <Dialog
      open={isPopupOpen}
      className={styles.popContainer}
      classes={{
        paper: styles.popupBox,
      }}
      data-testid="reset-popup"
    >
      <>
        <DialogTitle>
          <span className={styles.title}>
            <WarningIcon style={{ height: '18px', width: '18px' }} />
            <span style={{ marginLeft: "8px" }} className={styles.txt}>Confirm Reject Action</span>
          </span>
        </DialogTitle>
        <DialogContent className={styles.content}>
          <div>
            <p>On confirmation, data will be Reject</p>
            <p>Please Add Reject Remark.</p>

            {/* Add Comment input field */}
            <TextField
              autoFocus
              margin="dense"
              label="Add Remark"
              fullWidth
              variant="outlined"
              value={comment}                 
              onChange={handleCommentChange}  
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            className={clsx(styles.actionButton, styles.transparentButton)}
            onClick={handleCloseFormModal}
          >
            Cancel
          </Button>
          <Button
            className={clsx(styles.actionButton, styles.primaryActionButton)}
            variant="primary"
            onClick={(e) => {
              e.preventDefault();
              handleSubmitWithComment();  // Submit with the comment
            }}
          >
            Yes
          </Button>
        </DialogActions>
      </>
    </Dialog>
  );
}
function ResetModal({ isPopupOpen, handleClose, goNext }) {
  return (
    <Dialog
      open={isPopupOpen}
      className={styles.popContainer}
      classes={{
        paper: styles.popupBox,
      }}
      data-testid="reset-popup"
    >
      <>
        <DialogTitle>
          <span className={styles.title}>
            <WarningIcon style={{ height: "18px", width: "18px" }} />
            <span style={{ marginLeft: "8px" }} className={styles.txt}>
              Confirm file Upload
            </span>
          </span>
        </DialogTitle>
        <DialogContent className={styles.content}>
          <div>
            <p>
              {" "}

              On confirmation ,Records will be appended.
            </p>
            <p>Are you sure ?</p>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            className={clsx(styles.actionButton, styles.transparentButton)}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            className={clsx(styles.actionButton, styles.primaryActionButton)}
            variant="primary"
            onClick={(e) => {
              e.preventDefault();
              handleClose();
              goNext();
            }}
          >
            Yes
          </Button>
        </DialogActions>
      </>
    </Dialog>
  );
}
ResetModalF.propTypes = {
  isPopupOpen: PropTypes.bool.isRequired,
  handleCloseFormModal: PropTypes.func.isRequired,
  goNextForm: PropTypes.func.isRequired,
};

ResetModal.propTypes = {
  isPopupOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  goNext: PropTypes.func.isRequired,
}

ValidationModal.propTypes = {
  isValidatePopupOpen: PropTypes.bool.isRequired,
  handleCloseValidatePopup: PropTypes.func.isRequired,
  validationAPIFunction: PropTypes.func.isRequired,
}
SubmitDataModal.propTypes = {
  isSubmissionPopupOpen: PropTypes.bool.isRequired,
  handleCloseSubmissionPopup: PropTypes.func.isRequired,
  submitSupplierDVACollection: PropTypes.func.isRequired,
}

export default withAllowedOperationsProvider(VendarData, RESOURCE_TYPE.PROJECT);

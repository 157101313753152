/* eslint-disable radix */
/* eslint-disable no-param-reassign */
/* eslint-disable import/no-unresolved */
/* eslint-disable func-names */
/* eslint-disable one-var */
/* eslint-disable react/self-closing-comp */
/* eslint-disable vars-on-top */
/* eslint-disable eqeqeq */
/* eslint-disable prefer-const */
/* eslint-disable no-var */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-use-before-define */
/* eslint-disable array-callback-return */
/* eslint-disable camelcase */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import {
  Grid
} from '@material-ui/core'
import * as FileSaver from 'file-saver'
import XLSX from 'sheetjs-style'
import clsx from 'clsx'
import { useHistory } from 'react-router-dom'
import Table from '../../components/Table/Table'
import styles from './CommodityDashboard.module.css'
import { CustomFormGroup, CustomSelect } from '../../components/FormComponents'
import BackButton from '../../components/BackButton/BackButton'
import {
  // DATE_FORMAT,
  // API_RESOURCE_URLS,
  // DISPLAY_MESSAGES,
  MESSAGE_TYPE,
  RESOURCE_TYPE,
  // USER_OPERATIONS,
  // Regex
} from '../../constants'
import { withAllowedOperationsProvider } from '../../hocs'
import { CustomTab, Button } from '../../atomicComponents'
import { usePopupManager } from '../../providers/PopupManager/PopupManager'

const CommodityDashboard = () => {

  const history = useHistory()
  const { showPopup } = usePopupManager()
  const baseUrl = useSelector(state => state.counter.baseUrl)

  const [flag, show] = useState(false)
  const [tableArray, setTableArray] = useState([])
  const [asnCount, setAsnCount] = useState(0)
  const [financialYear, setFinancialYear] = useState({
    value: '2023-24',
    label: '2023-24'
  })

  const redirectToApplicationLandingPage = () => {
    history.push('/')
  }

  const setCount = count => {
    setAsnCount(count)
  }

  useEffect(() => {
    getCommodityWiseVendorCountView()
  }, [])



  const getCommodityWiseVendorCountView = () => {
    let headerss = new Headers()
    headerss.append('Content-Type', 'application/json')
    // fetch(`${baseUrl}MIS_Supplier_data/`,
    console.log('FY Value: ', financialYear?.value)
    fetch(
      `${baseUrl}CommodityWiseVendorCountView/?financial_year=${financialYear?.value}`,
      {
        method: 'GET',
        headers: headerss
      }
    )
      .then(response => {
        if (!response?.ok) {
          return response.json().then(error => {
            showPopup({
              type: MESSAGE_TYPE.FAILURE,
              contextText: MESSAGE_TYPE.FAILURE,
              info: `No data found for FY: ${financialYear?.value}!`
            })
            // throw new Error(`Error ${response.status}: ${error.message}`)
            throw new Error(`No data found for FY: ${financialYear?.value}!`)
          })
        }
        return response.json()
      })
      .then(response => {
        const tableData = response?.map(
          (
            { commodity, not_submitted_count, submitted_count, total_vendors },
            id
          ) => {
            let obj = {
              id,
              Commodity: commodity,
              Not_Submitted_Count: not_submitted_count,
              Submitted_Count: submitted_count,
              Total_Vendors: total_vendors
            }
            return obj
          }
        )
        setTableArray(tableData)
      })
      .catch(() => {
        showPopup({
          type: MESSAGE_TYPE.FAILURE,
          contextText: MESSAGE_TYPE.FAILURE,
          info: `No data found for FY: ${financialYear?.value}!`
        })
      })
  }


  const exportAFSandPPEDashboardExcel = () => {
    var fileTypel =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
    var fileExt = '.xlsx'
    const excelData = tableArray?.map(
      (
        { Commodity, Not_Submitted_Count, Submitted_Count, Total_Vendors },
        id
      ) => {
        let obj = {
          id,
          Commodity,
          Number_Of_Suppliers: Total_Vendors,
          AFS_And_PPE_Received: Submitted_Count,
          AFS_And_PPE_Pending: Not_Submitted_Count
        }
        return obj
      }
    )

    const ws = XLSX.utils.json_to_sheet(excelData)
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] }
    const excelBuffer = XLSX.write(wb, { booktype: 'xlsx', type: 'array' })
    const data = new Blob([excelBuffer], { type: fileTypel })
    FileSaver.saveAs(data, `AFS&PPEDashboardDataCV${fileExt}`)
  }

  const baseDefaultColumns = [
    {
      width: 200,
      title: 'Commodity',
      field: 'Commodity',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 150,
      title: 'Number of Suppliers',
      field: 'Total_Vendors',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 280,
      title: 'AFS and PPE Received',
      field: 'Submitted_Count',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 170,
      title: 'AFS and PPE Pending',
      field: 'Not_Submitted_Count',
      enableSearch: true,
      enableFilter: true
    }
  ]

  const handleSelect = (selectedOption, name) => {
    if(name == 'FY') {
      show(false)
      setFinancialYear(selectedOption)
    }
  }

  const renderHTML = () => (
    <div
    >
      <Grid
        container
        style={{ display: 'flex' }}
        columns={12}
      >
        <Grid item md={7} lg={7}>
          <div className={styles.rowDiv}>
            <div className={clsx(styles.lableDiv)} style={{ width: '20%' }}>
              <label className={styles.label}>Financial Year</label>
            </div>
            <div className={styles.formDiv}>
              <CustomSelect
                name='FY'
                options={[
                  { value: '2023-24', label: '2023-24' },
                  { value: '2022-23', label: '2022-23' },
                ]}
                className={clsx(styles.select, styles.sel1)}
                value={financialYear}
                isClearable
                onChange={e => {
                  handleSelect(e, 'FY')
                }}
                style={{ width: '40%' }}
              />
              <div
                style={{
                  marginLeft: '20px',
                  marginTop: '1px',
                  justifyContent: 'center',
                  display: 'flex'
                }}
              >
                <Button
                  className={clsx(
                    styles.actionButton,
                    styles.primaryActionButton
                  )}
                  variant='primary'
                  onClick={() => {
                    if (!financialYear?.value) {
                      showPopup({
                        type: MESSAGE_TYPE.INFORMATION,
                        contextText: MESSAGE_TYPE.INFORMATION,
                        info: 'Please select Financial Year!'
                      })
                    } else {
                      getCommodityWiseVendorCountView()
                    }
                  }}
                >
                  Show Data
                </Button>
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  )
  return (
    <>
      <div className={styles.container} style={{ boxSizing: 'border-box' }}>
        <div>
          <div className={styles.backButtonLastRefreshedDateWrapper}>
            <BackButton
              action="Back"
              handleClick={redirectToApplicationLandingPage}
            />
            <div className={styles.topDiv}>
              <div className={styles.processHeader}>
                <div style={{ width: '100%' }}>
                  <CustomTab title="AFS and PPE Dashboard" withOutCount isSelected />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className={styles.formContainer}>
            <CustomFormGroup
              body={renderHTML()}
            />
          </div>
        </div>

        <div
          className={styles.tableView}
          style={{ height: '86%', padding: '0 2em' }}
        >
          <Table
            columns={[...baseDefaultColumns]}
            defaultColumns={[...baseDefaultColumns]}
            rowHeight={38}
            rows={tableArray}
            secondaryActions={[
              {
                name: 'Download',
                authOperation: 'download',
                shouldEnable: selected => true,
                actionFn: selected => { exportAFSandPPEDashboardExcel() },
                customClass: ""
              },
            ]}
            setAsnCount={setCount}
            isDataLoading={false}
            actionButtons
            onRowSelect={(e) => { }}
            removeRowSelection
          />
        </div>

      </div>
    </>)
}

export default withAllowedOperationsProvider(
  CommodityDashboard,
  RESOURCE_TYPE.PROJECT
);
/* eslint-disable react/self-closing-comp */
/* eslint-disable vars-on-top */
/* eslint-disable eqeqeq */
/* eslint-disable prefer-const */
/* eslint-disable no-var */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-use-before-define */
/* eslint-disable array-callback-return */
/* eslint-disable camelcase */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import {
  Modal,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Form,
  Box,
  TextField,
  Grid,
  FormGroup,
  IconButton
} from '@material-ui/core'
import WarningIcon from '@mui/icons-material/Warning'
import FormLabel from '@mui/material/FormLabel'
import * as FileSaver from 'file-saver'
import XLSX from 'sheetjs-style'
// for print user in consol
import jwt_decode from 'jwt-decode'
import { useSelector } from 'react-redux'
// for print user in consol
import axios from 'axios'

import clsx from 'clsx'
// import $ from 'jquery';
import { useHistory } from 'react-router-dom'
import Table from '../../components/Table/Table'
// import Table from '../../components/Table1/Table1'
import styles from './SupplierDataPurchasePVPage3.module.css'
import { CustomFormGroup, CustomSelect } from '../../components/FormComponents'
import BackButton from '../../components/BackButton/BackButton'
import {
  DATE_FORMAT,
  API_RESOURCE_URLS,
  DISPLAY_MESSAGES,
  MESSAGE_TYPE,
  MESSAGE_TYPE_CERTIFICATION,
  MESSAGE_TYPE_ANNEXURE,
  RESOURCE_TYPE,
  USER_OPERATIONS,
  Regex
} from '../../constants'
import { withAllowedOperationsProvider } from '../../hocs'
import { CustomTab, Button, AuthChecker } from '../../atomicComponents'
import { API } from '../../apis/api'
import { buildErrorMessage } from '../../apis/calls'
import { usePopupManager } from '../../providers/PopupManager/PopupManager'

import StepBar1 from '../../components/StepBar1/StepBar'

const VendarData = () => {
  const history = useHistory()
  const { showPopup } = usePopupManager()
  // const baseUrl='http://127.0.0.1:8000/file/'
  const baseUrl = useSelector(state => state.counter.baseUrl) // useSelector
  const auth = useSelector(state => state.auth) // useSelector
  const { userRole, userID } = auth
  // console.log('auth in Supplier: ',);
  // const [dynamicColumns, setDynamicColumns] = useState([]);
  // const [dynamicColumns1, setDynamicColumns1] = useState([]);
  const [plant, setPlant] = useState(null)
  const [flag, show] = useState(false)
  const [checkDisable, setDisable] = useState(true)
  const [Bc, setBC] = useState(null)
  const [projectCode, setProjectCode] = useState(null)
  const [bcArray, setBcArray] = useState([])
  // const [plantArray, setPlantArray] = useState([]);
  const [tableArray, setTableArray] = useState([])
  // const [Fy,setFY] = useState(null)
  const [fyArray, setFyArray] = useState([])
  // const [quarter,setQuarter] = useState(null)
  const [quarterArray, setQuarterArray] = useState([])
  const [projectCodeArray, setProjectCodeArray] = useState([])
  const [asnCount, setAsnCount] = useState(0)
  const [loading, setLoader] = useState(false)
  const [loader, setLoading] = useState(true)
  const [vendCode, setvendCode] = useState()
  // const [datesData, setDates] = useState({
  //   endDate: "",
  //   startDate: "",
  // });

  const [selectedProjectCode, setSelectedProjectCode] = useState(null)
  const [latestProject, setLatestProject] = useState('')
  const [recentProjectCodeDescription, setRecentProjectCodeDescription] =
    useState()

  const [isPopupOpenFormModal, setPopupOpenFormModal] = useState(false)
  const handleOpenFormModal = () => {
    setPopupOpenFormModal(true)
  }
  const handleCloseFormModal = () => setPopupOpenFormModal(false)

  //

  const ROW_HEIGHT = 38

  const [isPopupOpen, setIsPopupOpen] = useState(false)
  const handleOpen = () => setIsPopupOpen(true)
  const handleClose = () => setIsPopupOpen(false)
  const goNext = () => {
    uploadFileData()
  }

  const redirectToApplicationLandingPage = () => {
    history.push('/')
  }

  const setCount = count => {
    setAsnCount(count)
  }

  useEffect(() => {
    // getData()
    // getdate()

    getRecentProjectCode()
    getAllProjectCode()
    //  Uploadcertification();
    // }, [bcArray])
  }, [])
  const newDate = new Date();
  const formattedDate = newDate.toLocaleDateString('en-GB')

  const GetSupplierCodeAPI = async latestProject1 => {
    console.log(
      'Supplier res PRO: ',
      selectedProjectCode?.value || latestProject1?.value
    )
    const headerss = {
      'Content-Type': 'application/json'
    }
    const apiFormattedDataJson = JSON.stringify({
      Project_code: selectedProjectCode?.value || latestProject1?.value
    })
    // try {
    const { data } = await axios.post(
      `${baseUrl}GetSupplierCode/`,
      apiFormattedDataJson,
      { headers: headerss }
    )
    console.log('supplier res: ', data)

    const dropdownOptions = data?.Supplier_codes.filter(code => code) // Filter out empty strings and null values
      .map(code => ({ value: code, label: code }))
    console.log('Supplier res option: ', dropdownOptions)
    setBcArray(dropdownOptions)

    if (userRole.includes('Vendor-CV') || userRole.includes('Vendor')) {
      const singleVendor = dropdownOptions.find(item => item.value === userID)
      if (singleVendor) {
        setBC(singleVendor)
        setDisable(true) // Disable UI elements
      }
    } else {
      setDisable(false) // Enable UI elements
    }

    // const allProjectCodes = data.map(({ Project_code }) => Project_code);
    // const allProjectCodes = data.map(({ Project_code }) => Project_code);
    // const uniqueProjects = [...new Set(allProjectCodes)];
    // const projectCodeOptions = uniqueProjects.map((value) => ({
    //   value,
    //   label: value,
    // }));

    // setProjectCodeArray(projectCodeOptions)
    // const projectDesc = uniqueProjects?.maps((item) => ( item?.Project_code === latestProject))
    // const projectDesc = uniqueProjects.map((item) => {
    //   console.log('item: ', item);
    //   return item?.Project_code === latestProject})
    // console.log('projectDesc: ', projectDesc)
    // console.log('projectdesc latest: ', latestProject, data)
    // // const lastElement = array[array.length - 1];
    // const latestProjectCodeDesc = data[data?.length - 1]?.Project_code_Description
    // console.log('projectDesc last: ', latestProjectCodeDesc);
    // setRecentProjectCodeDescription(latestProjectCodeDesc)
    // await getRecentProjectCode(projectCodeOptions);
    // const recentProjectCodeDescription =
    // } catch (error) {
    //   console.error("Error fetching project codes:", error);
    // }
  }

  const getAllProjectCode = async () => {
    try {
      // const { data } = await axios.get(`${baseUrl}AllProjectCode/`);
      const { data } = await axios.get(
        `${baseUrl}AllProjectCodeforProductline/`
      )
      const allProjectCodes = data.map(({ Project_code }) => Project_code)
      const uniqueProjects = [...new Set(allProjectCodes)]
      const projectCodeOptions = uniqueProjects.map(value => ({
        value,
        label: value
      }))

      setProjectCodeArray(projectCodeOptions)
      // const projectDesc = uniqueProjects?.maps((item) => ( item?.Project_code === latestProject))
      const projectDesc = uniqueProjects.map(item => {
        console.log('item: ', item)
        return item?.Project_code === latestProject
      })
      console.log('projectDesc: ', projectDesc)
      console.log('projectdesc latest: ', latestProject, data)
      // const lastElement = array[array.length - 1];
      const latestProjectCodeDesc =
        data[data?.length - 1]?.Project_code_Description
      console.log('projectDesc last: ', latestProjectCodeDesc)
      setRecentProjectCodeDescription(latestProjectCodeDesc)
      // await getRecentProjectCode(projectCodeOptions);
      // const recentProjectCodeDescription =

      // GetSupplierCodeAPI()
    } catch (error) {
      console.error('Error fetching project codes:', error)
    }
  }

  const getRecentProjectCode = async projectCodeOptions => {
    try {
      // const { data, status } = await axios.get(`${baseUrl}LatestProjectCode/`);
      const { data, status } = await axios.get(
        `${baseUrl}LatestProjectCodeforProductline/`
      )
      if (status !== 200) {
        throw new Error('Failed to fetch the latest project code')
      }
      console.log('data in Project_code: ', data)
      const recentProj = {
        label: data?.Project_code,
        value: data?.Project_code
      }
      setLatestProject(recentProj)
      setProjectCode(recentProj)
      setSelectedProjectCode(recentProj) // Automatically select the latest project code
      GetSupplierCodeAPI(recentProj)
      // getVcForProjectCode(recentProj)
      // const recentProjectCodeDescription =
    } catch (error) {
      // console.error("Error fetching recent project codes:", error);
      showPopup({
        type: MESSAGE_TYPE.FAILURE,
        contextText: MESSAGE_TYPE.FAILURE,
        info: 'Could not fetch Project Code!'
      })
    }
  }

  const handleDropdownChange = (selectedOption, name) => {
    console.log('selectedOption: ', selectedOption)
    if (name === 'Project_code') {
      setSelectedProjectCode(selectedOption)
      // if (selectedProjectCode !== null) {
      //   getVcForProjectCode(selectedOption)
      // }
    }
  }

  const showTable = () => {
    console.log('Project in show: ', projectCode)

    if (projectCode === null || projectCode.value === '') {
      showPopup({
        type: MESSAGE_TYPE.INFORMATION,
        contextText: MESSAGE_TYPE.INFORMATION,
        info: 'Project Code is Mandatory'
      })
    } else {
      getTableData()
      show(true)
    }
  }

  // let Vendor_Code ='';
  const handleSelect = (selectedOption, name) => {
    // alert(selectedOption.value);
    // console.log(selectedOption);
    setvendCode(selectedOption.value)
    if (name == 'VC') {
      show(false)

      setBC(selectedOption)
      // getPlantData(selectedOption)
    } else {
      setPlant(selectedOption)
    }
  }

  // const getTableData = () => {
  //   const apiFormatedData = {
  //     Project_code : projectCode?.value,
  //     Supplier_code: Bc?.value,
  //     // Financial_year : Fy?.value,
  //     // Quarter: quarter?.value,
  //     // Plant_code : plant?.value
  //   };
  //   var apiFormatedDataJson = JSON.stringify(apiFormatedData);
  //   let headerss = new Headers();
  //   headerss.append("Content-Type", "application/json");
  //   // fetch(`${baseUrl}SupplierDeclaration/`, {
  //   fetch(`${baseUrl}VendorUnderPLIIncentiveData/`, {
  //     method: "POST",
  //     headers: headerss,
  //     body: apiFormatedDataJson,
  //   })
  //     .then((response) => response.json())
  //     .then((responseJson) => {
  //       const tableData = responseJson.data;

  //       setTableArray(tableData);
  //       // const excelData = tableData.map(
  //       //   (
  //       //     {
  //       //       Vendor_Code,
  //       //       Plant_code,
  //       //       Part_number,
  //       //       Part_Description,
  //       //       Selling_Price,
  //       //       Value_of_Direct_Import_by_us,
  //       //       Broad_Description_of_parts_imported_by_us,
  //       //       Value_of_parts_imported_by_our_suppliers,
  //       //       Broad_Description_of_parts_imported_by_our_suppliers,
  //       //       Local_Content,
  //       //       DVA,

  //       //     },
  //       //     id
  //       //   ) => {
  //       //     let obj = {
  //       //       id,
  //       //       Vendor_Code,
  //       //       Plant_code,
  //       //       Part_number,
  //       //       Part_Description,
  //       //       // Vendor_Code,
  //       //       // Plant_code,
  //       //       // Part_number,
  //       //       // Part_Description,
  //       //       // Part_number,
  //       //       // Part_Description,

  //       //       Selling_Price,
  //       //       Value_of_Direct_Import_by_us,
  //       //       Broad_Description_of_parts_imported_by_us,
  //       //       Value_of_parts_imported_by_our_suppliers,
  //       //       Broad_Description_of_parts_imported_by_our_suppliers,
  //       //       Local_Content,
  //       //       DVA,
  //       //     };
  //       //     return obj;
  //       //   }
  //       // );

  //       // setTableArray(excelData)
  //       setLoading(false)
  //     })
  //     .catch((error) => {});
  // }

  const getTableData = async () => {
    const apiFormatedData = {
      Project_code: projectCode?.value,
      Supplier_code: Bc?.value
    }
    try {
      const response = await axios.post(
        `${baseUrl}VendorUnderPLIIncentiveData/`,
        apiFormatedData,
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )
      if (response.status === 200 || response.status === 201) {
        const tableData = response?.data?.data
        setTableArray(tableData)
        setLoading(false)
      } else {
        console.error('Error fetching table data:', response.statusText)
        showPopup({
          type: MESSAGE_TYPE.FAILURE,
          contextText: MESSAGE_TYPE.FAILURE,
          info: `Error: ${response.statusText}`
        })
      }
    } catch (error) {
      console.error('Error fetching table data:', error)
      showPopup({
        type: MESSAGE_TYPE.FAILURE,
        contextText: MESSAGE_TYPE.FAILURE,
        info: `Error: ${error.message}`
      })
    }
  }

  const getAllData = () => {
    let headerss = new Headers()
    headerss.append('Content-Type', 'application/json')
    fetch(`${baseUrl}AllSupplierDeclarationData/`, {
      method: 'GET',
      headers: headerss
    })
      .then(response => response.json())
      .then(responseJson => {
        if (responseJson === null || responseJson.length === 0) {
          // setTableArray(responseJson)
          showPopup({
            type: MESSAGE_TYPE.FAILURE,
            contextText: MESSAGE_TYPE.FAILURE,
            info: 'No data available'
          })
        } else {
          setTableArray(responseJson)
          show(true)
          setLoading(false)
        }
      })
      .catch(error => {
        console.log('error', error)
        showPopup({
          type: MESSAGE_TYPE.FAILURE,
          contextText: MESSAGE_TYPE.FAILURE,
          info: 'Error while fetching data.'
        })
      })
  }

  const getTableData1 = value => {
    const apiFormatedData = {
      VC: value
    }
    var apiFormatedDataJson = JSON.stringify(apiFormatedData)
    let headerss = new Headers()
    headerss.append('Content-Type', 'application/json')

    fetch(`${baseUrl}SupplierDataadditionPV/`, {
      method: 'POST',
      headers: headerss,
      body: apiFormatedDataJson
    })
      .then(response => {
        if (response.ok) {
          return response.json()
        }
        throw new Error('Failed to fetch data')
      })
      // .then((response)=>response.json())
      .then(responseJson => {
        console.log('response data coming : ', responseJson)
        if (responseJson.data === null || responseJson.status === 500) {
          showPopup({
            type: MESSAGE_TYPE.FAILURE,
            contextText: MESSAGE_TYPE.FAILURE,
            info: 'Data not found'
          })
        } else {
          const tableData = responseJson.data
          setTableArray(tableData)
          console.log('Table array response: ', tableData)
        }
        // const excelData = tableData.map(
        //   (
        //     {
        //       Vendor_Code,
        //       Plant_code,
        //       Part_number,
        //       Part_Description,
        //       Selling_Price,
        //       Value_of_Direct_Import_by_us,
        //       Broad_Description_of_parts_imported_by_us,
        //       Value_of_parts_imported_by_our_suppliers,
        //       Broad_Description_of_parts_imported_by_our_suppliers,
        //       Local_Content,
        //       DVA,

        //     },
        //     id
        //   ) => {
        //     let obj = {
        //       id,
        //       Vendor_Code,
        //       Plant_code,
        //       Part_number,
        //       Part_Description,
        //       Selling_Price,
        //       Value_of_Direct_Import_by_us,
        //       Broad_Description_of_parts_imported_by_us,
        //       Value_of_parts_imported_by_our_suppliers,
        //       Broad_Description_of_parts_imported_by_our_suppliers,
        //       Local_Content,
        //       DVA,
        //     };
        //     return obj;
        //   }
        // );

        // exportExcel(excelData);
      })
      .catch(error => {
        console.log(error)
        // setDisable(false)
        show(false)
        showPopup({
          type: MESSAGE_TYPE.FAILURE,
          contextText: MESSAGE_TYPE.FAILURE,
          info: `${error.message}`
        })
      })
  }

  // const getData = () => {
  //   let headerss = new Headers();
  //   headerss.append("Content-Type", "application/json");
  //   // fetch(`${baseUrl}SupplierDeclaration/`, {
  //   fetch(`${baseUrl}SupplierDeclaration/`, {
  //     method: "GET",
  //     headers: headerss,
  //   })
  //     .then((response) => response.json())
  //     .then((responseJson) => {
  //     const uniqueSuppliers = [...new Set(responseJson.map(item => item.Supplier_Code))];
  //     const uniqueProjects = [...new Set(responseJson.map(item => item.Project_code))];
  //     // const uniquePlants = [...new Set(responseJson.map(item => item.Plant_code))];
  //     const uniqueQuarters = [...new Set(responseJson.map(item => item.Quarter))];
  //     const uniqueYears = [...new Set(responseJson.map(item => item.Financial_year))];
  //     console.log(uniqueSuppliers)
  //       // const ListArray = responseJson.map(({ Vendor_Code }) => ({
  //       //   value: Vendor_Code,
  //       //   label: Vendor_Code,
  //       // }));
  //       if(bcArray.length === 0){
  //         setBcArray(uniqueSuppliers.map(value => ({ value, label: value })));
  //       }
  //       console.log(bcArray)
  //       setProjectCodeArray(uniqueProjects.map(value => ({ value, label: value })));
  //       console.log(projectCodeArray)
  //       // setPlantArray(uniquePlants.map(value => ({ value, label: value })));
  //       setQuarterArray(uniqueQuarters.map(value => ({ value, label: value })));
  //       setFyArray(uniqueYears.map(value => ({ value, label: value })));

  //       // let { userRole, userID } = auth;

  //       // if (userRole.includes("Vendor-CV") || userRole.includes("Vendor-CV") || userRole.includes("Vendor")) {
  //       //   const singleVendor = ListArray.filter(
  //       //     (element) => element.value == userID
  //       //   );
  //       //   if (singleVendor.length >= 1) {
  //       //     setBC(singleVendor[0]);
  //       //     setDisable(true);
  //       //     show(true); // table show
  //       //     getTableData1(singleVendor[0].value);
  //       //   }
  //       // } else {
  //       //   setDisable(false);
  //       // }
  //       if (userRole.includes("Vendor-CV") || userRole.includes("Vendor")) {
  //         const singleVendor = bcArray.find(item => item.value === userID);
  //         if (singleVendor) {
  //           setBC(singleVendor);
  //           setDisable(true); // Disable UI elements
  //         }
  //       } else {
  //         setDisable(false); // Enable UI elements
  //       }
  //     })
  //     .catch((error) => {
  //       console.error('Error fetching data:', error);
  //     });
  // };
  const uploadFileData = () => {
    var input = document.createElement('input')
    input.type = 'file'
    input.id = 'file'
    input.setAttribute(
      'accept',
      '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
    )
    input.click()
    input.onchange = e => {
      setLoader(true)
      const formData = new FormData()
      formData.append('file', e.target.files[0])
      fetch(`${baseUrl}SupplierDataadditionPV/`, {
        method: 'POST',
        body: formData
      })
        .then(response => {
          // eslint-disable-next-line radix
          let status1 = parseInt(response.status)
          if (status1 == '201') {
            //   document.removeChild(input);
            getTableData()
            showPopup({
              type: MESSAGE_TYPE.SUCCESS,
              contextText: MESSAGE_TYPE.SUCCESS,
              info: 'file uploaded sucessfully'
            })
          } else {
            showPopup({
              type: MESSAGE_TYPE.SUCCESS,
              contextText: MESSAGE_TYPE.SUCCESS,
              info: 'file uploaded failed'
            })
          }
        })

        .catch(error => {})
    }
  }
  // const uploadDocument = () => {
  //   var input = document.createElement('input')
  //   input.type = 'file'
  //   input.id = 'file'
  //   input.setAttribute('accept', '.xlsx')
  //   input.click()

  //   input.onchange = e => {
  //     const formData = new FormData()
  //     formData.append('file', e.target.files[0])
  //     // formData.append("Supplier_Code", Bc?.value);
  //     // formData.append('Project_code', projectCode?.value)
  //     // fetch(`${baseUrl}SupplierDeclaration/`, {
  //     fetch(`${baseUrl}uploadunderPLIdata/`, {
  //       // method: "PUT",
  //       method: 'POST',
  //       body: formData
  //     })
  //       // .then((response) => {
  //       //   // console.log(response)
  //       //   // return response.json()
  //       // }

  //       .then(response => {
  //         console.log(response)
  //         if (response.status == 201) {
  //           showPopup({
  //             type: MESSAGE_TYPE.SUCCESS,
  //             //  contextText:getUrl(certificateUrl),
  //             contextText: MESSAGE_TYPE.SUCCESS,
  //             info: 'Document Uploaded Successfully!'
  //           })
  //           // return response.json()
  //         } else if (response.status == 400) {
  //           showPopup({
  //             type: MESSAGE_TYPE.FAILURE,
  //             contextText: MESSAGE_TYPE.FAILURE,
  //             info: `${response.data.status}`
  //           })
  //         } else {
  //           showPopup({
  //             type: MESSAGE_TYPE.FAILURE,
  //             contextText: MESSAGE_TYPE.FAILURE,
  //             info: `Server error: ${response.data.status}`
  //           })
  //         }
  //       })
  //       // .then((responseJson) => {
  //       //   console.log(responseJson);
  //       // //  var certificateurlstampurl = 'https://tml-test-digital-invoice-s3.s3.amazonaws.com/Certificate%20%2838%29_23-12-2022T11%3A02%3A38.pdf?response-content-disposition=attachment&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAQGDNMWVUMVQVJ4YB%2F20221223%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20221223T110240Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=4da98e95872b5a7d4f2b15a2f524ce1d18178ba15b0923a4ca1b8605ad79bd1a';

  //       //   if (responseJson) {
  //       //     showPopup({
  //       //       type: MESSAGE_TYPE.SUCCESS,
  //       //       //  contextText:getUrl(certificateUrl),
  //       //         contextText:MESSAGE_TYPE.SUCCESS,
  //       //       info:"Document Uploaded Successfully"

  //       //     });
  //       //   } else {
  //       //     showPopup({
  //       //       type: MESSAGE_TYPE.FAILURE,
  //       //       contextText: MESSAGE_TYPE.FAILURE,
  //       //        info: responseJson.url,
  //       //     });
  //       //   }

  //       //   // eslint-disable-next-line radix
  //       //   // let status1 = parseInt(response.status);
  //       //   //  console.log(response.status)
  //       //   // if (status1 == "200") {
  //       //   //   //   document.removeChild(input);
  //       //   // //  getTableData();

  //       //   // }
  //       // })

  //       .catch(error => {
  //         showPopup({
  //           type: MESSAGE_TYPE.FAILURE,
  //           contextText: MESSAGE_TYPE.FAILURE
  //           //  info: responseJson.url,
  //         })
  //       })
  //   }
  // }

  const uploadDocument = () => {
    var input = document.createElement('input')
    input.type = 'file'
    input.id = 'file'
    input.setAttribute('accept', '.xlsx')
    input.click()

    input.onchange = async e => {
      const formData = new FormData()
      formData.append('file', e.target.files[0])

      try {
        const response = await axios.post(
          `${baseUrl}uploadunderPLIdata/`,
          formData,
          {
            headers: { 'Content-Type': 'multipart/form-data' }
          }
        )
        console.log('Upload res: ', response);
        
        if (response.status === 200 || response.status === 201) {
          showPopup({
            type: MESSAGE_TYPE.SUCCESS,
            contextText: MESSAGE_TYPE.SUCCESS,
            info: 'Document Uploaded Successfully!'
          })
          getTableData()
        } else {
          showPopup({
            type: MESSAGE_TYPE.FAILURE,
            contextText: MESSAGE_TYPE.FAILURE,
            info: `Document upload failed!`
          });
        }
      } catch (error) {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.error('Error response:', error.response);
          let errorMessage = 'Document upload failed!';
          
          if (error.response.status === 400) {
            // errorMessage = 'Bad Request: The server could not understand the request due to invalid syntax.';
            errorMessage = error?.response?.data?.error
          } else if (error.response.status === 401) {
            errorMessage = 'Unauthorized: Access is denied due to invalid credentials.';
          } else if (error.response.status === 404) {
            errorMessage = 'Not Found: The server can not find the requested resource.';
          } else if (error.response.status === 500) {
            errorMessage = `Internal Server Error: The server has encountered a situation.`;
          }
  
          showPopup({
            type: MESSAGE_TYPE.FAILURE,
            contextText: MESSAGE_TYPE.FAILURE,
            info: errorMessage
          });
        } else if (error.request) {
          // The request was made but no response was received
          console.error('Error request:', error.request);
          showPopup({
            type: MESSAGE_TYPE.FAILURE,
            contextText: MESSAGE_TYPE.FAILURE,
            info: 'No response received from server.'
          });
        } else {
          // Something happened in setting up the request that triggered an Error
          console.error('Error message:', error.message);
          showPopup({
            type: MESSAGE_TYPE.FAILURE,
            contextText: MESSAGE_TYPE.FAILURE,
            info: `Request error: ${error.message}`
          });
        }
      }
    };
  };

  const exportExcel = async excelData => {
    const newExcelData = excelData?.map(
      (
        {
          Project_code,
          Supplier_code,
          Part_no,
          Part_description,
          Supplier_pli,
          TA_certified_dva_percentage,
          PLI_claim_effective_date,
          Financial_year,
          Quarter
        },
        id
      ) => {
        let obj = {
          id,
          Project_code,
          Supplier_code,
          Part_no,
          Part_description,
          Supplier_pli,
          TA_certified_dva_percentage,
          PLI_claim_effective_date,
          Financial_year,
          Quarter
        }
        return obj
      }
    )
    var fileTypel =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
    var fileExt = '.xlsx'
    const ws = XLSX.utils.json_to_sheet(newExcelData)
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] }
    const excelBuffer = XLSX.write(wb, { booktype: 'xlsx', type: 'array' })
    const data = new Blob([excelBuffer], { type: fileTypel })
    FileSaver.saveAs(data, `${Bc.value}${fileExt}`)
  }

  const baseDefaultColumns = [
    {
      width: 130,
      title: 'Project Code',
      field: 'Project_code',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 200,
      title: 'Vendor Code',
      // field: "Supplier_Code",
      field: 'Supplier_code',
      enableSearch: true,
      enableFilter: true
    },
    // {
    //   width: 200,
    //   title: "Plant code",
    //   field: "Plant_code",
    //   enableSearch: true,
    //   enableFilter: true,
    // },
    {
      width: 200,
      title: 'Part No.',
      // field: "Part_number",
      field: 'Part_no',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 210,
      title: 'Part Description',
      // field: "Part_Description",
      field: 'Part_description',
      enableSearch: true,
      enableFilter: true
    },
    // {
    //   width: 200,
    //   title: "TML Part No. for eligible AAT Component",
    //   // field: "TML Part No. for eligible AAT Component",
    //   field: "Selling_Price",
    //   enableSearch: true,
    //   enableFilter: true,
    // },
    {
      width: 200,
      title: 'Availing PLI Incentive (Yes/No)',
      // field: "Availing PLI Incentive (Yes/No)",
      // field : "Availing_PLI_Incentive",
      field: 'Supplier_pli',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 200,
      // title: "Date post which PLI incentives will be claimed",
      // field: "Date post which PLI incentives will be claimed",
      title: 'PLI Claim Effective Date',
      field: 'PLI_claim_effective_date',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 200,
      title: 'TA Certified DVA %',
      // field: "TA certified DVA % ",
      field: 'TA_certified_dva_percentage',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 200,
      title: 'Financial Year',
      // field: "Financial Year",
      field: 'Financial_year',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 140,
      title: 'Quarter',
      field: 'Quarter',
      enableSearch: true,
      enableFilter: true
    }
    // {
    //   width: 140,
    //   title: "MPFD",
    //   field: "MPFD",
    //   enableSearch: true,
    //   enableFilter: true,
    // },
    // {
    //   width: 200,
    //   title: "Broad Description of parts imported by our suppliers",
    //   field: "Broad_Description_of_parts_imported_by_our_suppliers",
    //   enableSearch: true,
    //   enableFilter: true,
    // },
    // {
    //   width: 130,
    //   title: "Local Content",
    //   field: "Local_Content",
    //   enableSearch: true,
    //   enableFilter: true,
    // },
    // {
    //   width: 90,
    //   title: "DVA",
    //   field: "DVA",
    //   enableSearch: true,
    //   enableFilter: true,
    // },
    // {
    //   width: 140,
    //   title : "Financial Year",
    //   field : "Financial year",
    //   enableSearch : true,
    //   enableFilter : true,
    // },
    // {
    //   width: 150,
    //   title: "T2 Import INR",
    //   field: "T2_Import",
    //   enableSearch: true,
    //   enableFilter: true,
    // },
    // {
    //   width: 120,
    //   title: "Exception",
    //   field: "Exception",
    //   enableSearch: true,
    //   enableFilter: true,
    // },
    // {
    //   width: 150,
    //   title: "Exception Classification ",
    //   field: "Exception_Classification",
    //   enableSearch: true,
    //   enableFilter: true,
    // },
    // {
    //   width: 120,
    //   title: "Total Import INR",
    //   field: "Total_Import",
    //   enableSearch: true,
    //   enableFilter: true,
    // },
    // {
    //   width: 120,
    //   title: "DVA",
    //   field: "DVA",
    //   enableSearch: true,
    //   enableFilter: true,
    // },
    // {
    //   width: 120,
    //   title: "Claimed for PLI",
    //   field: "Claimed_for_PLI",
    //   enableSearch: true,
    //   enableFilter: true,
    // },
    // {
    //   width: 120,
    //   title: "Start Date",
    //   field: "Start_Date",
    //   enableSearch: true,
    //   enableFilter: true,
    // },
    // {
    //   width: 120,
    //   title: "Explanation",
    //   field: "Explanation",
    //   enableSearch: true,
    //   enableFilter: true,
    // },
  ]
  const baseDefaultColumnsGDC = [
    {
      width: 130,
      title: 'Project Code',
      field: 'Project_code',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 200,
      title: 'Vendor Code',
      field: 'Supplier_Code',
      enableSearch: true,
      enableFilter: true
    },
    // {
    //   width: 200,
    //   title: "Plant code",
    //   field: "Plant_code",
    //   enableSearch: true,
    //   enableFilter: true,
    // },
    {
      width: 200,
      title: 'Part No.',
      field: 'Part_number',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 210,
      title: 'Part Description',
      field: 'Part_Description',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 200,
      title: 'TML Part No. for eligible AAT Component',
      // field: "TML Part No. for eligible AAT Component",
      field: 'Selling_Price',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 200,
      title: 'Availing PLI Incentive (Yes/No)',
      // field: "Availing PLI Incentive (Yes/No)",
      field: 'Availing_PLI_Incentive',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 200,
      title: 'Date post which PLI incentives will be claimed',
      // field: "Date post which PLI incentives will be claimed",
      field: 'PLI_claim_effective_date',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 200,
      title: 'TA certified DVA % ',
      // field: "TA certified DVA % ",
      field: 'TA_certified_DVA_percentage',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 200,
      title: 'Financial Year',
      // field: "Financial Year",
      field: 'Financial_year',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 140,
      title: 'Quarter',
      field: 'Quarter',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 140,
      title: 'MPFD',
      field: 'MPFD',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 140,
      title: 'CA_SA',
      field: 'CA_SA',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 140,
      title: 'UDIN',
      field: 'UDIN',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 140,
      title: 'DVA_certifying_agency',
      field: 'DVA_certifying_agency',
      enableSearch: true,
      enableFilter: true
    }
    // {
    //   width: 200,
    //   title: "Broad Description of parts imported by our suppliers",
    //   field: "Broad_Description_of_parts_imported_by_our_suppliers",
    //   enableSearch: true,
    //   enableFilter: true,
    // },
    // {
    //   width: 130,
    //   title: "Local Content",
    //   field: "Local_Content",
    //   enableSearch: true,
    //   enableFilter: true,
    // },
    // {
    //   width: 90,
    //   title: "DVA",
    //   field: "DVA",
    //   enableSearch: true,
    //   enableFilter: true,
    // },
    // {
    //   width: 140,
    //   title : "Financial Year",
    //   field : "Financial year",
    //   enableSearch : true,
    //   enableFilter : true,
    // },
    // {
    //   width: 150,
    //   title: "T2 Import INR",
    //   field: "T2_Import",
    //   enableSearch: true,
    //   enableFilter: true,
    // },
    // {
    //   width: 120,
    //   title: "Exception",
    //   field: "Exception",
    //   enableSearch: true,
    //   enableFilter: true,
    // },
    // {
    //   width: 150,
    //   title: "Exception Classification ",
    //   field: "Exception_Classification",
    //   enableSearch: true,
    //   enableFilter: true,
    // },
    // {
    //   width: 120,
    //   title: "Total Import INR",
    //   field: "Total_Import",
    //   enableSearch: true,
    //   enableFilter: true,
    // },
    // {
    //   width: 120,
    //   title: "DVA",
    //   field: "DVA",
    //   enableSearch: true,
    //   enableFilter: true,
    // },
    // {
    //   width: 120,
    //   title: "Claimed for PLI",
    //   field: "Claimed_for_PLI",
    //   enableSearch: true,
    //   enableFilter: true,
    // },
    // {
    //   width: 120,
    //   title: "Start Date",
    //   field: "Start_Date",
    //   enableSearch: true,
    //   enableFilter: true,
    // },
    // {
    //   width: 120,
    //   title: "Explanation",
    //   field: "Explanation",
    //   enableSearch: true,
    //   enableFilter: true,
    // },
  ]

  // const goNextForm = () => {
  //   var url;
  //   var urlMethod;

  //   // let { userRole, userID } = auth;

  //   if (userRole.includes("GDC")) {
  //     url = "VendorMISProgress";
  //     urlMethod = "PUT";
  //   } else if (userRole.includes("Vendor") || userRole.includes("Vendor-CV")) {
  //     url = "VendorMISProgress";
  //     urlMethod = "POST";
  //   } else {
  //     url = "VendorMISProgress";
  //     urlMethod = "POST";
  //   }
  //   const apiFormatedData = {
  //     VC: Bc.value,
  //   }

  //   var apiFormatedDataJson = JSON.stringify(apiFormatedData);
  //   let headerss = new Headers();
  //   headerss.append('Content-Type', 'application/json');
  //   fetch(`${baseUrl}${url}/`,
  //     {
  //       method: urlMethod,
  //       headers: headerss,
  //       body: apiFormatedDataJson
  //     }).then((response) => response.json())
  //     .then((responseJson) => {
  //       showPopup({
  //         type: MESSAGE_TYPE.SUCCESS,
  //         contextText: MESSAGE_TYPE.SUCCESS,
  //         info: "Data is Succesfully Saved"

  //       });
  //       history.push('/')

  //     })
  //     .catch((error) => {

  //     });

  // };

  const goNextForm = async () => {
    let url
    let urlMethod

    if (userRole.includes('GDC')) {
      url = 'VendorMISProgress'
      urlMethod = 'PUT'
    } else if (userRole.includes('Vendor') || userRole.includes('Vendor-CV')) {
      // url = 'VendorMISProgress'
      url = 'SupplierUnderPLISubmissionstatus'
      urlMethod = 'POST'
    } else {
      url = 'VendorMISProgress'
      urlMethod = 'POST'
    }

    const apiFormatedData = {
      Project_code: projectCode?.value,
      Supplier_code: Bc?.value,
      Vendor_underPLI_submitted_Date: formattedDate
    }

    try {
      const response = await axios({
        method: urlMethod,
        url: `${baseUrl}${url}/`,
        headers: {
          'Content-Type': 'application/json'
        },
        data: apiFormatedData
      })

      showPopup({
        type: MESSAGE_TYPE.SUCCESS,
        contextText: MESSAGE_TYPE.SUCCESS,
        info: 'Data is Succesfully Saved'
      })
      history.push('/')
    } catch (error) {
      let errorMessage = 'An error occurred'

      if (error.response) {
        switch (error.response.status) {
          case 400:
            errorMessage = 'Bad Request'
            break
          case 401:
            errorMessage = 'Unauthorized'
            break
          case 403:
            errorMessage = 'Forbidden'
            break
          case 404:
            errorMessage = 'Not Found'
            break
          case 500:
            errorMessage = 'Internal Server Error'
            break
          default:
            errorMessage = `Error: ${error.response.statusText}`
        }
      } else if (error.request) {
        errorMessage = 'No response received from the server'
      } else {
        errorMessage = `Error: ${error.message}`
      }

      showPopup({
        type: MESSAGE_TYPE.FAILURE,
        contextText: MESSAGE_TYPE.FAILURE,
        info: errorMessage
      })
    }
  }

  const redirectToCreateProjectPage = () => history.push('/')
  //   const renderHTML = () => (
  //     <div className={styles.formGroup}>

  // <Grid
  //         container
  //         spacing={1}

  //         style={{ display: 'flex', justifyContent: "left", marginTop: "5px",marginBottom: "1px" }}
  //         columns={12}
  //       >
  //         <Grid item md={5} lg={5}>
  //           <div className={styles.rowDiv}>
  //             <div className={clsx(styles.lableDiv)} style={{width:"20%"}}>
  //               <label className={styles.label}>Supplier</label>
  //             </div>
  //             <div className={styles.formDiv}>
  //             <CustomSelect
  //               name="bc"
  //               options={bcArray}
  //               isDisabled={checkDisable}
  //               className={clsx(styles.select, styles.sel1)}
  //               value={Bc}
  //               isMulti={false}
  //               // isClearable
  //               onChange={(e) => {
  //                 handleSelect(e, "VC");
  //               }}
  //             />
  //            <div
  //              style={{marginLeft:"5px", paddingRight:"10px",marginTop: "1px",display:"flex"}}

  // >

  //                   <AuthChecker operation="search">
  //              {isAuthorized => (
  //                <Button
  //                  className={clsx(
  //                    styles.actionButton,
  //                    styles.primaryActionButton,
  //                  )}
  //                  style={{marginRight:"12px !important"}}
  //                  variant="primary"
  //                  //   onClick={() => runPreSubmissionChecks() && handleSubmit()}
  //                  onClick={showTable}
  //                   disabled={!isAuthorized}

  //                  data-testid="confirm-action"
  //                >
  //                  Show Data
  //                </Button>
  //              )}

  //            </AuthChecker>
  //            <AuthChecker operation="search">
  //              {isAuthorized => (
  //                <Button
  //                  className={clsx(
  //                    styles.actionButton,
  //                    styles.primaryActionButton,
  //                  )}

  //                  variant="primary"
  //                  //   onClick={() => runPreSubmissionChecks() && handleSubmit()}
  //                  onClick={handleOpen}
  //                   disabled={!isAuthorized}
  //                  data-testid="confirm-action"
  //                  style={{marginLeft:"5px"}}

  //                >
  //                Upload
  //                </Button>
  //              )}

  //            </AuthChecker>

  //       {
  //         loader==false&&

  //           <>
  //         <AuthChecker operation="download">

  //           {isAuthorized => (
  //                <Button
  //                  className={clsx(
  //                    styles.actionButton,
  //                    styles.primaryActionButton,
  //                  )}
  //                  variant="primary"
  //                  //   onClick={() => runPreSubmissionChecks() && handleSubmit()}
  //                  onClick={()=>{

  //                   if(tableArray.length>=1){exportExcel(tableArray);}
  //                  }}
  //                   disabled={!isAuthorized}
  //                  data-testid="confirm-action"
  //                  style={{marginLeft:"5px"}}

  //                >
  //                  download
  //                </Button>
  //              )}

  //            </AuthChecker>

  //        </>
  //     }

  //               </div>
  //             </div>
  //           </div>

  //         </Grid>

  //         </Grid>
  //       {/* <div className={styles.formGroup}>
  //         <div className={styles.formGroupRow}>
  //           <div className={styles.formRow}>
  //             <label className={styles.label}>Vendor Code</label>
  //             <CustomSelect
  //               name="bc"
  //               options={bcArray}
  //               isDisabled={checkDisable}
  //               className={clsx(styles.select, styles.sel1)}
  //               value={Bc}
  //               isMulti={false}
  //               isClearable
  //               onChange={(e) => {
  //                 handleSelect(e, "VC");
  //               }}
  //             />
  //           </div>
  //           <div className={styles.formRow}>
  //             <label className={styles.label}>
  //               <Button
  //                 className={clsx(
  //                   styles.actionButton,
  //                   styles.primaryActionButton
  //                 )}
  //                 variant="primary"
  //                 onClick={showTable}
  //               >
  //                 Download Data
  //               </Button>
  //             </label>

  //           </div>
  //         </div>

  //       </div> */}
  //     </div>
  //   );

  //

  const renderHTML = () => (
    <div className={styles.formGroup}>
      <Grid
        container
        spacing={1}
        style={{ display: 'flex', justifyContent: 'left' }}
        columns={12}
      >
        <Grid
          item
          // sm={12}
          md={3}
          lg={3}
          // style={{ marginTop: '5px', marginBottom: '5px' }}
        >
          <div className={styles.rowDiv} style={{ display: 'flex', alignItems: 'center' }}>
            <div className={clsx(styles.lableDiv)} style={{ width: '51%', display: 'flex', alignItems: 'center' }}>
              <label className={styles.label}>Project Code</label>
            </div>
            <div className={styles.formDiv}>
              <CustomSelect
                name='Project_code'
                options={projectCodeArray}
                // isDisabled={checkDisable}
                className={clsx(styles.select, styles.sel1)}
                // value={projectCode}
                value={selectedProjectCode}
                defaultValue={latestProject}
                isMulti={false}
                isClearable
                onChange={selectedOption => {
                  // handleSelect(e, "VC");
                  setProjectCode(selectedOption)
                  handleDropdownChange(selectedOption)
                  setSelectedProjectCode(selectedOption)
                }}
              />
            </div>
          </div>
        </Grid>
        {/* <Grid item sm={12}  md={5} lg={4} style={{marginTop:"5px", marginBottom:'5px'}}>
        <div className={styles.rowDiv}>
          <div className={clsx(styles.lableDiv)}  style={{width:"40%"}}>
              <label className={styles.label}>Financial Year</label>
            </div>
            <div className={styles.formDiv} >
            <CustomSelect
              name="Fy"
              options={fyArray}
              // isDisabled={checkDisable}
              className={clsx(styles.select, styles.sel1)}
              value={Fy}
              isMulti={false}
              // isClearable
              onChange={(e) => {
                // handleSelect(e, "VC");
                setFY(e.target.value)
              }}
            />
            </div>
            </div>
        </Grid> */}
        {/* <Grid item xs={12} md={5} lg={4} style={{marginTop:"5px", marginBottom:'5px'}}>
        <div className={styles.rowDiv}>
          <div className={clsx(styles.lableDiv)} style={{width:"40%"}}>
              <label className={styles.label}>Quarter</label>
            </div>
            <div className={styles.formDiv}>
            <CustomSelect
              name="bc"
              options={quarterArray}
              // isDisabled={checkDisable}
              className={clsx(styles.select, styles.sel1)}
              value={quarter}
              isMulti={false}
              // isClearable
              onChange={(e) => {
                handleSelect(e, "VC");
              }}
            />
            </div>
            </div>
        </Grid> */}
        {/* <Grid item md={5} lg={4} sm={12} style={{marginTop:"5px", marginBottom:'5px'}}>
        <div className={styles.rowDiv}>
          <div className={clsx(styles.lableDiv)} style={{width:"40%"}}>
              <label className={styles.label}>Plant</label>
            </div>
            <div className={styles.formDiv}>
            <CustomSelect
              name="Plant"
              options={plantArray}
              // isDisabled={checkDisable}
              className={clsx(styles.select, styles.sel1)}
              value={plant}
              isMulti={false}
              // isClearable
              onChange={(e) => {
                handleSelect(e, "VC");
              }}
            />
            </div>
            </div>
        </Grid> */}
        <Grid
          item
          md={3}
          lg={3}
          // sm={12}
          // style={{ marginTop: '5px', marginBottom: '5px' }}
        >
          <div className={styles.rowDiv} style={{ display: 'flex', alignItems: 'center' }}>
            <div className={clsx(styles.lableDiv)} style={{ width: '30%', display: 'flex', alignItems: 'center' }}>
              <label className={styles.label}>Supplier</label>
            </div>
            <div className={styles.formDiv}>
              <CustomSelect
                name='bc'
                options={bcArray}
                isDisabled={checkDisable}
                className={clsx(styles.select, styles.sel1)}
                value={Bc}
                isMulti={false}
                isClearable
                onChange={e => {
                  // handleSelect(e, "VC");
                  setBC(e)
                }}
              />
            </div>
          </div>
        </Grid>
        {/* <Grid item md={2} lg={2} sm={12}>
         */}

        <Grid
          item
          md={5}
          lg={4}
          sm={12}
          // style={{ marginTop: '5px', marginBottom: '5px' }}
        >
          <div
            style={{
              marginLeft: '5px',
              paddingRight: '10px',
              // marginTop: '1px',
              display: 'flex',
              justifyContent: 'left'
            }}
          >
            <AuthChecker operation='search'>
              {isAuthorized => (
                <Button
                  className={clsx(
                    styles.actionButton,
                    styles.primaryActionButton
                  )}
                  style={{ marginRight: '12px !important' }}
                  variant='primary'
                  //   onClick={() => runPreSubmissionChecks() && handleSubmit()}
                  onClick={showTable}
                  disabled={!isAuthorized}
                  data-testid='confirm-action'
                >
                  Show Data
                </Button>
              )}
            </AuthChecker>
            <AuthChecker operation='search'>
              {isAuthorized => (
                <Button
                  className={clsx(
                    styles.actionButton,
                    styles.primaryActionButton
                  )}
                  variant='primary'
                  //   onClick={() => runPreSubmissionChecks() && handleSubmit()}
                  onClick={() => exportExcel(tableArray)}
                  disabled={!isAuthorized}
                  data-testid='confirm-action'
                  style={{ marginLeft: '5px' }}
                >
                  Download
                </Button>
              )}
            </AuthChecker>
            {/* {auth.userRole !== 'Vendor' && (<AuthChecker operation="search">
             {isAuthorized => (
               <Button
                 className={clsx(
                   styles.actionButton,
                   styles.primaryActionButton,
                 )}
                 style={{marginRight:"12px !important",marginLeft:"5px"}}
                 variant="primary"
                 //   onClick={() => runPreSubmissionChecks() && handleSubmit()}
                 onClick={()=>{
                  getAllData()
                 }}
                  disabled={!isAuthorized}
                 
                 data-testid="confirm-action"
               >
                 Show All
               </Button>
             )}
   
           </AuthChecker>)} */}
            {!auth.userRole.includes('Vendor') ? (
              <AuthChecker operation='search'>
                {isAuthorized => (
                  <Button
                    className={clsx(
                      styles.actionButton,
                      styles.primaryActionButton
                    )}
                    style={{
                      marginRight: '12px !important',
                      marginLeft: '5px'
                    }}
                    variant='primary'
                    onClick={() => {
                      getAllData()
                    }}
                    disabled={!isAuthorized}
                    data-testid='confirm-action'
                  >
                    Show All
                  </Button>
                )}
              </AuthChecker>
            ) : null}

            {/* {
        loader==false&&
    
          <>
        <AuthChecker operation="download">
  
          {isAuthorized => (
               <Button
                 className={clsx(
                   styles.actionButton,
                   styles.primaryActionButton,
                 )}
                 variant="primary"
                 //   onClick={() => runPreSubmissionChecks() && handleSubmit()}
                 onClick={()=>{
  
                  if(tableArray.length>=1){exportExcel(tableArray);}
                 }}
                  disabled={!isAuthorized}
                 data-testid="confirm-action"
                 style={{marginLeft:"5px"}}
  
               >
                 download
               </Button>
             )}
   
           </AuthChecker>
       
       </> 
    } */}
          </div>
        </Grid>
      </Grid>
    </div>
  )

  // const downloadFile = () => {

  //   fetch(`${baseUrl}ResultList`).then((response) => {

  //     const success = response.ok;

  //     response
  //       .blob()
  //       .then((blob) => {
  //         // Creating new object of PDF file
  //         const fileURL = window.URL.createObjectURL(blob);
  //         // Setting various property values

  //         let alink = document.createElement("a");
  //         alink.href = fileURL;
  //         alink.download = "Certificate.pdf";
  //         alink.click();

  //       }).then(() => {
  //         if (!success) {
  //           showPopup({
  //             type: MESSAGE_TYPE.SUCCESS,
  //             contextText: MESSAGE_TYPE.SUCCESS,
  //             info: "File is Sucessfully Downloaded",
  //           });
  //         }

  //       })
  //       .catch((error) => {
  //         // catch any unexpected errors
  //         console.log(error);
  //         showPopup({
  //           type: MESSAGE_TYPE.FAILURE,
  //           contextText: MESSAGE_TYPE.FAILURE,
  //           info: "Document download failed.",
  //         });
  //       });
  //   });
  // };
  const downloadFile = () => {
    const headerss = new Headers({
      'Content-Type': 'application/json'
    })
    // const formData = new FormData();
    // formData.append("file", e.target.files[0]);
    // formData.append("Supplier_Code",Bc?.value)
    // console.log(Bc?.value)
    const body = JSON.stringify({
      Supplier_code: Bc?.value,
      Project_code: projectCode?.value
    })
    // fetch(`${baseUrl}SupplierDeclaration_PDF/`,{
    fetch(`${baseUrl}SupplierDeclaration_PDFGen/`, {
      method: 'POST',
      headers: headerss,
      body
    }).then(response => {
      const success = response.ok

      response
        .blob()
        .then(blob => {
          // Creating new object of PDF file
          const fileURL = window.URL.createObjectURL(blob)
          // Setting various property values

          let alink = document.createElement('a')
          alink.href = fileURL
          alink.download = 'Certificate.pdf'
          alink.click()
        })
        .then(() => {
          if (!success) {
            showPopup({
              type: MESSAGE_TYPE.SUCCESS,
              contextText: MESSAGE_TYPE.SUCCESS,
              info: 'File is Sucessfully Downloaded'
            })
          }
        })
        .catch(error => {
          // catch any unexpected errors
          console.log(error)
          showPopup({
            type: MESSAGE_TYPE.FAILURE,
            contextText: MESSAGE_TYPE.FAILURE,
            info: 'Document download failed.'
          })
        })
    })
  }

  const getUrl = code => {
    var msg = code

    return (
      <div>
        <p>Certificate Uploaded Successfully</p>
        <a href={msg} target='_blank' rel='noreferrer'>
          {msg}
        </a>
      </div>
    )
  }
  // const Uploadcertification = () => {
  //   var input = document.createElement("input");
  //   input.type = "file";
  //   input.id = "file";
  //   input.setAttribute("accept", ".pdf");
  //   input.click();

  //   input.onchange = (e) => {
  //     const formData = new FormData();
  //     formData.append("file", e.target.files[0]);
  //     formData.append("Vendor_code", vendCode);
  //     fetch(`${baseUrl}FileUploadView/`, {
  //       method: "POST",
  //       body: formData,
  //     })
  //       .then((response) => response.json())
  //       .then((responseJson) => {
  //         console.log(responseJson);
  //         var certificateurlstampurl = 'https://tml-test-digital-invoice-s3.s3.amazonaws.com/Certificate%20%2838%29_23-12-2022T11%3A02%3A38.pdf?response-content-disposition=attachment&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAQGDNMWVUMVQVJ4YB%2F20221223%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20221223T110240Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=4da98e95872b5a7d4f2b15a2f524ce1d18178ba15b0923a4ca1b8605ad79bd1a';

  //         if (responseJson) {
  //           showPopup({
  //             type: MESSAGE_TYPE.SUCCESS,
  //             //  contextText:getUrl(certificateUrl),
  //             contextText: "success",
  //             info: "Certificate Uploaded Successfully"

  //           });
  //         } else {
  //           showPopup({
  //             type: MESSAGE_TYPE_CERTIFICATION.FAILURE,
  //             contextText: MESSAGE_TYPE_CERTIFICATION.FAILURE,
  //             info: responseJson.url,
  //           });
  //         }

  //         // eslint-disable-next-line radix
  //         // let status1 = parseInt(response.status);
  //         //  console.log(response.status)
  //         // if (status1 == "200") {
  //         //   //   document.removeChild(input);
  //         // //  getTableData();

  //         // }
  //       })

  //       .catch((error) => {

  //         showPopup({

  //           type: MESSAGE_TYPE_CERTIFICATION.FAILURE,
  //           contextText: MESSAGE_TYPE_CERTIFICATION.FAILURE,
  //           //  info: responseJson.url,
  //         });
  //       });
  //   };
  // };
  // const Uploadcertification = () => {
  //   var input = document.createElement("input");
  //   input.type = "file";
  //   input.id = "file";
  //   input.setAttribute("accept", ".pdf");
  //   input.click();

  //   input.onchange = (e) => {
  //     const formData = new FormData();
  //     formData.append("file", e.target.files[0]);
  //     formData.append("Supplier_Code", Bc?.value);
  //     formData.append("Plant_code", plant?.value);
  //     formData.append("Project_code", projectCode?.value);
  //     // fetch(`${baseUrl}SupplierDeclarationPdfUploadToBucket/`, {
  //     fetch(`${baseUrl}SupplierDeclarationPdfUploadToS3Bucket/`, {
  //       method: "POST",
  //       body: formData,
  //     })
  //       .then((response) => response.json())
  //       .then((responseJson) => {
  //         console.log(responseJson);
  //       //  var certificateurlstampurl = 'https://tml-test-digital-invoice-s3.s3.amazonaws.com/Certificate%20%2838%29_23-12-2022T11%3A02%3A38.pdf?response-content-disposition=attachment&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAQGDNMWVUMVQVJ4YB%2F20221223%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20221223T110240Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=4da98e95872b5a7d4f2b15a2f524ce1d18178ba15b0923a4ca1b8605ad79bd1a';

  //         if (responseJson) {
  //           showPopup({
  //             type: MESSAGE_TYPE.SUCCESS,
  //             //  contextText:getUrl(certificateUrl),
  //             contextText:"success",
  //             info:"Certificate Uploaded Successfully"
  //           });
  //         } else {
  //           showPopup({
  //             type: MESSAGE_TYPE_CERTIFICATION.FAILURE,
  //             contextText: MESSAGE_TYPE_CERTIFICATION.FAILURE,
  //              info: responseJson.url,
  //           });
  //         }

  //         // eslint-disable-next-line radix
  //         // let status1 = parseInt(response.status);
  //         //  console.log(response.status)
  //         // if (status1 == "200") {
  //         //   //   document.removeChild(input);
  //         // //  getTableData();

  //         // }
  //       })

  //       .catch((error) => {

  //         showPopup({

  //           type: MESSAGE_TYPE_CERTIFICATION.FAILURE,
  //           contextText:  MESSAGE_TYPE_CERTIFICATION.FAILURE,
  //            //  info: responseJson.url,
  //         });
  //       });
  //   };
  // };

  const Uploadcertification = () => {
    var input = document.createElement('input')
    input.type = 'file'
    input.id = 'file'
    input.setAttribute('accept', '.pdf')
    input.click()

    input.onchange = async e => {
      const formData = new FormData()
      formData.append('file', e.target.files[0])
      formData.append('Supplier_code', Bc?.value)
      formData.append('Project_code', projectCode?.value)

      try {
        const response = await axios.post(
          `${baseUrl}SupplierDeclarationPdfUploadToS3Bucket/`,
          formData,
          {
            headers: { 'Content-Type': 'multipart/form-data' }
          }
        )

        if (response.status === 200 || response.status === 201) {
          showPopup({
            type: MESSAGE_TYPE.SUCCESS,
            contextText: MESSAGE_TYPE.SUCCESS,
            info: 'Certificate Uploaded Successfully!'
          })
        } else {
          showPopup({
            type: MESSAGE_TYPE.FAILURE,
            contextText: MESSAGE_TYPE.FAILURE,
            info: response.data?.url || `Error: ${response.statusText}`
          })
        }
      } catch (error) {
        let errorMessage = 'An error occurred'

        if (error.response) {
          switch (error.response.status) {
            case 400:
              errorMessage = 'Bad Request'
              break
            case 401:
              errorMessage = 'Unauthorized'
              break
            case 404:
              errorMessage = 'Not Found'
              break
            case 500:
              errorMessage = 'Internal Server Error'
              break
            default:
              errorMessage = `Error: ${error.response.statusText}`
          }
        } else if (error.request) {
          errorMessage = 'No response received from the server'
        } else {
          errorMessage = `Error: ${error.message}`
        }

        showPopup({
          type: MESSAGE_TYPE.FAILURE,
          contextText: MESSAGE_TYPE.FAILURE,
          info: `Upload Failed. Reason: ${errorMessage}`
        })
      }
    }
  }

  // const Uploadannexure = () => {
  //   var input = document.createElement("input");
  //   input.type = "file";
  //   input.id = "file";
  //   input.setAttribute("accept", ".pdf");
  //   input.click();
  //   input.onchange = (e) => {
  //     const formData = new FormData();
  //     formData.append("file", e.target.files[0]);
  //     formData.append("Vendor_code", vendCode);
  //     fetch(`${baseUrl}AnnexureFileUploadView/`, {
  //       method: "POST",
  //       body: formData,
  //     })
  //       .then((response) => response.json())
  //       .then((responseJson) => {
  //         console.log(responseJson);
  //         if (responseJson) {
  //           showPopup({
  //             type: MESSAGE_TYPE.SUCCESS,
  //             contextText: "Success",
  //             info: MESSAGE_TYPE_ANNEXURE.SUCCESS,
  //             //   info: responseJson.url,
  //           });
  //         } else {
  //           showPopup({
  //             type: MESSAGE_TYPE_ANNEXURE.FAILURE,
  //             contextText: MESSAGE_TYPE_ANNEXURE.FAILURE,
  //             // info: responseJson.url,
  //           });
  //         }

  //       })

  //       .catch((error) => { });
  //   };
  // };
  // const Uploadannexure = () => {
  //   var input = document.createElement('input')
  //   input.type = 'file'
  //   input.id = 'file'
  //   input.setAttribute('accept', '.pdf')
  //   input.click()
  //   input.onchange = e => {
  //     const formData = new FormData()
  //     formData.append('file', e.target.files[0])
  //     formData.append('supplier_code', Bc?.value)
  //     formData.append('project_code', projectCode?.value)
  //     // fetch(`${baseUrl}SupplierDeclarationPdfAnnexureUploadToBucket/`, {
  //     fetch(`${baseUrl}SupplierDeclarationAnnexureUploadToS3Bucket/`, {
  //       method: 'POST',
  //       body: formData
  //     })
  //       .then(response => {
  //         if (response.status !== 201) {
  //           const errorMessage = `Failed to upload file: ${response.statusText}`
  //           showPopup({
  //             type: MESSAGE_TYPE_ANNEXURE.FAILURE,
  //             contextText: MESSAGE_TYPE_ANNEXURE.FAILURE,
  //             info: MESSAGE_TYPE_ANNEXURE.FAILURE
  //           })
  //           throw new Error(errorMessage)
  //         }
  //         return response.json()
  //       })
  //       .then(responseJson => {
  //         console.log(responseJson)
  //         if (responseJson) {
  //           showPopup({
  //             type: MESSAGE_TYPE.SUCCESS,
  //             contextText: 'Success',
  //             // info: MESSAGE_TYPE_ANNEXURE.SUCCESS,
  //             info: 'Document successfully submitted'
  //             //   info: responseJson.url,
  //           })
  //         }
  //       })

  //       .catch(error => {
  //         console.log('error:', error)
  //       })
  //   }
  // }

  const Uploadannexure = () => {
    var input = document.createElement('input')
    input.type = 'file'
    input.id = 'file'
    input.setAttribute('accept', '.pdf')
    input.click()

    input.onchange = async e => {
      const formData = new FormData()
      formData.append('file', e.target.files[0])
      formData.append('Supplier_code', Bc?.value)
      formData.append('Project_code', projectCode?.value)

      try {
        const response = await axios.post(
          `${baseUrl}SupplierDeclarationAnnexureUploadToS3Bucket/`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }
        )

        if (response.status !== 201) {
          const errorMessage = `Failed to upload file: ${response.statusText}`
          showPopup({
            type: MESSAGE_TYPE_ANNEXURE.FAILURE,
            contextText: MESSAGE_TYPE_ANNEXURE.FAILURE,
            info: MESSAGE_TYPE_ANNEXURE.FAILURE
          })
          throw new Error(errorMessage)
        }

        const responseJson = response.data
        console.log(responseJson)

        if (responseJson) {
          showPopup({
            type: MESSAGE_TYPE.SUCCESS,
            contextText: 'Success',
            info: 'Annexure Submitted Successfully!'
          })
        }
      } catch (error) {
        let errorMessage = 'An error occurred'

        if (error.response) {
          switch (error.response.status) {
            case 400:
              errorMessage = 'Bad Request'
              break
            case 401:
              errorMessage = 'Unauthorized'
              break
            case 404:
              errorMessage = 'Not Found'
              break
            case 500:
              errorMessage = 'Internal Server Error'
              break
            default:
              errorMessage = `Error: ${error.response.statusText}`
          }
        } else if (error.request) {
          errorMessage = 'No response received from the server'
        } else {
          errorMessage = `Error: ${error.message}`
        }
        console.log('error:', errorMessage)
        showPopup({
          type: MESSAGE_TYPE_ANNEXURE.FAILURE,
          contextText: MESSAGE_TYPE_ANNEXURE.FAILURE,
          info: errorMessage
        })
      }
    }
  }

  const secondaryActions = [
    {
      name: 'Upload',
      authOperation: 'upload',
      shouldEnable: selected => true,
      // shouldEnable : (selected,userRole) => auth.userRole.includes('admin','Vendor')  ,
      actionFn: selected => {
        // handleOpen();
        uploadDocument()
      },
      customClass: ''
    },
    {
      name: 'Generate Certificate',
      authOperation: 'submit',
      shouldEnable: selected => true,
      // shouldEnable : (selected,userRole) => auth.userRole.includes('admin','Vendor')  ,
      actionFn: selected => {
        downloadFile()
      },
      customClass: ''
    },
    {
      name: 'Upload Certificate',
      authOperation: 'uploadCertificate',
      shouldEnable: selected => true,
      // shouldEnable : (selected,userRole) => auth.userRole.includes('admin','Vendor'),
      actionFn: selected => {
        Uploadcertification()
      },
      customClass: ''
    },
    {
      name: 'Other Documents',
      authOperation: 'uploadAnnexure',
      shouldEnable: selected => true,
      // shouldEnable : (selected,userRole) => auth.userRole.includes('admin','Vendor')  ,
      actionFn: selected => {
        Uploadannexure()
      },
      customClass: ''
    },
    {
      name: 'Download Certificate',
      // authOperation: "uploadAnnexure",
      authOperation: 'download',
      shouldEnable: selected => true,
      // shouldEnable : (selected,userRole) => auth.userRole.includes('admin','TMLBSL','GDC','Purchase'),
      actionFn: selected => {
        Uploadannexure()
      },
      customClass: ''
    },
    {
      name: 'Download Documents',
      // authOperation: "uploadAnnexure",
      authOperation: 'download',
      shouldEnable: selected => true,
      // shouldEnable: (selected) => auth.userRole.some(role => ['admin', 'TMLBSL', 'GDC', 'Purchase'].includes(role)) ,
      actionFn: selected => {
        Uploadannexure()
      },
      customClass: ''
    }
  ]

  const handleSecondaryActionOnRole = () => {
    if (auth.userRole.includes('admin')) {
      return secondaryActions
    }
    if (auth.userRole.includes('GDC') || auth.userRole.includes('TMLBSL')) {
      return secondaryActions.filter(
        item =>
          item.name === 'Download Documents' ||
          item.name === 'Download Certificate' ||
          item.name === 'Upload'
      )
    }
    if (auth.userRole.includes('Vendor')) {
      return secondaryActions.filter(
        item =>
          item.name !== 'Download Documents' &&
          item.name !== 'Download Certificate'
      )
    }
    return secondaryActions
  }

  return (
    <>
      <div className={styles.container} style={{ boxSizing: 'border-box' }}>
        <div>
          <div
            className={styles.backButtonLastRefreshedDateWrapper}
            style={{ marginTop: '10px' }}
          >
            <BackButton
              action='Back'
              handleClick={redirectToApplicationLandingPage}
              style={{ marginBottom: '0px' }}
            />
            <div style={{ width: '100%' }}>
              <div className={styles.topDiv}>
                <div
                  className={styles.processHeader}
                  style={{ display: 'flex', height: '3em' }}
                >
                  <div style={{ width: '100%' }}>
                    <CustomTab
                      // title='Supplier DVA Declaration for PLI'
                      title='Supplier PLI Declaration'
                      withOutCount
                      isSelected
                    />
                  </div>

                  {/* <StepBar1 enable={5} /> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className={styles.formContainer}>
            <CustomFormGroup body={renderHTML()} />
          </div>
        </div>

        {/* {checkDisable && (  */}
        {flag && (
          <div
            className={styles.tableView}
            style={{ height: '86%', padding: '0 2em' }}
          >
            <Table
              columns={
                !auth.userRole.includes('Vendor')
                  ? [...baseDefaultColumnsGDC]
                  : [...baseDefaultColumns]
              }
              defaultColumns={
                !auth.userRole.includes('Vendor')
                  ? [...baseDefaultColumnsGDC]
                  : [...baseDefaultColumns]
              }
              rowHeight={38}
              rows={tableArray}
              primaryAction={{
                name: 'Submit Data',
                authOperation: 'submit',
                shouldEnable: () => true,
                actionFn: handleOpenFormModal
              }}
              secondaryActions={handleSecondaryActionOnRole()}
              setAsnCount={setCount}
              isDataLoading={false}
              actionButtons
              onRowSelect={e => {}}
              removeRowSelection
            />
          </div>
        )}
      </div>
      {/* <Button
            className={clsx(
              styles.actionButton,
              styles.primaryActionButton,
            )}
            variant="primary"
            // onClick={onDelete}
          >
            Yes
          </Button> */}
      <ResetModalF
        isPopupOpen={isPopupOpenFormModal}
        handleCloseFormModal={handleCloseFormModal}
        goNextForm={goNextForm}
      />

      <ResetModal
        isPopupOpen={isPopupOpen}
        handleClose={handleClose}
        goNext={goNext}
      />
    </>
  )
}
function ResetModalF ({ isPopupOpen, handleCloseFormModal, goNextForm }) {
  return (
    <Dialog
      open={isPopupOpen}
      className={styles.popContainer}
      classes={{
        paper: styles.popupBox
      }}
      data-testid='reset-popup'
    >
      <>
        <DialogTitle>
          <span className={styles.title}>
            <WarningIcon style={{ height: '18px', width: '18px' }} />
            <span style={{ marginLeft: '8px' }} className={styles.txt}>
              Confirm Submit
            </span>
          </span>
        </DialogTitle>
        <DialogContent className={styles.content}>
          <div>
            {/* <p> On confirmation, All existing data will be overwritten by the uploaded data.</p> */}
            <p>On confirmation , data will be submitted</p>

            <p>Are you sure ?</p>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            className={clsx(styles.actionButton, styles.transparentButton)}
            onClick={handleCloseFormModal}
          >
            Cancel
          </Button>
          <Button
            className={clsx(styles.actionButton, styles.primaryActionButton)}
            variant='primary'
            onClick={e => {
              e.preventDefault()
              handleCloseFormModal()
              goNextForm()
            }}
          >
            Yes
          </Button>
        </DialogActions>
      </>
    </Dialog>
  )
}
function ResetModal ({ isPopupOpen, handleClose, goNext }) {
  return (
    <Dialog
      open={isPopupOpen}
      className={styles.popContainer}
      classes={{
        paper: styles.popupBox
      }}
      data-testid='reset-popup'
    >
      <>
        <DialogTitle>
          <span className={styles.title}>
            <WarningIcon style={{ height: '18px', width: '18px' }} />
            <span style={{ marginLeft: '8px' }} className={styles.txt}>
              Confirm file Upload
            </span>
          </span>
        </DialogTitle>
        <DialogContent className={styles.content}>
          <div>
            <p> On confirmation ,Records will be appended.</p>
            <p>Are you sure ?</p>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            className={clsx(styles.actionButton, styles.transparentButton)}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            className={clsx(styles.actionButton, styles.primaryActionButton)}
            variant='primary'
            onClick={e => {
              e.preventDefault()
              handleClose()
              goNext()
            }}
          >
            Yes
          </Button>
        </DialogActions>
      </>
    </Dialog>
  )
}
ResetModalF.propTypes = {
  isPopupOpen: PropTypes.bool.isRequired,
  handleCloseFormModal: PropTypes.func.isRequired,
  goNextForm: PropTypes.func.isRequired
}

ResetModal.propTypes = {
  isPopupOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  goNext: PropTypes.func.isRequired
}

export default withAllowedOperationsProvider(VendarData, RESOURCE_TYPE.PROJECT)

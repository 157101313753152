/* eslint-disable react/self-closing-comp */
/* eslint-disable vars-on-top */
/* eslint-disable eqeqeq */
/* eslint-disable prefer-const */
/* eslint-disable no-var */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-use-before-define */
/* eslint-disable array-callback-return */
/* eslint-disable camelcase */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable spaced-comment */
/* eslint-disable no-unneeded-ternary */

import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'

import {
  // Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  // Form,
  // FormGroup,
  Grid,
  // IconButton,
  // Modal,
  // TextField
} from '@material-ui/core'
// import macaddress from 'macaddress';
// import getMAC, { isMAC } from 'getmac'
import { makeStyles } from '@material-ui/core/styles'
import WarningIcon from '@mui/icons-material/Warning'
import { Checkbox } from '@mui/material'
// import FormLabel from '@mui/material/FormLabel'
import * as FileSaver from 'file-saver'
import XLSX from 'sheetjs-style'
// for print user in consol
// import jwt_decode from 'jwt-decode'
import { useSelector } from 'react-redux'
// for print user in consol
// import AWS from 'aws-sdk'
// import axios from 'axios'

import clsx from 'clsx'
// import $ from 'jquery';
import moment from 'moment'
import { useHistory } from 'react-router-dom'
import {
  CustomFormGroup,
  CustomSelect,
  // ValidatingTextField
} from '../../components/FormComponents'
import Table from '../../components/Table/Table'
import styles from './AnnualFinancialStatement.module.css'

// import { API } from '../../apis/api'
// import { buildErrorMessage } from '../../apis/calls'
import {
  AuthChecker, Button,
  CustomTab
} from '../../atomicComponents'
import BackButton from '../../components/BackButton/BackButton'
import {
  // API_RESOURCE_URLS,
  // DATE_FORMAT,
  // DISPLAY_MESSAGES,
  MESSAGE_TYPE,
  // MESSAGE_TYPE_ANNEXURE,
  MESSAGE_TYPE_CERTIFICATION,
  RESOURCE_TYPE,
  Regex,
  // USER_OPERATIONS
} from '../../constants'
import { withAllowedOperationsProvider } from '../../hocs'
import { usePopupManager } from '../../providers/PopupManager/PopupManager'

// import StepBar1 from '../../components/StepBar1/StepBar'
// import SupplierImportDetailsManualForm from './SupplierImportDetailsManualForm'
import AFSForm from './AFSForm'
// import apiService from '../../service';
// import {
//   CustomFormGroup,
//   CustomSelect,
//   ValidatingTextField
// } from '../../components/FormComponents'

const AnnualFinancialStatement = () => {
  const history = useHistory()
  const { showPopup } = usePopupManager()
  // const baseUrl='http://127.0.0.1:8000/file/'
  const baseUrl = useSelector(state => state.counter.baseUrl) // useSelector
  // console.log(baseUrl);
  const auth = useSelector(state => state.auth) // useSelector
  const { userRole, userID, email, userName, preferredUsername } = auth
  // const user_name = auth?.name
  // const auth1 = useSelector(state => state) // useSelector
  console.log('user_name', userName)

  // const [dynamicColumns, setDynamicColumns] = useState([])
  // const [dynamicColumns1, setDynamicColumns1] = useState([])
  const [plant, setPlant] = useState(null)
  const [flag, show] = useState(false)
  const [checkDisable, setDisable] = useState(true)
  const [Bc, setBC] = useState(null)
  const [bcArray, setBcArray] = useState([])
  // const [plantArray, setPlantArray] = useState([])
  const [tableArray, setTableArray] = useState([])
  const [asnCount, setAsnCount] = useState(0)
  const [loading, setLoader] = useState(false)
  const [loader, setLoading] = useState(true)
  console.log('loader', loader);
  const [editableByUser, setEditableByUser] = useState(false)
  // console.log('manual IEC Yes: ', tableArray[0]?.Test4 && JSON.parse(tableArray[0]?.Test4))
  // console.log('IEC Yes: ', JSON.parse((tableArray[0]?.Test4)))
  const [manualFormData, setManualFormData] = useState({
    //   Vendor_Code: Bc?.value,
    //   NAME1: tableArray[0]?.NAME1,
    //   PAN: tableArray[0]?.PAN,
    //   Tier: tableArray[0]?.Tier,
    //   GSTIN: tableArray[0]?.GSTIN,
    IEC_Code: tableArray[0]?.IEC_Code,
    // iecChecked:tableArray[0]?.Test4,
    // iecChecked: tableArray[0]?.Test4 && JSON.parse(tableArray[0]?.Test4),
    SA_Name: tableArray[0]?.SA_Name,
    SA_tenure: tableArray[0]?.Test11,
    Revenue_to_PPE_ratio: tableArray[0]?.Revenue_to_PPE_ratio,
    Revenue_from_Operations_in_last_FY_Rs_Crore:
      tableArray[0]?.Revenue_from_Operations_in_last_FY_Rs_Crore,

    Total_Import_of_Goods_in_last_FY_Rs_Crore:
      tableArray[0]?.Total_Import_of_Goods_in_last_FY_Rs_Crore,
    Total_Import_of_Services_in_last_FY_Rs_Crore:
      tableArray[0]?.Total_Import_of_Services_in_last_FY_Rs_Crore,
    // Total_Imports_in_last_FY_Rs_Crore:
    //   tableArray[0]?.Total_Imports_in_last_FY_Rs_Crore,

    Estimated_Import_of_Goods_attributable_to_Applicant_Rs_Crore:
      tableArray[0]?.Estimated_Import_of_Goods_attributable_to_Applicant_Rs_Crore,
    Estimated_Import_of_Services_attributable_to_Applicant_Rs_Crore:
      tableArray[0]
        ?.Estimated_Import_of_Services_attributable_to_Applicant_Rs_Crore,
    // Estimated_Import_attributable_to_Applicant_Rs_Crore:
    //   tableArray[0]?.Estimated_Import_attributable_to_Applicant_Rs_Crore,

    PPA_with_Tier_I_submitted_to_TA:
      tableArray[0]?.PPA_with_Tier_I_submitted_to_TA,
    Copies_of_AFS_of_suppliers_in_last_FY_submitted:
      tableArray[0]?.Copies_of_AFS_of_suppliers_in_last_FY_submitted,
    PPE_at_the_start_of_last_FY: tableArray[0]?.PPE_at_the_start_of_last_FY,
    PPE_At_The_Close_of_last_FY: tableArray[0]?.PPE_At_The_Close_of_last_FY,
    // Avg_PPE_for_last_FY: tableArray[0]?.Avg_PPE_for_last_FY
    Date_of_PPA: tableArray[0]?.Date_of_PPA,
    MPFD_for_major_suppliers_submitted: tableArray[0]?.MPFD_for_major_suppliers_submitted,
    Afs_Document_mandatory: tableArray[0]?.Afs_Document_mandatory,
    TMLBSL_Validation_Email_comment: tableArray[0]?.TMLBSL_Validation_Email_comment,
    Purchase_Validation_Email_comment: tableArray[0]?.Purchase_Validation_Email_comment,
    PMT_Validation_Email_comment: tableArray[0]?.PMT_Validation_Email_comment,
    Admin_Validation_comment: tableArray[0]?.Admin_Validation_comment,
    // checkIEC: tableArray[0]?.Test4 && JSON.parse(tableArray[0]?.Test4)
    // iecChecked: tableArray[0]?.Test4 && JSON.parse(tableArray[0]?.Test4),
    // iecChecked: tableArray[0]?.Test4 ? JSON.parse(tableArray[0]?.Test4) : false,
    iecChecked: (tableArray[0]?.Test4 !== null || tableArray[0]?.Test4 !== 'false' || tableArray[0]?.Test4 !== '') ? false : true,
    // iecChecked: tableArray[0]?.Test4 === 'true'
  })
  // console.log('manual Form: ',tableArray[0]?.Test4 && JSON.parse(tableArray[0]?.Test4))
  
  
  const [clientIP, setClientIP] = useState('')
  const [relatedVendorCode, setRelatedVendorCode] = useState('')
  const [relatedGstin, setRelatedGstin] = useState('')
  const [buttonStatusData, setButtonStatusData] = useState()
  
  const [acceptedFiles1, setAcceptedFiles1] = useState([])
  console.log('acceptedFiles1', acceptedFiles1)
  // console.log('');
  
  // const [confirmSubmission, setConfirmSubmission] = useState({
    const [financialDetails, setFinancialDetails] = useState(false)
    // const [auditedFinancialStatement, setAuditedFinancialStatement] =
  //   useState(false)
  // })
  
  const handleAFSDetailsCheckbox = (event, setState) => {
    setState(event.target.checked)
  }
  
  // const handleAFSStatementCheckbox = (event) => {
    //   setConfirmSubmission({
      //     auditedFinancialStatement: event.target.checked
      //   })
      // }
      
      // console.log('confirmSubmission', confirmSubmission);
      
      const [vendCode, setvendCode] = useState()
      // const [datesData, setDates] = useState({
        //   endDate: '',
        //   startDate: ''
        // })
        
        //
        const [isPopupOpenFormModal, setPopupOpenFormModal] = useState(false)
  const handleOpenFormModal = () => {
    setPopupOpenFormModal(true)
  }
  const handleCloseFormModal = () => setPopupOpenFormModal(false)
  
  //
  
  const ROW_HEIGHT = 38
  
  const [isPopupOpen, setIsPopupOpen] = useState(false)
  const handleOpen = () => setIsPopupOpen(true)
  const handleClose = () => setIsPopupOpen(false)
  const goNext = () => {
    uploadFileData()
  }
  
  //

  const [isOpenManualForm, setIsOpenManualForm] = useState(false)
  const handleOpenManualForm = () => setIsOpenManualForm(true)
  const handleCloseManualForm = () => setIsOpenManualForm(false)
  const [isManualFormConfirmationBox, setIsManualFormConfirmationBox] =
    useState(false)
  const [iecChecked, setIecChecked] = useState(false)
  
  // useState(() => {
  //   const value = tableArray[0]?.Test4
  //   return value ? JSON.parse(value) : false
  // })  
  
  // useState(tableArray[0]?.Test4 && JSON.parse(tableArray[0]?.Test4))

  const [financialYear, setFinancialYear] = useState({
    value: '2024-25',
    label: '2024-25'
  })
  // console.log('manual Form: ', typeof(iecChecked))

  const handleOpenManualFormConfirmation = () => {
    const estimatedImportGoods = parseFloat(manualFormData?.Estimated_Import_of_Goods_attributable_to_Applicant_Rs_Crore)
    const estimatedImportService = parseFloat(manualFormData?.Estimated_Import_of_Services_attributable_to_Applicant_Rs_Crore)
    const totalImportGoods = parseFloat(manualFormData?.Total_Import_of_Goods_in_last_FY_Rs_Crore)
    const totalImportService = parseFloat(manualFormData?.Total_Import_of_Services_in_last_FY_Rs_Crore)
    const ppeClose = manualFormData?.PPE_At_The_Close_of_last_FY
    const ppeStart = manualFormData?.PPE_at_the_start_of_last_FY
    const iecCode = manualFormData?.IEC_Code
    // const Test4 = manualFormData?.IEC_Code
    const saName = manualFormData?.SA_Name
    const revenueFromOperation = manualFormData?.Revenue_from_Operations_in_last_FY_Rs_Crore
    const afsMandateFlag = tableArray[0]?.Afs_Document_mandatory

    console.log('types: ', typeof(estimatedImportGoods))

    if (
      ((isAdminUser || isVendorUser) && ((iecChecked && !iecCode))) || 
      // (!saName) ||
      (estimatedImportGoods === null || estimatedImportGoods === '') ||
      (estimatedImportService === null || estimatedImportService === '') ||
      (ppeClose === null || ppeClose === '') ||
      (ppeStart === null || ppeStart === '') ||
      (totalImportGoods === null || totalImportGoods === '') ||
      (totalImportService === null || totalImportService === '')
    ) {
      handlepopup('Please fill the required fields!')
    }
    else if ((isAdminUser || isVendorUser) && (iecChecked && !iecCode)) {
      handlepopup('Please fill IEC Code!')
    } 
    else if ((totalImportInLastFY === null || '') || 
              (totalEstimatedImportAttributableApplicant === null || '') ||
              (avgParsedPPE === null || '')) {
      handlepopup('Total/Average field cannot be null!')
    } else if (estimatedImportGoods > totalImportGoods) {
      handlepopup('Estimated Import of Goods value cannot be greater than Total Import of Goods!')
    } else if (estimatedImportService > totalImportService) {
      handlepopup('Estimated Import of Services value cannot be greater than Total Import of Services!')
    }
    else if (!revenueFromOperation || revenueFromOperation == 0) {
      handlepopup('Revenue from Operations in last FY cannot be zero or null!')
    } else if ((isAdminUser || isVendorUser) && (iecChecked && iecCode?.length > 10)) {
      handlepopup('IEC Code Upto 10 characters!')
    }
    else if (((isAdminUser || isVendorUser)) && (afsMandateFlag ? (acceptedFiles1?.length === 0 || acceptedFiles1 === null) : '')) {
      handlepopup('Please add AFS attachment!')
    }
    else {
      setIsManualFormConfirmationBox(true)
    }
  }

  const handleCloseManualFormConfirmation = () => {
    setIsManualFormConfirmationBox(false)
    setFinancialDetails(false)
  }


  // console.log('auth in AFS', auth);
  const redirectToApplicationLandingPage = () => {
    history.push('/')
  }

  const setCount = count => {
    setAsnCount(count)
  }

  const currentDate = new Date()
  const lastYear = new Date(currentDate)
  lastYear.setFullYear(currentDate.getFullYear() - 1)

  const lastYearValue = lastYear.getFullYear()
  const isAdminUser = userRole?.includes('admin')
  const isGDCUser = userRole?.includes('TMLBSL')
  const isPurchaseUser = userRole?.includes('Purchase')
  const isVendorUser = userRole?.includes('Vendor')
  const isPMTUser = userRole?.includes('PMT')

  useEffect(() => {
    if (userID && (isAdminUser || isGDCUser || isPurchaseUser)) {
      getTableData1()
    }
    if (isVendorUser) {
      setLoading(false)
      if (Bc?.value) {
        getTableData1(Bc?.value)
      }
    }

    if (isPurchaseUser) {
      getSupplierdataCV() 
    } 
    else {
      getData()
    }

    if ((userRole?.includes('TMLBSL')) || userRole.includes('Purchase')) {
      setEditableByUser(true)
    }
    // setIecChecked(tableArray[0]?.Test4 && JSON.parse(tableArray[0]?.Test4))
  }, [flag])

  console.log('iecChecked up: ', iecChecked)

  // const isAdminUser = userRole?.includes('admin')
  // const isGDCUser = userRole?.includes('TMLBSL')
  // const isPurchaseUser = userRole?.includes('Purchase')
  // const isVendorUser = userRole?.includes('Vendor')
  // const isPMTUser = userRole?.includes('PMT')

  function getFinancialYear() {
    const today = new Date();
    const currentYear = today.getFullYear();
    const currentMonth = today.getMonth() + 1; // Months are zero-based in JavaScript
  
    let startYear 
    let endYear
  
    if (currentMonth >= 4) {
      // If the current month is April or later, the financial year started this year
      startYear = currentYear;
      endYear = currentYear + 1;
    } else {
      // If the current month is before April, the financial year started last year
      startYear = currentYear - 1;
      endYear = currentYear;
    }
  
    // Format the financial year as "YYYY-YY"
    const financialYearFormatted = `${startYear}-${String(endYear).slice(-2)}`;
    return financialYearFormatted;
  }
  
  console.log('getFinancialYear', getFinancialYear());
  

  const downloadAfsCertificate = () => {
    const formData = new FormData()
    formData.append('Vendor_code', Bc.value)

    fetch(`${baseUrl}DownloadSupplierAfsFile/`, {
      method: 'POST',
      body: formData
    })
      .then(async response => {
        const res = await response.json()
        const aws_url = res?.url
        const link = document.createElement('a')
        link.href = aws_url
        document.body.appendChild(link)
        link.dispatchEvent(
          new MouseEvent('click', {
            bubbles: true,
            cancelable: true,
            view: window
          })
        )
        document.body.removeChild(link)
      })
      .catch(error => {
        console.log('Error while calling DownloadSupplierAfsFile API: ', error)
      })
  }

  const Uploadcertification = () => {
    var input = document.createElement('input')
    input.type = 'file'
    input.id = 'file'
    input.setAttribute('accept', '.pdf')
    input.click()

    input.onchange = e => {
      const formData = new FormData()
      formData.append('file', e.target.files[0])
      formData.append('Vendor_code', vendCode)
      fetch(`${baseUrl}Annual_financial_statements/`, {
        method: 'POST',
        body: formData
      })
        .then(response => response.json())
        .then(responseJson => {
          console.log(responseJson)
          //  var certificateurlstampurl = 'https://tml-test-digital-invoice-s3.s3.amazonaws.com/Certificate%20%2838%29_23-12-2022T11%3A02%3A38.pdf?response-content-disposition=attachment&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAQGDNMWVUMVQVJ4YB%2F20221223%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20221223T110240Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=4da98e95872b5a7d4f2b15a2f524ce1d18178ba15b0923a4ca1b8605ad79bd1a';

          if (responseJson) {
            showPopup({
              type: MESSAGE_TYPE.SUCCESS,
              //  contextText:getUrl(certificateUrl),
              contextText: 'success',
              info: 'AFS Uploaded Successfully'
            })
          } else {
            showPopup({
              type: MESSAGE_TYPE_CERTIFICATION.FAILURE,
              contextText: MESSAGE_TYPE_CERTIFICATION.FAILURE,
              info: responseJson.url
            })
          }

          // eslint-disable-next-line radix
          // let status1 = parseInt(response.status);
          //  console.log(response.status)
          // if (status1 == "200") {
          //   //   document.removeChild(input);
          // //  getTableData();

          // }
        })

        .catch(error => {
          showPopup({
            type: MESSAGE_TYPE_CERTIFICATION.FAILURE,
            contextText: MESSAGE_TYPE_CERTIFICATION.FAILURE
            //  info: responseJson.url,
          })
        })
    }
  }

  const useStyles = makeStyles(theme => ({
    customDialog: {
      width: '50%',
      maxWidth: 'none'
    }
  }))

  const handleSelect = (selectedOption, name) => {
    setvendCode(selectedOption)
    if (name == 'VC') {
      show(false)
      setBC(selectedOption)
      // getPlantData(selectedOption)
    } else {
      setPlant(selectedOption)
    }

    if (name == 'FY') {
      setFinancialYear(selectedOption)
      // console.log('inside FY select')
      // getTableData1()
    }
  }

  // console.log(
  //   'Copies_of_AFS_of_suppliers_in_last_FY_submitted',
  //   manualFormData?.Copies_of_AFS_of_suppliers_in_last_FY_submitted
  // )

  const getTableData = () => {
    // console.log('inside getTableData FY', financialYear?.value)
    // isFYSelected = financialYear.value ? true : false
    // if(userRole.includes('admin') && !financialYear?.value){
    //   alert('Please select Financial Year.')
    // }
    const apiFormatedData = {
      VC: Bc.value,
      Financial_year: financialYear?.value
    }
    // console.log('Vendor user Bc inside getTableData', Bc?.value)
    var apiFormatedDataJson = JSON.stringify(apiFormatedData)
    let headerss = new Headers()
    headerss.append('Content-Type', 'application/json')
    fetch(`${baseUrl}AfsSupplierData1/`, {
      method: 'POST',
      headers: headerss,
      body: apiFormatedDataJson
    })
      .then(response => response.json())
      .then(responseJson => {
        // console.log('responseJson 1: ', responseJson)
        // if (!responseJson || responseJson?.specific_vendor_data.length === 0 || responseJson?.related_vendors_data.length === 0) {
        if (
          responseJson?.status ===
          'An error occurred while processing the request'
        ) {
          show(false)
          showPopup({
            type: MESSAGE_TYPE.FAILURE,
            contextText: MESSAGE_TYPE.FAILURE,
            info: 'Data not found!'
          })
          // }  else if(responseJson?.specific_vendor_data.length !== 0 || responseJson?.related_vendors_data.length !== 0) {
          // }  else if(responseJson?.status === "successfully show and downloaded data in excel") {
          } else {
        // } else if (responseJson) {
          const tableData = responseJson?.specific_vendor_data
          // console.log('api tableData', tableData)
          setTableArray(tableData)

          setLoading(false)
          show(true)
          //  exportExcel(excelData);
        }
      })
      .catch(error => { })
  }


  // const getTableDataAll = () => {
  //   const apiFormatedData = {
  //     VC: Bc.value
  //   }
  //   var apiFormatedDataJson = JSON.stringify(apiFormatedData)
  //   let headerss = new Headers()
  //   headerss.append('Content-Type', 'application/json')
  //   // fetch(`${baseUrl}Supplier_data_details_CV_for_TierI/`, {
  //   fetch(`${baseUrl}Supplier_data/`, {
  //     method: 'GET',
  //     headers: headerss,
  //     body: apiFormatedDataJson
  //   })
  //     .then(response => response.json())
  //     .then(responseJson => {
  //       // console.log('responseJson in supplier list', responseJson)
  //       const tableData = responseJson

  //       setTableArray(tableData)
  //       const excelData = tableData.map(
  //         (
  //           {
  //             Vendor_Code,
  //             // Plant_code,
  //             NAME1,
  //             // Tier,
  //             PAN,
  //             GSTIN,
  //             IEC_Code,
  //             SA_Name,
  //             Revenue_from_Operations_in_last_FY_Rs_Crore,
  //             Total_Import_of_Goods_in_last_FY_Rs_Crore,
  //             Total_Import_of_Services_in_last_FY_Rs_Crore,
  //             Total_Imports_in_last_FY_Rs_Crore,
  //             Estimated_Import_of_Goods_attributable_to_Applicant_Rs_Crore,
  //             Estimated_Import_of_Services_attributable_to_Applicant_Rs_Crore,
  //             Estimated_Import_attributable_to_Applicant_Rs_Crore,
  //             PPE_at_the_start_of_last_FY,
  //             PPE_At_The_Close_of_last_FY,
  //             Avg_PPE_for_last_FY,
  //             Revenue_to_PPE_ratio,
  //             Copies_of_AFS_of_suppliers_in_last_FY_submitted,
  //             PPA_with_Tier_I_submitted_to_TA
  //           },
  //           id
  //         ) => {
  //           let obj = {
  //             id,
  //             Vendor_Code,
  //             // Plant_code,
  //             Vendor_Name: NAME1,
  //             // Tier,
  //             PAN,
  //             GSTIN,
  //             IEC_Code,
  //             SA_Name,
  //             Revenue_from_Operations_in_last_FY_Rs_Crore,
  //             Total_Import_of_Goods_in_last_FY_Rs_Crore,
  //             Total_Import_of_Services_in_last_FY_Rs_Crore,
  //             Total_Imports_in_last_FY_Rs_Crore,
  //             Estimated_Import_of_Goods_attributable_to_Applicant_Rs_Crore,
  //             Estimated_Import_of_Services_attributable_to_Applicant_Rs_Crore,
  //             Estimated_Import_attributable_to_Applicant_Rs_Crore,
  //             PPE_at_the_start_of_last_FY,
  //             PPE_At_The_Close_of_last_FY,
  //             Avg_PPE_for_last_FY,
  //             Revenue_to_PPE_ratio,
  //             Copies_of_AFS_of_suppliers_in_last_FY_submitted,
  //             //   Copies_of_AFS_of_suppliers_in_last_FY_submitted ? 'Yes' : 'No',
  //             PPA_with_Tier_I_submitted_to_TA
  //             //   ? 'Yes'
  //             //   : 'No'
  //           }
  //           return obj
  //         }
  //       )
  //       setTableArray(excelData)

  //       setLoading(false)
  //       //  exportExcel(excelData);
  //     })
  //     .catch(error => {})
  // }
  const getTableData1 = value => {
    // console.log('Vendor user Bc inside getTableData1 value', value)
    if (value && financialYear?.value) {
      const apiFormatedData = {
        VC: Bc?.value,
        Financial_year: financialYear?.value
      }
      // const apiFormatedData = {
      //   VC: 'P83520',
      //   Financial_year: 2023
      // }
      // console.log('inside getTableData1')
      var apiFormatedDataJson = JSON.stringify(apiFormatedData)
      let headerss = new Headers()
      headerss.append('Content-Type', 'application/json')
      fetch(`${baseUrl}AfsSupplierData1/`, {
        method: 'POST',
        headers: headerss,
        body: apiFormatedDataJson
      })
        .then(response => response.json())
        .then(responseJson => {
          // console.log('responseJson 2: ', responseJson)
          // if (!responseJson || responseJson?.specific_vendor_data.length === 0 || responseJson?.related_vendors_data.length === 0) {
          if (
            responseJson?.status ===
            'An error occurred while processing the request'
          ) {
            // console.log('Inside then block with error', responseJson)
            showPopup({
              type: MESSAGE_TYPE.FAILURE,
              contextText: MESSAGE_TYPE.FAILURE,
              info: `Data not found for FY ${financialYear?.value}!`
            })
            // show(false)
            // setTableArray([])
            // } else if(responseJson?.specific_vendor_data && responseJson?.related_vendors_data) {
            // }  else if(responseJson?.status === "successfully show and downloaded data in excel") {
            // } else if (responseJson) {
          } else {
            // console.log('responseJson in getTableData1', responseJson);

            const tableData = responseJson?.specific_vendor_data
            // console.log('tableData in getTableData1', tableData)
            // console.log('tableData Specific', tableData)
            setRelatedVendorCode(responseJson?.related_vendors_data?.Vendor_Code)
            // console.log('responseJson?.related_vendors_data?.Vendor_Code', responseJson?.related_vendors_data?.Vendor_Code);
            setRelatedGstin(responseJson?.related_vendors_data?.GSTIN)
            setTableArray(tableData)
            // console.log('tableData[0]?.Estimated_Import_of_Services_attributable_to_Applicant_Rs_Crore: ', tableData[0]?.Estimated_Import_of_Services_attributable_to_Applicant_Rs_Crore);
            setManualFormData({
              IEC_Code: tableData[0]?.IEC_Code === null ? '' : tableData[0]?.IEC_Code,
              SA_Name: tableData[0]?.SA_Name === null ? '' : tableData[0]?.SA_Name,
              SA_tenure: tableData[0]?.Test11 === null ? '' : tableData[0]?.Test11,
              Revenue_to_PPE_ratio: tableData[0]?.Revenue_to_PPE_ratio === null ? '' : tableData[0]?.Revenue_to_PPE_ratio,
              Revenue_from_Operations_in_last_FY_Rs_Crore:
                tableData[0]?.Revenue_from_Operations_in_last_FY_Rs_Crore === null ? '' : tableData[0]?.Revenue_from_Operations_in_last_FY_Rs_Crore,
              Total_Import_of_Goods_in_last_FY_Rs_Crore:
                tableData[0]?.Total_Import_of_Goods_in_last_FY_Rs_Crore === null ? '' : tableData[0]?.Total_Import_of_Goods_in_last_FY_Rs_Crore,
              Total_Import_of_Services_in_last_FY_Rs_Crore:
                tableData[0]?.Total_Import_of_Services_in_last_FY_Rs_Crore === null ? '' : tableData[0]?.Total_Import_of_Services_in_last_FY_Rs_Crore,
              Total_Imports_in_last_FY_Rs_Crore: tableData[0]?.Total_Imports_in_last_FY_Rs_Crore,

              Estimated_Import_of_Goods_attributable_to_Applicant_Rs_Crore:
                tableData[0]
                  ?.Estimated_Import_of_Goods_attributable_to_Applicant_Rs_Crore === null ? '' : tableData[0]?.Estimated_Import_of_Goods_attributable_to_Applicant_Rs_Crore,
              
              Estimated_Import_of_Services_attributable_to_Applicant_Rs_Crore:
                tableData[0]
                  ?.Estimated_Import_of_Services_attributable_to_Applicant_Rs_Crore === null ? '' : tableData[0]?.Estimated_Import_of_Services_attributable_to_Applicant_Rs_Crore,
              // PPA_with_Tier_I_submitted_to_TA:
              //   tableData[0]?.PPA_with_Tier_I_submitted_to_TA,
              Copies_of_AFS_of_suppliers_in_last_FY_submitted: {
                value:
                  tableData[0]
                    ?.Copies_of_AFS_of_suppliers_in_last_FY_submitted === true
                    ? 'Yes'
                    : 'No',
                label:
                  tableData[0]
                    ?.Copies_of_AFS_of_suppliers_in_last_FY_submitted === true
                    ? 'Yes'
                    : 'No'
              },
              PPE_at_the_start_of_last_FY:
                tableData[0]?.PPE_at_the_start_of_last_FY === null ? '' : tableData[0]?.PPE_at_the_start_of_last_FY,
              PPE_At_The_Close_of_last_FY:
                tableData[0]?.PPE_At_The_Close_of_last_FY === null ? '' : tableData[0]?.PPE_At_The_Close_of_last_FY,
              PPA_with_Tier_I_submitted_to_TA: { 
                value: tableData[0]?.PPA_with_Tier_I_submitted_to_TA === true ? 'Yes' : 'No',
                label: tableData[0]?.PPA_with_Tier_I_submitted_to_TA === true ? 'Yes' : 'No'
              },
              MPFD_for_major_suppliers_submitted: {
                value: tableData[0]?.MPFD_for_major_suppliers_submitted === true ? 'Yes' : 'No',
                label: tableData[0]?.MPFD_for_major_suppliers_submitted === true ? 'Yes' : 'No'
              },
              Date_of_PPA: tableData[0]?.Date_of_PPA === null ? '' : tableData[0]?.Date_of_PPA,
              TMLBSL_Validation_Email_comment: tableData[0]?.TMLBSL_Validation_Email_comment === null ? '' : tableData[0]?.TMLBSL_Validation_Email_comment,
              Purchase_Validation_Email_comment: tableData[0]?.Purchase_Validation_Email_comment === null ? '' : tableData[0]?.Purchase_Validation_Email_comment,
              PMT_Validation_Email_comment: tableData[0]?.PMT_Validation_Email_comment === null ? '' : tableData[0]?.PMT_Validation_Email_comment,
              Admin_Validation_comment: tableData[0]?.Admin_Validation_comment === null || tableData[0]?.Admin_Validation_comment === undefined ? '' : tableData[0]?.Admin_Validation_comment,
              // iecChecked: tableData[0]?.Test4 === null || tableData[0]?.Test4 === undefined ? null : JSON.parse(tableData[0]?.Test4)
              // checkIEC: tableData[0]?.Test4 === null || tableData[0]?.Test4 === undefined ? null : JSON.parse(tableData[0]?.Test4)
              // iecChecked :  tableData[0]?.Test4 === null || tableData[0]?.Test4 === 'false' || tableData[0]?.Test4
              // 
              iecChecked: tableData[0]?.Test4 !== null && tableData[0]?.Test4 !== 'false' && tableData[0]?.Test4 !== ''
                  // iecChecked :tableData[0]?.Test4 === 'true'
            })
            //  setIecChecked(tableArray[0]?.Test4 && JSON.parse(tableArray[0]?.Test4))
            setIecChecked(iecChecked);
            setAcceptedFiles1(null)
            setLoading(false)
            getStatusDataForButtonAPIView()
            show(true)
          }
        })
        .catch(error => {})
    }
  }


  const getData = () => {
    let headerss = new Headers()
    // let { userRole, userID } = auth
    // console.log('Bc? getData', Bc?.value)
    console.log('userRole in getData', userRole)
    headerss.append('Content-Type', 'application/json')
    // if(userRole.includes('Vendor')){
    //   console.log('inside vendor if');
    //   setBC(userID)
    // }
    // fetch(`${baseUrl}Supplier_data_details_CV_for_TierI/`, {
    fetch(`${baseUrl}Supplier_data/`, {
      method: 'GET',
      headers: headerss
    })
      .then(response => response.json())
      .then(responseJson => {
        // console.log('responseJson', responseJson)
        const ListArray = responseJson.map(({ Vendor_Code }) => ({
          value: Vendor_Code,
          label: Vendor_Code
        }))
        setBcArray(ListArray)
        // console.log('ListArray in response', ListArray)
        // console.log('userRole in response', userRole)
        // console.log('userRole userID in response', userID)
        // console.log('userRole auth', auth);

        if (
          userRole.includes('Vendor-CV') ||
          userRole.includes('Vendor-PV') ||
          userRole.includes('Vendor')
        ) {
          // console.log('inside userRole if', userRole)

          const singleVendor = ListArray.filter(
            element => element.value == userID
          )
          // console.log('singleVEndor', singleVendor)
          if (singleVendor.length >= 1) {
            // console.log('singleVendor inside null check', singleVendor)
            setBC(singleVendor[0])
            // console.log('Bc effect Bc', Bc)
            setDisable(true)
            show(true) // table show
            // console.log('singleVendor', singleVendor[0].value)
            // console.log('Bc before getTableData1 API call', Bc)
            // getTableData1(singleVendor[0].value)
          }
          // setBC(singleVendor[0].value)
        } else {
          setDisable(false)
        }
        // console.log('Bc effect after', Bc)
      })
      .catch(error => {})
  }

  const getSupplierdataCV = () => {
    let headerss = new Headers()
    headerss.append('Content-Type', 'application/json')
    const body = { BuyerName: preferredUsername }
    const formattedBody = JSON.stringify(body)
    fetch(`${baseUrl}Supplier_data_CV/`, {
      method: 'POST',
      headers: headerss,
      body: formattedBody
    })
      .then(response => response.json())
      .then(responseJson => {
        const ListArray = responseJson.map(({ Vendor_Code }) => ({
          value: Vendor_Code,
          label: Vendor_Code
        }))
        setBcArray(ListArray)
        if (
          userRole.includes('Vendor-CV') ||
          userRole.includes('Vendor-PV') ||
          userRole.includes('Vendor')
        ) {
          const singleVendor = ListArray.filter(
            element => element.value == userID
          )
          if (singleVendor.length >= 1) {
            setBC(singleVendor[0])
            setDisable(true)
            show(true) // table show
          }
        } else {
          setDisable(false)
        }
      })
      .catch(error => {})
  }

  const uploadFileData = () => {
    var input = document.createElement('input')
    input.type = 'file'
    input.id = 'file'
    input.setAttribute(
      'accept',
      '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
    )
    input.click()
    input.onchange = e => {
      const formData = new FormData()
      formData.append('file', e.target.files[0])
      fetch(`${baseUrl}PanToVendorUpdate/`, {
        method: 'POST',
        body: formData
      })
        .then(response => {
          // eslint-disable-next-line radix
          let status1 = parseInt(response.status)
          if (status1 == '201') {
            //   document.removeChild(input);
            getTableData()
            showPopup({
              type: MESSAGE_TYPE.SUCCESS,
              contextText: MESSAGE_TYPE.SUCCESS,
              info: 'file uploaded sucessfully'
            })
          } else {
            showPopup({
              type: MESSAGE_TYPE.SUCCESS,
              contextText: MESSAGE_TYPE.SUCCESS,
              info: 'file uploaded failed'
            })
          }
        })

        .catch(error => {})
    }
  }

  const exportExcel = async excelData => {
    var fileTypel =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
    var fileExt = '.xlsx'

    console.log('excelData', excelData)
    console.log('tableArray', tableArray)
    //
    const newExcelData = excelData.map(
      (
        {
          Vendor_Code,
          NAME1,
          // Tier,
          PAN,
          GSTIN,
          IEC_Code,
          SA_Name,
          Revenue_from_Operations_in_last_FY_Rs_Crore,
          Total_Import_of_Goods_in_last_FY_Rs_Crore,
          Total_Import_of_Services_in_last_FY_Rs_Crore,
          Total_Imports_in_last_FY_Rs_Crore,
          Estimated_Import_of_Goods_attributable_to_Applicant_Rs_Crore,
          Estimated_Import_of_Services_attributable_to_Applicant_Rs_Crore,
          Estimated_Import_attributable_to_Applicant_Rs_Crore,
          PPE_at_the_start_of_last_FY,
          PPE_At_The_Close_of_last_FY,
          Avg_PPE_for_last_FY,
          Revenue_to_PPE_ratio,
          Copies_of_AFS_of_suppliers_in_last_FY_submitted,
          // PPA_with_Tier_I_submitted_to_TA,
          Related_Vendor_Code,
          Related_Gstin
          // Claimed_for_PLI,
          // Start_Date,
          // Explanation,
        },
        id
      ) => {
        let obj = {
          id,
          Vendor_Code,
          // Plant_code,
          Vendor_Name: NAME1,
          // Tier,
          PAN,
          GSTIN,
          IEC_Code,
          SA_Name,
          Revenue_from_Operations_in_last_FY_Rs_Crore,
          Total_Import_of_Goods_in_last_FY_Rs_Crore,
          Total_Import_of_Services_in_last_FY_Rs_Crore,
          Total_Imports_in_last_FY_Rs_Crore,
          Estimated_Import_of_Goods_attributable_to_Applicant_Rs_Crore,
          Estimated_Import_of_Services_attributable_to_Applicant_Rs_Crore,
          Estimated_Import_attributable_to_Applicant_Rs_Crore,
          PPE_at_the_start_of_last_FY_Rs_Crore: PPE_at_the_start_of_last_FY,
          PPE_At_The_Close_of_last_FY_Rs_Crore: PPE_At_The_Close_of_last_FY,
          Avg_PPE_for_last_FY_Rs_Crore: Avg_PPE_for_last_FY,
          Revenue_to_PPE_ratio,
          // eslint-disable-next-line no-unneeded-ternary
          Copies_of_AFS_of_suppliers_in_last_FY_submitted: Copies_of_AFS_of_suppliers_in_last_FY_submitted == 'Yes'? true : false,
          // eslint-disable-next-line no-unneeded-ternary
          // PPA_with_Tier_I_submitted_to_TA: PPA_with_Tier_I_submitted_to_TA == 'Yes' ? true : false,
          Related_Vendor_Code: relatedVendorCode,
          Related_GSTIN: relatedGstin
          // Claimed_for_PLI,
          // Start_Date,
          //  Explanation,
        }
        return obj
      }
    )
    console.log('newExcelData', newExcelData)
    //

    const ws = XLSX.utils.json_to_sheet(newExcelData)
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] }
    const excelBuffer = XLSX.write(wb, { booktype: 'xlsx', type: 'array' })
    const data = new Blob([excelBuffer], { type: fileTypel })
    FileSaver.saveAs(data, `${Bc.value}${fileExt}`)
  }
  // const exportExcel2 = async EXdata => {
  //   //  let excelData=EXdata.map((element,id)=>(Object.assign(element,{id})))

  //   const excelData = EXdata.map(
  //     (
  //       {
  //         Vendor_Code,
  //         // Plant_code,
  //         NAME1,
  //         // Tier,
  //         PAN,
  //         GSTIN,
  //         IEC_Code,
  //         SA_Name,
  //         Revenue_from_Operations_in_last_FY_Rs_Crore,
  //         Total_Import_of_Goods_in_last_FY_Rs_Crore,
  //         Total_Import_of_Services_in_last_FY_Rs_Crore,
  //         Total_Imports_in_last_FY_Rs_Crore,
  //         Estimated_Import_of_Goods_attributable_to_Applicant_Rs_Crore,
  //         Estimated_Import_of_Services_attributable_to_Applicant_Rs_Crore,
  //         Estimated_Import_attributable_to_Applicant_Rs_Crore,
  //         PPE_at_the_start_of_last_FY,
  //         PPE_At_The_Close_of_last_FY,
  //         Avg_PPE_for_last_FY,
  //         Revenue_to_PPE_ratio,
  //         Copies_of_AFS_of_suppliers_in_last_FY_submitted,
  //         PPA_with_Tier_I_submitted_to_TA
  //       },
  //       id
  //     ) => {
  //       let obj = {
  //         id,
  //         Vendor_Code,
  //         // Plant_code,
  //         Vendor_Name: NAME1,
  //         // Tier,
  //         PAN,
  //         GSTIN,
  //         IEC_Code,
  //         SA_Name,
  //         Revenue_from_Operations_in_last_FY_Rs_Crore,
  //         Total_Import_of_Goods_in_last_FY_Rs_Crore,
  //         Total_Import_of_Services_in_last_FY_Rs_Crore,
  //         Total_Imports_in_last_FY_Rs_Crore,
  //         Estimated_Import_of_Goods_attributable_to_Applicant_Rs_Crore,
  //         Estimated_Import_of_Services_attributable_to_Applicant_Rs_Crore,
  //         Estimated_Import_attributable_to_Applicant_Rs_Crore,
  //         PPE_at_the_start_of_last_FY,
  //         PPE_At_The_Close_of_last_FY,
  //         Avg_PPE_for_last_FY,
  //         Revenue_to_PPE_ratio,
  //         Copies_of_AFS_of_suppliers_in_last_FY_submitted,
  //         // :Copies_of_AFS_of_suppliers_in_last_FY_submitted ? 'Yes' : 'No',
  //         PPA_with_Tier_I_submitted_to_TA
  //         // :PPA_with_Tier_I_submitted_to_TA ? 'Yes' : 'No'
  //       }
  //       return obj
  //     }
  //   )

  //   var fileTypel =
  //     'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
  //   var fileExt = '.xlsx'
  //   const ws = XLSX.utils.json_to_sheet(excelData)
  //   const wb = { Sheets: { data: ws }, SheetNames: ['data'] }
  //   const excelBuffer = XLSX.write(wb, { booktype: 'xlsx', type: 'array' })
  //   const data = new Blob([excelBuffer], { type: fileTypel })
  //   FileSaver.saveAs(data, `${Bc.value}${fileExt}`)
  // }

  // const baseDefaultColumns = [
  //   {
  //     width: 120,
  //     title: 'Vendor Code',
  //     field: 'Vendor_Code',
  //     enableSearch: true,
  //     enableFilter: true
  //   },
  //   // {
  //   //   width: 120,
  //   //   title: "Plant code",
  //   //   field: "Plant_code",
  //   //   enableSearch: true,
  //   //   enableFilter: true,
  //   // },
  //   {
  //     width: 120,
  //     title: 'Name of Suppliers',
  //     field: 'NAME1',
  //     enableSearch: true,
  //     enableFilter: true
  //   },
  //   {
  //     width: 120,
  //     title: 'Tier',
  //     field: 'Tier',
  //     enableSearch: true,
  //     enableFilter: true,
  //     filterOptions: true,
  //     sortData: true,
  //     selectedFilterOptions: true
  //   },
  //   {
  //     width: 120,
  //     title: 'PAN No',
  //     field: 'PAN',
  //     enableSearch: true,
  //     enableFilter: true,
  //     filterOptions: true,
  //     sortData: true,
  //     selectedFilterOptions: true
  //   },
  //   {
  //     width: 120,
  //     title: 'GSTIN',
  //     field: 'GSTIN',
  //     enableSearch: true,
  //     enableFilter: true
  //   },
  //   {
  //     width: 120,
  //     title: 'IEC Code',
  //     field: 'IEC_Code',
  //     enableSearch: true,
  //     enableFilter: true
  //   },
  //   {
  //     width: 120,
  //     title: 'Revenue from Operations in last FY',
  //     field: 'Revenue_from_Operations_in_last_FY_Rs_Crore',
  //     enableSearch: true,
  //     enableFilter: true
  //   },
  //   {
  //     width: 120,
  //     title: 'Total Imports of Good on Last FY',
  //     field: 'Total_Import_of_Goods_in_last_FY_Rs_Crore',
  //     enableSearch: true,
  //     enableFilter: true
  //   },
  //   {
  //     width: 120,
  //     title: 'Total Imports of Services* on Last FY',
  //     field: 'Total_Import_of_Services_in_last_FY_Rs_Crore',
  //     enableSearch: true,
  //     enableFilter: true
  //   },
  //   {
  //     width: 120,
  //     title: 'Total Imports in Last FY',
  //     field: 'Total_Imports_in_last_FY_Rs_Crore',
  //     enableSearch: true,
  //     enableFilter: true
  //   },
  //   {
  //     width: 150,
  //     title: 'Estimated Imports of Goods attributal to Applicant',
  //     field: 'Estimated_Import_of_Goods_attributable_to_Applicant_Rs_Crore',
  //     enableSearch: true,
  //     enableFilter: true
  //   },
  //   {
  //     width: 150,
  //     title: 'Estimated Imports of Services* attributal to Applicant',
  //     field: 'Estimated_Import_of_Services_attributable_to_Applicant_Rs_Crore',
  //     enableSearch: true,
  //     enableFilter: true
  //   },
  //   {
  //     width: 120,
  //     title: 'Estimated Imports attributal to Applicant',
  //     field: 'Estimated_Import_attributable_to_Applicant_Rs_Crore',
  //     enableSearch: true,
  //     enableFilter: true
  //   },
  //   {
  //     width: 120,
  //     title: 'PPE At The start of last FY',
  //     field: 'PPE_at_the_start_of_last_FY',
  //     enableSearch: true,
  //     enableFilter: true
  //   },
  //   {
  //     width: 120,
  //     title: 'PPE At The Close_of_last_FY',
  //     field: 'PPE_At_The_Close_of_last_FY',
  //     enableSearch: true,
  //     enableFilter: true
  //   },
  //   {
  //     width: 120,
  //     title: 'Avg PPE for last FY',
  //     field: 'Avg_PPE_for_last_FY',
  //     enableSearch: true,
  //     enableFilter: true
  //   },
  //   {
  //     width: 120,
  //     title: 'Revenue to PPE ratio',
  //     field: 'Revenue_to_PPE_ratio',
  //     enableSearch: true,
  //     enableFilter: true
  //   },
  //   {
  //     width: 120,
  //     title: 'PPA with Tier I submitted to TA',
  //     field: 'PPA_with_Tier_I_submitted_to_TA',
  //     enableSearch: true,
  //     enableFilter: true
  //   },
  //   {
  //     width: 120,
  //     title: 'Copies of AFS of suppliers in last FY submitted',
  //     field: 'Copies_of_AFS_of_suppliers_in_last_FY_submitted',
  //     enableSearch: true,
  //     enableFilter: true
  //   }
  // ]
  
  // console.log('baseDefaultColumn: ', baseDefaultColumns)

  /* Check Over all Status API */
  const CheckOverallStatusAPI = () => {
    let headerss = new Headers()
    fetch(`${baseUrl}CheckOverallStatus`, {
      method: 'GET',
      headers: headerss
    })
      .then(async responseJson => {
        console.log('CheckOverallStatus: ', responseJson)
      })
      .catch(error => {
        console.log('CheckOverallStatus Error: ', error)
      })
  }

  const goNextForm = () => {
    var url
    var urlMethod

    // let { userRole, userID } = auth

    if (userRole.includes('GDC')) {
      url = 'MIS_Supplier_data_for_Same_PAN'
      urlMethod = 'PUT'
    } else if (userRole.includes('Vendor') || userRole.includes('Vendor-CV')) {
      url = 'MIS_Supplier_data_for_Same_PAN1'
      urlMethod = 'POST'
    } else if (userRole.includes('TMLBSL')) {
      url = 'MIS_TMLBSL_data_for_PAN_for_both'
      urlMethod = 'POST'
    } else if (userRole.includes('Purchase')) {
      url = 'MIS_Purchase_data_for_PAN'
      urlMethod = 'POST'
    } else {
      url = 'MIS_Supplier_data_for_Same_PAN'
      urlMethod = 'POST'
    }
    const apiFormatedData = {
      Vendor_Code: Bc.value,
      Financial_year: financialYear?.value
    }

    var apiFormatedDataJson = JSON.stringify(apiFormatedData)
    let headerss = new Headers()
    headerss.append('Content-Type', 'application/json')
    fetch(`${baseUrl}${url}/`, {
      method: urlMethod,
      headers: headerss,
      body: apiFormatedDataJson
    })
      .then(response => response.json())
      .then(responseJson => {
        CheckOverallStatusAPI()
        showPopup({
          type: MESSAGE_TYPE.SUCCESS,
          contextText: MESSAGE_TYPE.SUCCESS,
          info: 'Data is Succesfully Saved'
        })
        history.push('/')
      })
      .catch(error => {})
  }

  const handleOpenSubmit = () => {}
  const handlepopup = message => {
    showPopup({
      type: MESSAGE_TYPE.FAILURE,
      contextText: MESSAGE_TYPE.FAILURE,
      info: message
    })
  }

  const handleInfoPopup = message => {
    showPopup({
      type: MESSAGE_TYPE.INFORMATION,
      contextText: MESSAGE_TYPE.INFORMATION,
      info: message
    })
  }

    /* Summation and average calculations */
    const parsedTotalGoods = parseFloat(manualFormData?.Total_Import_of_Goods_in_last_FY_Rs_Crore) || 0
    const parsedTotalServices = parseFloat(manualFormData?.Total_Import_of_Services_in_last_FY_Rs_Crore) || 0
    // const totalImportInLastFY = parseFloat(vendorCode.manualFormData?.Total_Import_of_Goods_in_last_FY_Rs_Crore) || 0 + 
    // parseFloat(vendorCode.manualFormData?.Total_Import_of_Services_in_last_FY_Rs_Crore) || 0
  
    const totalImportInLastFY = parsedTotalGoods + parsedTotalServices
    // console.log('totalImportInLastFY int', typeof(totalImportInLastFY))
    // console.log('int parsedTotalGoods', parsedTotalGoods)
    // console.log('int parsedTotalServices', parsedTotalServices)
    // console.log('int totalImportInLastFY', totalImportInLastFY)
  
    const parsedEstimated_Import_of_Goods_attributable = parseFloat(manualFormData?.Estimated_Import_of_Goods_attributable_to_Applicant_Rs_Crore) || 0
    const parsedEstimated_Import_of_Services_attributable_to_Applicant = parseFloat(manualFormData?.Estimated_Import_of_Services_attributable_to_Applicant_Rs_Crore) || 0
  
    const totalEstimatedImportAttributableApplicant = parsedEstimated_Import_of_Goods_attributable + parsedEstimated_Import_of_Services_attributable_to_Applicant
    // console.log('totalEstimatedImportAttributableApplicant', totalEstimatedImportAttributableApplicant)
  
    const parsedPPE_at_the_start_of_last_FY = parseFloat(manualFormData?.PPE_at_the_start_of_last_FY) || 0
    const parsedPPE_At_The_Close_of_last_FY = parseFloat(manualFormData?.PPE_At_The_Close_of_last_FY) || 0
  
    const avgParsedPPE = (parsedPPE_at_the_start_of_last_FY + parsedPPE_At_The_Close_of_last_FY) / 2

    /* Revenue to PPE Ratio calculation */
    const calcRevenueToPPERatio = avgParsedPPE != 0 ? manualFormData?.Revenue_from_Operations_in_last_FY_Rs_Crore / avgParsedPPE : 0
    // console.log('revenueToPPERation: ', calcRevenueToPPERatio)
    // console.log('revenueToPPERation type: ', typeof(calcRevenueToPPERatio))

    // console.log('int avgParsedPPE', avgParsedPPE)
    // console.log('int parsedEstimated_Import_of_Goods_attributable', parsedEstimated_Import_of_Goods_attributable)
    // console.log('int parsedEstimated_Import_of_Services_attributable_to_Applicant', parsedEstimated_Import_of_Services_attributable_to_Applicant)
    // console.log('int totalEstimatedImportAttributableApplicant', totalEstimatedImportAttributableApplicant)

    // console.log('tableArray AFS', tableArray[0]?.Afs_Document_mandatory);
    // console.log('auth in AFS: ', auth);
    // console.log('tableArray AFS: condition: ', (userRole?.includes('admin') || userRole?.includes('Vendor')) && (tableArray[0]?.Afs_Document_mandatory && acceptedFiles1?.length === 0))

  const manualDataUpload = prop => {

    const date = new Date();
    const formattedDate = date.toLocaleDateString('en-GB')

    const formattedDateOfPPA = moment(manualFormData?.Date_of_PPA).format("DD/MM/YYYY")
    const body = {
      UserID: isVendorUser ? userID : tableArray[0]?.UserID,
      Financial_year: financialYear?.value,
      Vendor_Code: Bc?.value,
      NAME1: tableArray[0]?.NAME1,
      PAN: tableArray[0]?.PAN,
      Tier: tableArray[0]?.Tier,
      GSTIN: tableArray[0]?.GSTIN,
      BuyerName: tableArray[0]?.BuyerName ? tableArray[0]?.BuyerName : '',
      // eslint-disable-next-line no-unneeded-ternary
      Copies_of_AFS_of_suppliers_in_last_FY_submitted: manualFormData?.Copies_of_AFS_of_suppliers_in_last_FY_submitted?.value == 'Yes' ? true : false,
      // eslint-disable-next-line no-unneeded-ternary
      PPA_with_Tier_I_submitted_to_TA: manualFormData?.PPA_with_Tier_I_submitted_to_TA?.value == 'Yes' ? true : false,
      IEC_Code: manualFormData?.IEC_Code,
       Test4: iecChecked.toString(),
      // Test4: iecChecked ? iecChecked.toString() : '',
      // Test4: iecChecked ? 'true' : 'false',
      SA_Name: manualFormData?.SA_Name,
      // eslint-disable-next-line no-unneeded-ternary
      MPFD_for_major_suppliers_submitted: manualFormData?.MPFD_for_major_suppliers_submitted?.value == 'Yes' ? true : false,
      Date_of_PPA: formattedDateOfPPA,
      // SA_tenure: manualFormData?.SA_tenure,
      Test11: manualFormData?.SA_tenure,

      Revenue_from_Operations_in_last_FY_Rs_Crore:
        manualFormData?.Revenue_from_Operations_in_last_FY_Rs_Crore,
      Estimated_Import_of_Goods_attributable_to_Applicant_Rs_Crore:
        manualFormData?.Estimated_Import_of_Goods_attributable_to_Applicant_Rs_Crore,
      Estimated_Import_of_Services_attributable_to_Applicant_Rs_Crore:
        manualFormData?.Estimated_Import_of_Services_attributable_to_Applicant_Rs_Crore,
      PPE_At_The_Close_of_last_FY: manualFormData?.PPE_At_The_Close_of_last_FY,
      PPE_at_the_start_of_last_FY: manualFormData?.PPE_at_the_start_of_last_FY,
      Total_Import_of_Goods_in_last_FY_Rs_Crore:
        manualFormData?.Total_Import_of_Goods_in_last_FY_Rs_Crore,
      Total_Import_of_Services_in_last_FY_Rs_Crore:
        manualFormData?.Total_Import_of_Services_in_last_FY_Rs_Crore,
      Total_Imports_in_last_FY_Rs_Crore: totalImportInLastFY,
      Estimated_Import_attributable_to_Applicant_Rs_Crore:
        totalEstimatedImportAttributableApplicant,
      Avg_PPE_for_last_FY: avgParsedPPE,
      Revenue_to_PPE_ratio: calcRevenueToPPERatio,
      updated_at: (isVendorUser || isAdminUser) ? formattedDate : tableArray[0]?.updated_at,
      TMLBSL_Validation_Email_comment: manualFormData?.TMLBSL_Validation_Email_comment,
      Purchase_Validation_Email_comment: manualFormData?.Purchase_Validation_Email_comment,
      PMT_Validation_Email_comment: manualFormData?.PMT_Validation_Email_comment,

      Updated_by_emailId: (isVendorUser || isAdminUser) ? email : tableArray[0]?.Updated_by_emailId,
      Updated_by_IpAddress: isVendorUser ? clientIP : tableArray[0]?.Updated_by_IpAddress,
      Updated_by_MacId: null,
      Updated_by_Username: (isVendorUser || isAdminUser) ? userName : tableArray[0]?.Updated_by_Username,
      TMLBSL_Validator_Name: (isGDCUser || isAdminUser) ? userName : tableArray[0]?.TMLBSL_Validator_Name,
      TMLBSL_Validation_Date: (isGDCUser || isAdminUser) ? formattedDate : tableArray[0]?.TMLBSL_Validation_Date,
      Purchase_PMT_Validator_Name: (isPurchaseUser || isAdminUser) ? userName : tableArray[0]?.Purchase_PMT_Validator_Name,
      Purchase_PMT_Validation_Date: (isPurchaseUser || isAdminUser) ? formattedDate : tableArray[0]?.Purchase_PMT_Validation_Date
    }

    const headerss = new Headers()
    headerss.append('Content-Type', 'application/json')
    // fetch(`${baseUrl}PanManualDataForAllVendors2/`, {
    fetch(`${baseUrl}PanManualDataForAllVendors1/`, {
      method: 'POST',
      headers: headerss,
      body: JSON.stringify(body)
    })
      .then(async responseJson => {
        // setTableArray(responseJson);
        if (responseJson?.ok) {
          showPopup({
            type: MESSAGE_TYPE.SUCCESS,
            contextText: MESSAGE_TYPE.SUCCESS,
            info: 'AFS form submitted Successfully!'
          })
          // setFormSubmitTrigger(false)
          goNextForm()
          handleCloseManualForm()
        }
        else {
          showPopup({
            type: MESSAGE_TYPE.FAILURE,
            contextText: MESSAGE_TYPE.FAILURE,
            info: 'AFS form not submitted Successfully!'
          })
          console.log('responseJson false', responseJson?.ok)
        }
      })
      .catch(error => {
        console.log('ManualDataUpload: ', error)
      })
  }
  // console.log('formSubmitTrigger before function', formSubmitTrigger)
  // if (formSubmitTrigger) {
  //   manualDataUpload()
  // }
  const classes = useStyles()

  const renderByRole = () => {
    if (
      userRole.includes('admin') ||
      userRole.includes('Purchase') ||
      userRole.includes('TMLBSL')
    ) {
      // showTable()
      // setTableArray([])
      // getTableData()
      getTableData1(Bc?.value)
    } else {
      // getTableData()
      getTableData1(Bc?.value)
    }
  }


  const getStatusDataForButtonAPIView = () => {
    const body = {
      Vendor_Code: Bc?.value,
      Financial_year: financialYear?.value
    }
    const headerss = new Headers()
    headerss.append('Content-Type', 'application/json')
    fetch(`${baseUrl}StatusDataForButtonAPIView/`, {
      method: 'POST',
      headers: headerss,
      body: JSON.stringify(body)
    })
      .then((response) => response.json())
      .then(async responseJson => {
        // console.log('responseJson in status api: ', responseJson)
        setButtonStatusData(responseJson)
        // if (responseJson?.Purchase_Validation_status === 'Validation Completed' || responseJson?.TMLBSL_Validation_Status === 'Validation Completed') {
        //   console.log('inside editable');
        //   setEditableByUser(true)
        // }
      })
      .catch(error => {
        console.log('status API error: ', error)
      })
  }

  //   <div className={styles.formGroup}>
  //     <Grid
  //       container
  //       spacing={1}
  //       style={{ display: 'flex', justifyContent: 'left', marginTop: '-14px' }}
  //       columns={12}
  //     >
  //       <Grid item xs={6} className={styles.alignCenter}>
  //         <div className={clsx(styles.lableDiv)}>
  //           <label>
  //             Vendor Code<span style={{ color: 'red' }}>*</span>
  //           </label>
  //         </div>
  //       </Grid>
  //       <Grid item xs={6} className={styles.alignCenter}>
  //         <div className={styles.formDiv}>
  //           <ValidatingTextField
  //             variant='outlined'
  //             size='small'
  //             fullWidth
  //             value={Bc?.value}
  //             name='Vendor_Code'
  //             inputProps={{
  //               'data-testid': 'remark-input',
  //             }}
  //             // eslint-disable-next-line react/jsx-boolean-value
  //             isDisabled={true}
  //           />
  //         </div>
  //       </Grid>
  //     </Grid>
  //     <Grid
  //       container
  //       spacing={1}
  //       style={{ display: 'flex', justifyContent: 'left', marginTop: '10px' }}
  //       columns={12}
  //     >
  //       <Grid item xs={6} className={styles.alignCenter}>
  //         <div className={clsx(styles.lableDiv)}>
  //           <label>
  //             Name of Supplier<span style={{ color: 'red' }}>*</span>
  //           </label>
  //         </div>
  //       </Grid>
  //       <Grid item xs={6} className={styles.alignCenter}>
  //         <div className={styles.formDiv}>
  //           <ValidatingTextField
  //             variant='outlined'
  //             size='small'
  //             fullWidth
  //             multiline
  //             // onChange={onChangeHandler}
  //             name='model'
  //             value={tableArray[0]?.NAME1}
  //             placeholder=''
  //             inputProps={{
  //               'data-testid': 'remark-input'
  //             }}
  //             // eslint-disable-next-line react/jsx-boolean-value
  //             isDisabled={true}
  //           />
  //         </div>
  //       </Grid>
  //     </Grid>
  //     <Grid
  //       container
  //       spacing={1}
  //       style={{ display: 'flex', justifyContent: 'left', marginTop: '10px' }}
  //       columns={12}
  //     >
  //       <Grid item xs={6} className={styles.alignCenter}>
  //         <div className={clsx(styles.lableDiv)}>
  //           <label>
  //             PAN<span style={{ color: 'red' }}>*</span>
  //           </label>
  //         </div>
  //       </Grid>
  //       <Grid item xs={6} className={styles.alignCenter}>
  //         <div className={styles.formDiv}>
  //           <ValidatingTextField
  //             variant='outlined'
  //             size='small'
  //             fullWidth
  //             multiline
  //             // onChange={onChangeHandler}
  //             name='PAN'
  //             value={tableArray[0]?.PAN}
  //             placeholder=''
  //             inputProps={{
  //               'data-testid': 'remark-input'
  //             }}
  //             // eslint-disable-next-line react/jsx-boolean-value
  //             isDisabled={true}
  //           />
  //         </div>
  //       </Grid>
  //     </Grid>
  //     <Grid
  //       container
  //       spacing={1}
  //       style={{ display: 'flex', justifyContent: 'left', marginTop: '10px' }}
  //       columns={12}
  //     >
  //       <Grid item xs={6} className={styles.alignCenter}>
  //         <div className={clsx(styles.lableDiv)}>
  //           <label>
  //             Tier<span style={{ color: 'red' }}>*</span>
  //           </label>
  //         </div>
  //       </Grid>
  //       <Grid item xs={6} className={styles.alignCenter}>
  //         <div className={styles.formDiv}>
  //           <ValidatingTextField
  //             variant='outlined'
  //             size='small'
  //             fullWidth
  //             multiline
  //             // onChange={onChangeHandler}
  //             name='Tier'
  //             value={tableArray[0]?.Tier}
  //             placeholder=''
  //             inputProps={{
  //               'data-testid': 'remark-input'
  //             }}
  //             // eslint-disable-next-line react/jsx-boolean-value
  //             isDisabled={true}
  //           />
  //         </div>
  //       </Grid>
  //     </Grid>
  //     <Grid
  //       container
  //       spacing={1}
  //       style={{ display: 'flex', justifyContent: 'left', marginTop: '10px' }}
  //       columns={12}
  //     >
  //       <Grid item xs={6} className={styles.alignCenter}>
  //         <div className={clsx(styles.lableDiv)}>
  //           <label>
  //             GSTIN<span style={{ color: 'red' }}>*</span>
  //           </label>
  //         </div>
  //       </Grid>
  //       <Grid item xs={6} className={styles.alignCenter}>
  //         <div className={styles.formDiv}>
  //           <ValidatingTextField
  //             variant='outlined'
  //             size='small'
  //             fullWidth
  //             multiline
  //             onChange={onChangeHandler}
  //             name='GSTIN'
  //             value={tableArray[0]?.GSTIN}
  //             placeholder=''
  //             inputProps={{
  //               'data-testid': 'remark-input'
  //             }}
  //             // eslint-disable-next-line react/jsx-boolean-value
  //             isDisabled={true}
  //           />
  //         </div>
  //       </Grid>
  //     </Grid>
  //     <Grid
  //       container
  //       spacing={1}
  //       style={{ display: 'flex', justifyContent: 'left', marginTop: '10px' }}
  //       columns={12}
  //     >
  //       <Grid item xs={6} className={styles.alignCenter}>
  //         <div className={clsx(styles.lableDiv)}>
  //           <label>
  //             IEC Code<span style={{ color: 'red' }}>*</span>
  //           </label>
  //         </div>
  //       </Grid>
  //       <Grid item xs={6} className={styles.alignCenter}>
  //         <div className={styles.formDiv}>
  //           <ValidatingTextField
  //             variant='outlined'
  //             size='small'
  //             fullWidth
  //             multiline
  //             onChange={onChangeHandler}
  //             name='IEC_Code'
  //             value={myState.IEC_Code}
  //             placeholder=''
  //             inputProps={{
  //               'data-testid': 'remark-input'
  //             }}
  //             // style={{ width: '100%' }}
  //           />
  //         </div>
  //       </Grid>
  //     </Grid>
  //     <Grid
  //       container
  //       spacing={1}
  //       style={{ display: 'flex', justifyContent: 'left', marginTop: '10px' }}
  //       columns={12}
  //     >
  //       <Grid item xs={6} className={styles.alignCenter}>
  //         <div className={clsx(styles.lableDiv)}>
  //           <label>
  //             Revenue from Operations in last FY (Rs. crore)
  //             <span style={{ color: 'red' }}>*</span>
  //           </label>
  //         </div>
  //       </Grid>
  //       <Grid item xs={6} className={styles.alignCenter}>
  //         <div className={styles.formDiv}>
  //           <ValidatingTextField
  //             variant='outlined'
  //             size='small'
  //             fullWidth
  //             multiline
  //             onChange={onChangeHandler}
  //             name='Revenue_from_Operations_in_last_FY_Rs_Crore'
  //             // value={myState.variant}
  //             placeholder=''
  //             inputProps={{
  //               'data-testid': 'remark-input'
  //             }}
  //             // style={{ width: '100%' }}
  //           />
  //         </div>
  //       </Grid>
  //     </Grid>
  //     <Grid
  //       container
  //       spacing={1}
  //       style={{ display: 'flex', justifyContent: 'left', marginTop: '10px' }}
  //       columns={12}
  //     >
  //       <Grid item xs={6} className={styles.alignCenter}>
  //         <div className={clsx(styles.lableDiv)}>
  //           <label>
  //             Total Import of Goods in last FY (Rs. crore)
  //             <span style={{ color: 'red' }}>*</span>
  //           </label>
  //         </div>
  //       </Grid>
  //       <Grid item xs={6} className={styles.alignCenter}>
  //         <div className={styles.formDiv}>
  //           <ValidatingTextField
  //             variant='outlined'
  //             size='small'
  //             fullWidth
  //             multiline
  //             onChange={onChangeHandler}
  //             name='Total_Import_of_Goods_in_last_FY_Rs_Crore'
  //             // value={myState.variant}
  //             placeholder=''
  //             inputProps={{
  //               'data-testid': 'remark-input'
  //             }}
  //             style={{ width: '100%' }}
  //           />
  //         </div>
  //       </Grid>
  //     </Grid>
  //     <Grid
  //       container
  //       spacing={1}
  //       style={{ display: 'flex', justifyContent: 'left', marginTop: '10px' }}
  //       columns={12}
  //     >
  //       <Grid item xs={6} className={styles.alignCenter}>
  //         <div className={clsx(styles.lableDiv)}>
  //           <label>
  //             Total Import of Services in last FY (Rs. crore)
  //             <span style={{ color: 'red' }}>*</span>
  //           </label>
  //         </div>
  //       </Grid>
  //       <Grid item xs={6} className={styles.alignCenter}>
  //         <div className={styles.formDiv}>
  //           <ValidatingTextField
  //             variant='outlined'
  //             size='small'
  //             fullWidth
  //             multiline
  //             onChange={onChangeHandler}
  //             name='Total_Import_of_Services_in_last_FY_Rs_Crore'
  //             // value={myState.variant}
  //             placeholder=''
  //             inputProps={{
  //               'data-testid': 'remark-input'
  //             }}
  //             style={{ width: '100%' }}
  //           />
  //         </div>
  //       </Grid>
  //     </Grid>
  //     <Grid
  //       container
  //       spacing={1}
  //       style={{ display: 'flex', justifyContent: 'left', marginTop: '10px' }}
  //       columns={12}
  //     >
  //       <Grid item xs={6} className={styles.alignCenter}>
  //         <div className={clsx(styles.lableDiv)}>
  //           <label>
  //             Estimated Import of Goods attributable to Applicant (Rs. crore)
  //             <span style={{ color: 'red' }}>*</span>
  //           </label>
  //         </div>
  //       </Grid>
  //       <Grid item xs={6} className={styles.alignCenter}>
  //         <div className={styles.formDiv}>
  //           <ValidatingTextField
  //             variant='outlined'
  //             size='small'
  //             fullWidth
  //             multiline
  //             onChange={onChangeHandler}
  //             name='Estimated_Import_of_Goods_attributable_to_Applicant_Rs_Crore'
  //             // value={myState.variant}
  //             placeholder=''
  //             inputProps={{
  //               'data-testid': 'remark-input'
  //             }}
  //             style={{ width: '100%' }}
  //           />
  //         </div>
  //       </Grid>
  //     </Grid>
  //     <Grid
  //       container
  //       spacing={1}
  //       style={{ display: 'flex', justifyContent: 'left', marginTop: '10px' }}
  //       columns={12}
  //     >
  //       <Grid item xs={6} className={styles.alignCenter}>
  //         <div className={clsx(styles.lableDiv)}>
  //           <label>
  //             Estimated Import of Services attributable to Applicant (Rs. crore)
  //             <span style={{ color: 'red' }}>*</span>
  //           </label>
  //         </div>
  //       </Grid>
  //       <Grid item xs={6} className={styles.alignCenter}>
  //         <div className={styles.formDiv}>
  //           <ValidatingTextField
  //             variant='outlined'
  //             size='small'
  //             fullWidth
  //             multiline
  //             onChange={onChangeHandler}
  //             name='Estimated_Import_of_Services_attributable_to_Applicant_Rs_Crore'
  //             // value={myState.variant}
  //             placeholder=''
  //             inputProps={{
  //               'data-testid': 'remark-input'
  //             }}
  //             style={{ width: '100%' }}
  //           />
  //         </div>
  //       </Grid>
  //     </Grid>
  //     {/* <Grid
  //       container
  //       spacing={1}
  //       style={{ display: 'flex', justifyContent: 'left', marginTop: '10px' }}
  //       columns={12}
  //     >
  //       <Grid item xs={6} className={styles.alignCenter}>
  //         <div className={clsx(styles.lableDiv)}>
  //           <label>
  //             MPFD for major suppliers submitted
  //             <span style={{ color: 'red' }}>*</span>
  //           </label>
  //         </div>
  //       </Grid>
  //       <Grid item xs={6} className={styles.alignCenter}>
  //         <div className={styles.formDiv}>
  //           <CustomSelect
  //             isMandatory
  //             // markIfUnselected={highlightMandatoryFields}
  //             // resetAllVisitedFields={resetAllVisitedFields}
  //             className={clsx(styles.select, styles.sel2)}
  //             isMulti={false}
  //             isClearable
  //             //   value={myState.plantCode}
  //             options={[
  //               { value: 'Yes', label: 'Yes' },
  //               { value: 'No', label: 'No' }
  //             ]}
  //             onChange={e => {
  //               handleSelect(e, 'plantCode')
  //             }}
  //             name='plantCode'
  //           />
  //         </div>
  //       </Grid>
  //     </Grid> */}
  //     <Grid
  //       container
  //       spacing={1}
  //       style={{ display: 'flex', justifyContent: 'left', marginTop: '10px' }}
  //       columns={12}
  //     >
  //       <Grid item xs={6} className={styles.alignCenter}>
  //         <div className={clsx(styles.lableDiv)}>
  //           <label>
  //             PPA with Tier-I submitted to TA
  //             <span style={{ color: 'red' }}>*</span>
  //           </label>
  //         </div>
  //       </Grid>
  //       <Grid item xs={6} className={styles.alignCenter}>
  //         <div className={styles.formDiv}>
  //           <CustomSelect
  //             isMandatory
  //             // markIfUnselected={highlightMandatoryFields}
  //             // resetAllVisitedFields={resetAllVisitedFields}
  //             className={clsx(styles.select, styles.sel2)}
  //             isMulti={false}
  //             isClearable
  //             value={myState?.PPA_with_Tier_I_submitted_to_TA}
  //             options={[
  //               { value: 'Yes', label: 'Yes' },
  //               { value: 'No', label: 'No' }
  //             ]}
  //             onChange={e => {
  //               manualFormHandleSelect(e, 'PPA_with_Tier_I_submitted_to_TA')
  //             }}
  //             name='PPA_with_Tier_I_submitted_to_TA'
  //           />
  //         </div>
  //       </Grid>
  //     </Grid>
  //     {/* <Grid
  //       container
  //       spacing={1}
  //       style={{ display: 'flex', justifyContent: 'left', marginTop: '10px' }}
  //       columns={12}
  //     >
  //       <Grid item xs={6} className={styles.alignCenter}>
  //         <div className={clsx(styles.lableDiv)}>
  //           <label>
  //             Date of PPA
  //             <span style={{ color: 'red' }}>*</span>
  //           </label>
  //         </div>
  //       </Grid>
  //       <Grid item xs={6} className={styles.alignCenter}>
  //         <div className={styles.formDiv}>
  //           <ValidatingTextField
  //             variant='outlined'
  //             size='small'
  //             fullWidth
  //             type='date'
  //             // onChange={handleDateChange}
  //             name='variant'
  //             // value={myState.variant}
  //           />
  //         </div>
  //       </Grid>
  //     </Grid> */}
  //     <Grid
  //       container
  //       spacing={1}
  //       style={{ display: 'flex', justifyContent: 'left', marginTop: '10px' }}
  //       columns={12}
  //     >
  //       <Grid item xs={6} className={styles.alignCenter}>
  //         <div className={clsx(styles.lableDiv)}>
  //           <label>
  //             PPE at the start of last FY (Rs. crore)
  //             <span style={{ color: 'red' }}>*</span>
  //           </label>
  //         </div>
  //       </Grid>
  //       <Grid item xs={6} className={styles.alignCenter}>
  //         <div className={styles.formDiv}>
  //           <ValidatingTextField
  //             variant='outlined'
  //             size='small'
  //             fullWidth
  //             multiline
  //             onChange={onChangeHandler}
  //             name='PPE_at_the_start_of_last_FY'
  //             // value={myState.variant}
  //             placeholder=''
  //             inputProps={{
  //               'data-testid': 'remark-input'
  //             }}
  //             style={{ width: '100%' }}
  //           />
  //         </div>
  //       </Grid>
  //     </Grid>
  //     <Grid
  //       container
  //       spacing={1}
  //       style={{ display: 'flex', justifyContent: 'left', marginTop: '10px' }}
  //       columns={12}
  //     >
  //       <Grid item xs={6} className={styles.alignCenter}>
  //         <div className={clsx(styles.lableDiv)}>
  //           <label>
  //             PPE at the close of last FY (Rs. crore)
  //             <span style={{ color: 'red' }}>*</span>
  //           </label>
  //         </div>
  //       </Grid>
  //       <Grid item xs={6} className={styles.alignCenter}>
  //         <div className={styles.formDiv}>
  //           <ValidatingTextField
  //             variant='outlined'
  //             size='small'
  //             fullWidth
  //             multiline
  //             onChange={onChangeHandler}
  //             name='PPE_At_The_Close_of_last_FY'
  //             // value={myState.variant}
  //             placeholder=''
  //             inputProps={{
  //               'data-testid': 'remark-input'
  //             }}
  //             style={{ width: '100%' }}
  //           />
  //         </div>
  //       </Grid>
  //     </Grid>
  //           <Grid
  //       container
  //       spacing={1}
  //       style={{ display: 'flex', justifyContent: 'left', marginTop: '10px' }}
  //       columns={12}
  //     >
  //       <Grid item xs={6} className={styles.alignCenter}>
  //         <div className={clsx(styles.lableDiv)}>
  //           <label>
  //             Copies of AFS of major suppliers in last FY submitted
  //             <span style={{ color: 'red' }}>*</span>
  //           </label>
  //         </div>
  //       </Grid>
  //       <Grid item xs={6} className={styles.alignCenter}>
  //         <div className={styles.formDiv}>
  //           <CustomSelect
  //             isMandatory
  //             className={clsx(styles.select, styles.sel2)}
  //             isMulti={false}
  //             isClearable
  //               value={myState.Copies_of_AFS_of_suppliers_in_last_FY_submitted}
  //             options={[
  //               { value: 'Yes', label: 'Yes' },
  //               { value: 'No', label: 'No' }
  //             ]}
  //             onChange={e => {
  //               manualFormHandleSelect(e, 'Copies_of_AFS_of_suppliers_in_last_FY_submitted')
  //             }}
  //             name='Copies_of_AFS_of_suppliers_in_last_FY_submitted'
  //           />
  //         </div>
  //       </Grid>
  //     </Grid>
  //   </div>
  // )
  // console.log('isOpenManualForm: ', isOpenManualForm)
  // function ManualForm () {
  //   // const classes = useStyles()
  //   return (
  //     // <ThemeProvider theme={theme}>
  //     <Dialog
  //       open={isOpenManualForm}
  //       // className={styles.manualFormPopupContainer}
  //       // classes={{
  //       //   paper: styles.manualFormPopupBox,
  //       // }}
  //       classes={{ paper: classes.customDialog }}
  //       fullWidth
  //     >
  //       <>
  //         <DialogTitle>
  //           <span>
  //             <span
  //             // className={styles.txt}
  //             >
  //               Supplier Import Details
  //             </span>
  //           </span>
  //         </DialogTitle>
  //         <DialogContent
  //         // className={styles.content}
  //         >
  //           <SupplierImportDetailsManualForm
  //             vendorCode={Bc?.value}
  //             supplierName={tableArray[0]?.NAME1}
  //             pan={tableArray[0]?.PAN}
  //             tier={tableArray[0]?.Tier}
  //             gstin={tableArray[0]?.GSTIN}
  //             formSubmitTrigger={formSubmitTrigger}
  //             manualDataUpload={manualDataUpload}
  //             setManualFormData={setManualFormData}
  //             manualFormData={manualFormData}
  //             // myState={myState}
  //           />
  //           {/* <CustomFormGroup body={renderOther()} /> */}
  //         </DialogContent>
  //         <DialogActions>
  //           <Button
  //             className={clsx(styles.actionButton, styles.transparentButton)}
  //             onClick={handleCloseManualForm}
  //           >
  //             Cancel
  //           </Button>
  //           <Button
  //             className={clsx(styles.actionButton, styles.primaryActionButton)}
  //             variant='primary'
  //             onClick={e => {
  //               e.preventDefault()
  //               setFormSubmitTrigger(true)
  //               // ManualDataUpload()
  //               // handleClose();
  //               // goNext();
  //             }}
  //           >
  //             Submit
  //           </Button>
  //         </DialogActions>
  //       </>
  //     </Dialog>
  //     // </ThemeProvider>
  //   )
  // }
  // console.log('financialYear', financialYear);

  // const redirectToCreateProjectPage = () => history.push("/");
  const renderHTML = () => (
    <div
    // className={styles.formGroup}
    >
      <Grid
        container
        spacing={1}
        style={{
          display: 'flex',
          justifyContent: 'left',
          // marginTop: '5px',
          marginBottom: '1px'
        }}
        columns={12}
      >
        <Grid item md={11} lg={11}>
          <div className={styles.rowDiv}>
            <div className={clsx(styles.lableDiv)} style={{ width: '20%' }}>
              <label className={styles.label}>Financial Year</label>
            </div>
            <CustomSelect
              name='FY'
              options={[
                // { value: 'default', label: '2022', isDisabled: true },
                // { value: '2030', label: '2030' },
                // { value: '2029', label: '2029' },
                // { value: '2028', label: '2028' },
                // { value: '2027', label: '2027' },
                // { value: '2026', label: '2026' },
                { value: '2024-25', label: '2024-25' },
                { value: '2023-24', label: '2023-24' },
                { value: '2022-23', label: '2022-23' },
                // { value: '2022', label: '2022' }
                // { value: '2021', label: '2021'},
                // { value: '2020', label: '2020'},
              ]}
              // isDisabled={checkDisable}
              className={clsx(styles.select, styles.sel1, styles.inputColor)}
              value={financialYear}
              isMulti={false}
              // isClearable
              onChange={e => {
                handleSelect(e, 'FY')
              }}
              // defaultValue='2023'
              selectedOption='2023'
            />
            <div className={clsx(styles.lableDiv)} style={{ width: '13%' }}>
              <label className={styles.label}>Supplier</label>
            </div>
            <div className={styles.formDiv}>
              <CustomSelect
                name='bc'
                options={bcArray}
                isDisabled={checkDisable}
                className={clsx(styles.select, styles.sel1)}
                value={Bc}
                isMulti={false}
                // isClearable
                onChange={e => {
                  handleSelect(e, 'VC')
                }}
              />
              <div
                style={{
                  marginLeft: '20px',
                  marginTop: '1px',
                  justifyContent: 'center',
                  display: 'flex'
                }}
              >
                <Button
                  className={clsx(
                    styles.actionButton,
                    styles.primaryActionButton
                  )}
                  variant='primary'
                  onClick={() => {
                    if (!Bc?.value && !financialYear?.value) {
                      handleInfoPopup(
                        'Please select both Vendor and Financial Year!'
                      )
                    } else if (!Bc?.value) {
                      handleInfoPopup('Please select Vendor!')
                    } else if (!financialYear?.value) {
                      handleInfoPopup('Please select Financial Year!')
                    } else {
                      renderByRole()
                    }
                    setAcceptedFiles1(null)
                  }}
                >
                  Show Data
                </Button>
                {loader == false && (
                  <AuthChecker operation='download'>
                    {isAuthorized => (
                      <Button
                        className={clsx(
                          styles.actionButton,
                          styles.primaryActionButton
                        )}
                        variant='primary'
                        //   onClick={() => runPreSubmissionChecks() && handleSubmit()}
                        onClick={() => {
                          if (tableArray.length >= 1) {
                            exportExcel(tableArray)
                          }
                        }}
                        disabled={!isAuthorized}
                        data-testid='confirm-action'
                        style={{ marginLeft: '5px' }}
                      >
                        download
                      </Button>
                    )}
                  </AuthChecker>
                )}
                {/* {
                  loader == false &&

                  <AuthChecker operation="alldata">

                  {isAuthorized => (
                    <Button
                      className={clsx(
                        styles.actionButton,
                        styles.primaryActionButton,
                      )}
                      variant="primary"
                      //   onClick={() => runPreSubmissionChecks() && handleSubmit()}
                   //   onClick={showTableAll}
                      onClick={getDataAll}
                      disabled={!isAuthorized}
                      data-testid="confirm-action"
                      style={{ marginLeft: "5px" ,    width: "max-content"}}

                    >
                      ALL Data
                    </Button>
                  )}

                </AuthChecker>


                } */}
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
      {/* <div className={styles.formGroup}>
        <div className={styles.formGroupRow}>
          <div className={styles.formRow}>
            <label className={styles.label}>Vendor Code</label>
            <CustomSelect
              name="bc"
              options={bcArray}
              isDisabled={checkDisable}
              className={clsx(styles.select, styles.sel1)}
              value={Bc}
              isMulti={false}
              isClearable
              onChange={(e) => {
                handleSelect(e, "VC");
              }}
            />
          </div>
          <div className={styles.formRow}>
            <label className={styles.label}>
              <Button
                className={clsx(
                  styles.actionButton,
                  styles.primaryActionButton
                )}
                variant="primary"
                onClick={showTable}
              >
                Download Data
              </Button>
            </label>

          </div>
        </div>


      </div> */}
    </div>
  )

  return (
    <>
      <div className={styles.container} style={{ boxSizing: 'border-box' }}>
        <div>
          <div className={styles.backButtonLastRefreshedDateWrapper}>
            <BackButton
              action='Back'
              handleClick={redirectToApplicationLandingPage}
            />          
          <div className={styles.topDiv}>
            <div className={styles.processHeader}>
              <div style={{ width: '100%' }}>
                <CustomTab
                  title='Annual Financial Statement Form'
                  withOutCount
                  isSelected
                />
              </div>
            </div>
          </div>
        </div>
        </div>
        <div>
          <div className={styles.formContainer}>
            <CustomFormGroup
              // header="Supplier Details --- > (1) Details of Tier-I are mandatory.  (2) '*' Import of services including royalty, as disclosed in form 15CA & 15CB of Income Tax Act/ Rules. "
              body={renderHTML()}
            />
          </div>
        </div>
        {flag && (
          <div
            className={styles.formGroup}
          // style={{ height: '86%', padding: '0 2em' }}
          >
            {/* <Table
              columns={[...baseDefaultColumns]}
              defaultColumns={[...baseDefaultColumns]}
              rowHeight={38}
              rows={tableArray}
              primaryAction={{
                name: 'Submit Data',
                authOperation: 'submit',
                shouldEnable: () => true,
                actionFn: handleOpenFormModal
              }}
              secondaryActions={[
                {
                  name: 'Fill AFS Form',
                  authOperation: 'manualForm',
                  shouldEnable: () => true,
                  actionFn: selected => {
                    handleOpenManualForm()
                  },
                  customClass: ''
                },
                {
                  name: 'Upload Data',
                  authOperation: 'upload',
                  shouldEnable: selected => true,
                  actionFn: selected => {
                    handleOpen()
                  },
                  customClass: ''
                },
                {
                  name: 'Attach AFS',
                  authOperation: 'uploadPdfDocument',
                  shouldEnable: () => true,
                  actionFn: selected => {
                    Uploadcertification()
                  },
                  customClass: ''
                }
                // {
                //   name: 'Download AFS Certificate',
                //   authOperation: 'downloadAfsCertificate',
                //   shouldEnable: () => true,
                //   actionFn: selected => {
                //     downloadAfsCertificate()
                //   },
                //   customClass: ''
                // }
              ]}
              setAsnCount={setCount}
              isDataLoading={loader}
              filterDownloadBtn
              filterDownload={exportExcel2}
              actionButtons
              onRowSelect={e => {}}
              removeRowSelection
            /> */}
            {tableArray !== undefined && (
              <Grid
                spacing={1}
                style={{
                  display: 'flex',
                  justifyContent: 'left',
                  // marginTop: '5px',
                  marginBottom: '1px'
                }}
                columns={12}
              >
                <AFSForm
                  vendorCode={Bc?.value}
                  supplierName={tableArray[0]?.NAME1}
                  pan={tableArray[0]?.PAN}
                  // tier={tableArray[0]?.Tier}
                  gstin={tableArray[0]?.GSTIN}
                  relatedVendorCode={relatedVendorCode}
                  relatedGstin={relatedGstin}
                  // formSubmitTrigger={formSubmitTrigger}
                  // manualDataUpload={manualDataUpload}
                  setManualFormData={setManualFormData}
                  manualFormData={manualFormData}
                  tableArray={tableArray}
                  handleOpenManualFormConfirmation={
                    handleOpenManualFormConfirmation
                  }
                  iecChecked={iecChecked}
                  setIecChecked={setIecChecked}
                  CheckOverallStatusAPI={CheckOverallStatusAPI}
                  editableByUser={editableByUser}
                  buttonStatusData={buttonStatusData}
                  financialYear={financialYear}
                  setAcceptedFiles1={setAcceptedFiles1}
                  acceptedFiles1={acceptedFiles1}
                  totalImportInLastFY={totalImportInLastFY}
                  totalEstimatedImportAttributableApplicant={totalEstimatedImportAttributableApplicant}
                  avgParsedPPE={avgParsedPPE}
                  calcRevenueToPPERatio={calcRevenueToPPERatio}
                  getStatusDataForButtonAPIView={getStatusDataForButtonAPIView}
                />
              </Grid>
            )}
            <Grid
              spacing={1}
              style={{
                display: 'flex',
                justifyContent: 'right',
                // marginTop: '5px',
                marginBottom: '1px'
              }}
              columns={12}
            >
              <Grid>
                {/* <Button
                className={clsx(
                  styles.actionButton,
                  styles.primaryActionButton
                )}
                variant='primary'
                onClick={() => {
                  if (!Bc?.value) {
                    alert('Please select vendor!')
                  } else {
                    showTable()
                  }
                }}
              >
                Submit
              </Button> */}
              </Grid>
            </Grid>
          </div>
        )}
      </div>
      {/* <Button
            className={clsx(
              styles.actionButton,
              styles.primaryActionButton,
            )}
            variant="primary"
            // onClick={onDelete}
          >
            Yes
          </Button> */}
      <ResetModalF
        isPopupOpen={isPopupOpenFormModal}
        handleCloseFormModal={handleCloseFormModal}
        goNextForm={goNextForm}
      />

      <ResetModal
        isPopupOpen={isPopupOpen}
        handleClose={handleClose}
        goNext={goNext}
      />

      <Dialog
        open={isManualFormConfirmationBox}
        className={styles.popContainer}
        classes={{
          paper: styles.popupBox
        }}
        data-testid='reset-popup'
      >
        <>
          <DialogTitle>
            <span className={styles.title}>
              <WarningIcon style={{ height: '18px', width: '18px' }} />
              <span style={{ marginLeft: '8px' }} className={styles.txt}>
                Confirm {userRole?.includes('TMLBSL') || userRole?.includes('Purchase') ? 'Validation' : 'Submission'}
              </span>
            </span>
          </DialogTitle>
          <DialogContent className={styles.content}>
            {/* <div>
              <Checkbox
                size='small'
                checked={financialDetails}
                onChange={event =>
                  handleAFSDetailsCheckbox(event, setFinancialDetails)
                }
              />
              Financial Details
            </div>
            <div>
              <Checkbox
                size='small'
                checked={auditedFinancialStatement}
                onChange={event =>
                  handleAFSDetailsCheckbox(event, setAuditedFinancialStatement)
                }
              />
              Audited Financial Statement
            </div> */}
            <div>
              {/* <p> On confirmation, All existing data will be overwritten by the uploaded data.</p> */}
              <p style={{ margin: '0px' }}>
                {/* On confirmation, AFS form data will be submitted. */}
                {/* I am hereby submitting the Annual Financial Statement (AFS) details along with the required attachment as per my best knowledge. */}

                {userRole?.includes('admin') || userRole?.includes('Vendor') ? 'I, hereby confirm that details submitted by me are correct to the best of my knowledge.' : 'Are you sure you want to proceed with validation?'}
              </p>
              {/* <p style={{ margin: '5px 0px 0px 0px' }}>Are you sure ?</p> */}
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              className={clsx(styles.actionButton, styles.transparentButton)}
              onClick={handleCloseManualFormConfirmation}
            >
              Cancel
            </Button>
            <Button
              className={clsx(styles.actionButton, styles.primaryActionButton)}
              variant='primary'
              onClick={e => {
                e.preventDefault()
                manualDataUpload()
                handleCloseManualFormConfirmation()
                // handleCloseFormModal()
                // goNextForm()
              }}
            // eslint-disable-next-line no-unneeded-ternary
            // disabled={ financialDetails && auditedFinancialStatement ? false : true }
            >
              Yes
            </Button>
          </DialogActions>
        </>
      </Dialog>

      {/* <ManualForm 
        isOpenManualForm={isOpenManualForm}
        handleCloseManualForm={handleCloseManualForm}
        manualFormPost={ManualDataUpload}
      /> */}

      <Dialog
        open={isOpenManualForm}
        // className={styles.manualFormPopupContainer}
        // classes={{
        //   paper: styles.manualFormPopupBox,
        // }}
        classes={{ paper: classes.customDialog }}
        fullWidth
      >
        <>
          <DialogTitle>
            <span>
              <span
              // className={styles.txt}
              >
                Supplier Import Details
              </span>
            </span>
          </DialogTitle>
          <DialogContent
          // className={styles.content}
          >
            {/* <AFSForm
              vendorCode={Bc?.value}
              supplierName={tableArray[0]?.NAME1}
              pan={tableArray[0]?.PAN}
              tier={tableArray[0]?.Tier}
              gstin={tableArray[0]?.GSTIN}
              // formSubmitTrigger={formSubmitTrigger}
              // manualDataUpload={manualDataUpload}
              setManualFormData={setManualFormData}
              manualFormData={manualFormData}
              // myState={myState}
            /> */}
            {/* <CustomFormGroup body={renderOther()} /> */}
          </DialogContent>
          <DialogActions>
            <Button
              className={clsx(styles.actionButton, styles.transparentButton)}
              onClick={handleCloseManualForm}
            >
              Cancel
            </Button>
            <Button
              className={clsx(styles.actionButton, styles.primaryActionButton)}
              variant='primary'
              onClick={e => {
                e.preventDefault()
                handleOpenManualFormConfirmation()
                // setFormSubmitTrigger(true)
                // ManualDataUpload()
                // manualDataUpload()
                // handleClose();
                // goNext();
              }}
            >
              Submit
            </Button>
          </DialogActions>
        </>
      </Dialog>
    </>
  )
}
function ResetModalF ({ isPopupOpen, handleCloseFormModal, goNextForm }) {
  return (
    <Dialog
      open={isPopupOpen}
      className={styles.popContainer}
      classes={{
        paper: styles.popupBox
      }}
      data-testid='reset-popup'
    >
      <>
        <DialogTitle>
          <span className={styles.title}>
            <WarningIcon style={{ height: '18px', width: '18px' }} />
            <span style={{ marginLeft: '8px' }} className={styles.txt}>
              Confirm Submit
            </span>
          </span>
        </DialogTitle>
        <DialogContent className={styles.content}>
          <div>
            {/* <p> On confirmation, All existing data will be overwritten by the uploaded data.</p> */}
            <p>On confirmation ,VC BoM data will submit</p>

            <p>Are you sure ?</p>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            className={clsx(styles.actionButton, styles.transparentButton)}
            onClick={handleCloseFormModal}
          >
            Cancel
          </Button>
          <Button
            className={clsx(styles.actionButton, styles.primaryActionButton)}
            variant='primary'
            onClick={e => {
              e.preventDefault()
              handleCloseFormModal()
              goNextForm()
            }}
          >
            Yes
          </Button>
        </DialogActions>
      </>
    </Dialog>
  )
}
function ResetModal ({ isPopupOpen, handleClose, goNext }) {
  return (
    <Dialog
      open={isPopupOpen}
      className={styles.popContainer}
      classes={{
        paper: styles.popupBox
      }}
      data-testid='reset-popup'
    >
      <>
        <DialogTitle>
          <span className={styles.title}>
            <WarningIcon style={{ height: '18px', width: '18px' }} />
            <span style={{ marginLeft: '8px' }} className={styles.txt}>
              Confirm file Upload
            </span>
          </span>
        </DialogTitle>
        <DialogContent className={styles.content}>
          <div>
            <p> On confirmation ,Records will be appended.</p>
            <p>Are you sure ?</p>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            className={clsx(styles.actionButton, styles.transparentButton)}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            className={clsx(styles.actionButton, styles.primaryActionButton)}
            variant='primary'
            onClick={e => {
              e.preventDefault()
              handleClose()
              goNext()
            }}
          >
            Yes
          </Button>
        </DialogActions>
      </>
    </Dialog>
  )
}

ResetModalF.propTypes = {
  isPopupOpen: PropTypes.bool.isRequired,
  handleCloseFormModal: PropTypes.func.isRequired,
  goNextForm: PropTypes.func.isRequired
}

ResetModal.propTypes = {
  isPopupOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  goNext: PropTypes.func.isRequired
}

// ManualForm.propTypes = {
//   isPopupOpen: PropTypes.bool.isRequired,
//   handleCloseManualForm: PropTypes.func.isRequired,
//   // goNext: PropTypes.func.isRequired,
// };

export default withAllowedOperationsProvider(
  AnnualFinancialStatement,
  RESOURCE_TYPE.PROJECT
)


/* eslint-disable react/self-closing-comp */
/* eslint-disable vars-on-top */
/* eslint-disable eqeqeq */
/* eslint-disable prefer-const */
/* eslint-disable no-var */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-use-before-define */
/* eslint-disable array-callback-return */
/* eslint-disable camelcase */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-nested-ternary */
/* eslint-disable object-shorthand */


import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import {
  Modal,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Form,
  Box,
  TextField,
  Grid,
  FormGroup,
  IconButton
} from '@material-ui/core'
import WarningIcon from '@mui/icons-material/Warning'
import FormLabel from '@mui/material/FormLabel'
import * as FileSaver from 'file-saver'
import XLSX from 'sheetjs-style'
// for print user in consol
import jwt_decode from 'jwt-decode'
import { useSelector } from 'react-redux'
// for print user in consol
import axios from 'axios'

import clsx from 'clsx'
import { useHistory } from 'react-router-dom'

import styles from './AFSDataDashBoard.module.css'
import {
  CustomFormGroup,
  CustomSelect,
  ValidatingTextField
} from '../../components/FormComponents'
import BackButton from '../../components/BackButton/BackButton'
import {
  DATE_FORMAT,
  API_RESOURCE_URLS,
  DISPLAY_MESSAGES,
  MESSAGE_TYPE,
  MESSAGE_TYPE_CERTIFICATION,
  MESSAGE_TYPE_ANNEXURE,
  RESOURCE_TYPE,
  USER_OPERATIONS,
  Regex
} from '../../constants'
import { withAllowedOperationsProvider } from '../../hocs'
import { CustomTab, Button, AuthChecker } from '../../atomicComponents'
import { API } from '../../apis/api'
import { buildErrorMessage } from '../../apis/calls'
import { usePopupManager } from '../../providers/PopupManager/PopupManager'

import StepBar1 from '../../components/StepBar1/StepBar'
import { GridLoadingSpinner } from '../../components'
// import { error } from "jquery";

const AfsDashboard = () => {
  const history = useHistory()
  const { showPopup } = usePopupManager()
  // const baseUrl='http://127.0.0.1:8000/file/'
  const baseUrl = useSelector(state => state.counter.baseUrl) // useSelector
  const auth = useSelector(state => state.auth) // useSelector
  // console.log('auth in Supplier: ', auth);
  const { userRole, userID, userName, vendorcode } = auth
  console.log('userRole: ', userRole, userID, userName)
  const [dynamicColumns, setDynamicColumns] = useState([])
  const [dynamicColumns1, setDynamicColumns1] = useState([])
  const [plant, setPlant] = useState(null)
  const [flag, show] = useState(false)
  const [checkDisable, setDisable] = useState(true)
  const [Bc, setBC] = useState(null)
  const [projectCode, setProjectCode] = useState(null)
  const [bcArray, setBcArray] = useState([])
  const [plantArray, setPlantArray] = useState([])
  const [tableArray, setTableArray] = useState([])
  const [excelArray, setExcelArray] = useState([])
  const [asnCount, setAsnCount] = useState(0)
  const [Fy, setFY] = useState(null)
  const [fyArray, setFyArray] = useState([
    {
      value: '2023-24',
      label: '2023-24'
    }
  ])
  const [financialYear, setFinancialYear] = useState({
    value: '2023-24',
    label: '2023-24'
  })
  const [quarter, setQuarter] = useState(null)
  const [quarterArray, setQuarterArray] = useState([])
  const [projectCodeArray, setProjectCodeArray] = useState([])
  const [latestProject, setLatestProject] = useState('')
  const [selectedProjectCode, setSelectedProjectCode] = useState(null)
  const [recentProjectCodeDescription, setRecentProjectCodeDescription] =
    useState()
  const [dvaSubmissionStatus, setDVASubmissionStatus] = useState({})

  const [loading, setLoader] = useState(false)
  const [loader, setLoading] = useState(true)
  const [vendCode, setvendCode] = useState()
  const [datesData, setDates] = useState({
    endDate: '',
    startDate: ''
  })
  const [projectDescription, setProjectDescription] = useState('')

  const [show1, setShow1] = useState(false);
  const [activeTable, setActiveTable] = useState(null);
  const [isDataView, setIsDataView] = useState(true);

  const [isPopupOpenFormModal, setPopupOpenFormModal] = useState(false)
  const handleOpenFormModal = () => {
    setPopupOpenFormModal(true)
  }
  const handleCloseFormModal = () => setPopupOpenFormModal(false)

  //

  const ROW_HEIGHT = 38
  const [isValidatePopupOpen, setIsValidatePopupOpen] = useState(false)
  const handleOpenValidatePopup = () => {
    setIsValidatePopupOpen(true)
  }
  const handleCloseValidatePopup = () => {
    setIsValidatePopupOpen(false)
  }

  const [isSubmissionPopupOpen, setIsSubmissionPopupOpen] = useState(false)
  const handleOpenSubmissionPopup = () => {
    setIsSubmissionPopupOpen(true)
  }
  const handleCloseSubmissionPopup = () => {
    setIsSubmissionPopupOpen(false)
  }

  const [isPopupOpen, setIsPopupOpen] = useState(false)
  // const handleOpen = () => setIsPopupOpen(true)
  const handleClose = () => setIsPopupOpen(false)
  // const goNext = () => {
  //   uploadFileData()
  // }

  const redirectToApplicationLandingPage = () => {
    history.push('/')
  }

  const setCount = count => {
    setAsnCount(count)
  }
  // SubmitStatusUpdate

  const isAdminUser = userRole?.includes('admin')
  const isGDCUser = userRole?.includes('TMLBSL')
  const isPurchaseUser = userRole?.includes('Purchase')
  const isVendorUser = userRole?.includes('Vendor')
  const isPMTUser = userRole?.includes('PMT')
  const isProductLineUser = userRole?.includes('Product-line')
  const isBOEUser = userRole?.includes('BOE')

  const vendorUserID = isVendorUser && userID.toUpperCase()

  // useEffect(() => {
  //   // getData()
  //   getRecentProjectCode()
  //   getAllProjectCode()
  //   // getdate();
  //   //  Uploadcertification();
  // }, [])




  const apiBody = {
    Project_code: projectCode?.value,
    Supplier_code: Bc?.value,
    // Plant_code: plant?.value,
    Po_Plant: plant?.value,
    Financial_year: Fy?.value,
    Quarter: quarter?.value
  }

  useEffect(() => { }, [bcArray])
  console.log('Supplier BC: ', Bc)


  //  const showTable = () => {
  //     // getTableData()
  //     console.log('financialYear', financialYear);
  //     getDataByYear()
  //     getDataByYear1()
  //     show(true)
  //   }
  //   const showTable1 = () => {
  //     // getTableData()
  //     console.log('financialYear', financialYear);
  //     getDataByYear1()
  //     show(true)
  //   }

  const showTable = () => {
    console.log('financialYear', financialYear);
    setTableArray([]);
    getDataByYear();
    setActiveTable('data');
    setIsDataView(true);
    setShow1(true);
  };

  // const showTable1 = () => {
  //   console.log('financialYear', financialYear);
  //   getDataByYear1();
  //   setActiveTable('list');
  //   setIsDataView(false);
  //   setShow1(true);
  // };
  const showTable1 = () => {
    // Clear existing table data to avoid conflicts
    setTableArray([]);

    // Fetch new data and update the table
    getDataByYear1();
    setActiveTable('list');
    setIsDataView(false);
    setShow1(true);
  };
  const handleSelect = (selectedOption, name) => {
    console.log('selectedOption', selectedOption);

    if (name == 'FY') {
      show(false)
      setFinancialYear(selectedOption)
      console.log('inside FY select');
      // getDataByYear()
    }
  }
  const getProjectDescription = async () => {
    try {
      const headerss = { 'Content-Type': 'application/json' }
      const body = { Project_code: selectedProjectCode?.value }
      const { data } = await axios.post(
        `${baseUrl}ProjectDescriptionView/`,
        body,
        { headers: headerss }
      )
      // console.log('pro desc: ', data)
      setProjectDescription(data?.Project_Description)
    } catch (error) {
      console.error('Error fetching project description:', error)
    }
  }

  const getData = recentProj => {
    let headerss = new Headers()
    headerss.append('Content-Type', 'application/json')
    // const url = auth.userRole[0] === 'Vendor' ? 'SupplierDvaCollection' : 'SupplierDvaCollectionAdminGDC'
    const url =
      auth.userRole[0] === 'Vendor'
        ? 'ShowVendorData'
        : 'SupplierDvaCollectionAdminGDCshow'
    const formattedBody = {
      // Project_code: latestProject?.value
      Project_code: recentProj?.value
    }
    // fetch(`${baseUrl}SupplierDvaCollection/`, {
    fetch(`${baseUrl}LoadVendorcodeandplantwrtprojectcode/`, {
      method: 'POST',
      headers: headerss,
      body: JSON.stringify(formattedBody)
      // body: formattedBody
    })
      .then(response => response.json())
      .then(responseJson => {
        console.log('responseJson: ', responseJson)
        // Extract unique values for different fields
        const uniqueSuppliers = [
          ...new Set(responseJson.map(item => item.Supplier_code))
        ].filter(Boolean)
        // const uniquePlants = [...new Set(responseJson.map(item => item.Plant_code))].filter(Boolean);
        const uniquePlants = [
          ...new Set(responseJson.map(item => item.Po_Plant))
        ].filter(Boolean)
        const uniqueQuarters = [
          ...new Set(responseJson.map(item => item.Quarter))
        ].filter(Boolean)
        const uniqueYears = [
          ...new Set(responseJson.map(item => item.Financial_year))
        ].filter(Boolean)

        // Extract unique values for different fields
        // const uniqueSuppliers = [...new Set(responseJson.map(item => item.Supplier_Code))];
        // const uniqueProjects = [...new Set(responseJson.map(item => item.Project_code))];
        // const uniquePlants = [...new Set(responseJson.map(item => item.Plant_code))];
        // const uniqueQuarters = [...new Set(responseJson.map(item => item.Quarter))];
        // const uniqueYears = [...new Set(responseJson.map(item => item.Financial_year))];
        console.log('Filtered response1: ', uniqueSuppliers)
        console.log('Filtered response2: ', uniquePlants)
        console.log('Filtered response3: ', uniqueQuarters)
        console.log('Filtered response4: ', uniqueYears)

        let temp = []
        if (bcArray.length === 0) {
          console.log(
            'MApping vendor: ',
            uniqueSuppliers.map(value => ({ value, label: value }))
          )
          temp = uniqueSuppliers.map(value => ({ value, label: value }))
          // setBcArray(uniqueSuppliers.map(value => ({ value, label: value })))
        }
        console.log('Mapping bcArray:', bcArray, 'temp: ', temp)
        // setProjectCodeArray(uniqueProjects.map(value => ({ value, label: value })));
        console.log(projectCodeArray)
        setPlantArray(uniquePlants.map(value => ({ value, label: value })))
        setQuarterArray(uniqueQuarters.map(value => ({ value, label: value })))
        setFyArray(uniqueYears.map(value => ({ value, label: value })))

        // Extract userRole and userID from auth object
        // const { userRole, userID } = auth;
        console.log(
          'User Role:',
          userRole,
          'User ID:',
          userID,
          'vendorID: ',
          vendorUserID
        )

        // Check user role to conditionally set BC state
        if (userRole.includes('Vendor-CV') || userRole.includes('Vendor')) {
          console.log('bcArray: ', bcArray)

          const singleVendor = bcArray.find(item => item.value === userID)
          const singleVendor1 = temp.find(item => item.value === userID)
          console.log('singleVendor: ', singleVendor, singleVendor1)

          if (singleVendor1) {
            setBC(singleVendor1)
            setDisable(true) // Disable UI elements
          }
        } else {
          setDisable(false) // Enable UI elements
        }
      })
      .catch(error => {
        console.error('Error fetching data:', error)
        // Handle error (e.g., show error message)
      })
  }

  const getVcForProjectCode = async selectedCode => {
    console.log('selectedCode: ', selectedCode)
    // console.log('selectedCode ?.Project_code: ', selectedCode?.Project_code)
    console.log('selectedProjectCode', selectedProjectCode)

    const headerss = {
      'Content-Type': 'application/json'
    }

    const apiBody1 = {
      // project_code: selectedProjectCode?.value || selectedCode?.value,
      Project_code: selectedProjectCode?.value || selectedCode?.value
      // SPOC: preferredUsername
    }
    // console.log('PL body: ', apiBody1, preferredUsername)

    try {
      const response = await axios.post(
        // `${baseUrl}GetVCsByProject/`,
        // `${baseUrl}GetVCsByProjectAndSPOCForPL/`,
        `${baseUrl}GetVCsByProject/`,
        apiBody1,
        { headers: headerss }
      )

      if (response.status >= 200 && response.status < 300) {
        const resArray = response.data?.map(value => ({ value, label: value }))
        console.log('resArray: ', resArray)
        setBcArray(resArray)
      } else {
        console.error(
          `Error fetching VC: ${response.status} - ${response.statusText}`
        )
        showPopup({
          type: MESSAGE_TYPE.FAILURE,
          contextText: MESSAGE_TYPE.FAILURE,
          info: `Failed to fetch data. Error: ${response.status} - ${response.statusText}`
        })
      }
    } catch (error) {
      if (error.response) {
        // Server responded with a status other than 2xx
        console.error(
          `Error fetching VC: ${error.response.status} - ${error.response.data}`
        )
        showPopup({
          type: MESSAGE_TYPE.FAILURE,
          contextText: MESSAGE_TYPE.FAILURE,
          info: `Failed to fetch data. Error: ${error.response.status} - ${error.response.data}`
        })
      } else if (error.request) {
        // Request was made but no response was received
        console.error('Error fetching VC: No response received', error.request)
        showPopup({
          type: MESSAGE_TYPE.FAILURE,
          contextText: MESSAGE_TYPE.FAILURE,
          info: 'Failed to fetch data. No response received from the server.'
        })
      } else {
        // Something else happened in setting up the request
        console.error('Error fetching VC:', error.message)
        showPopup({
          type: MESSAGE_TYPE.FAILURE,
          contextText: MESSAGE_TYPE.FAILURE,
          info: `Failed to fetch data. Error: ${error.message}`
        })
      }
    }
  }


  // const exportExcel = (tableArray1) => {
  //   // Define file type and extension
  //   const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  //   const fileExtension = ".xlsx";

  //   // Map data to match table format
  //   const newExcelData = tableArray1?.map(
  //     ({
  //       commodity,
  //       Number_of_Suppliers,
  //       AFS_and_PPE_Received,
  //       AFS_and_PPE_Pending,
  //     }) => ({
  //       Commodity: commodity, // Table column headers
  //       "Number of Suppliers": Number_of_Suppliers,
  //       "AFS and PPE Received": AFS_and_PPE_Received,
  //       "AFS and PPE Pending": AFS_and_PPE_Pending,
  //     })
  //   );

  //   // Create worksheet and workbook
  //   const ws = XLSX.utils.json_to_sheet(newExcelData);
  //   const wb = { Sheets: { data: ws }, SheetNames: ["data"] };

  //   // Write workbook to buffer
  //   const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });

  //   // Create Blob and trigger download
  //   const data = new Blob([excelBuffer], { type: fileType });
  //   FileSaver.saveAs(data, `AFSDashboard_${new Date().toISOString().slice(0, 10)}${fileExtension}`);
  // };

  const exportExcel = (dataArray, isViewData) => {
    const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    // Map data based on the active view
    const newExcelData = dataArray?.map((row) => {
      if (isViewData) {
        const { commodity, Number_of_Suppliers, AFS_and_PPE_Received, AFS_and_PPE_Pending } = row;
        return {
          Commodity: commodity,
          "Number of Suppliers": Number_of_Suppliers,
          "AFS and PPE Received": AFS_and_PPE_Received,
          "AFS and PPE Pending": AFS_and_PPE_Pending,
        };
      }
      // Export for "Show List"
      const { commodity, Vendor_Code, NAME1, Supplier_Status } = row;
      return {
        Commodity: commodity,
        "Vendor Code": Vendor_Code,
        Name: NAME1,
        "Supplier Status": Supplier_Status,
      };
    });

    // Create worksheet and workbook
    const ws = XLSX.utils.json_to_sheet(newExcelData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };

    // Write workbook to buffer
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });

    // Create Blob and trigger download
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, `AFSDashboard_${new Date().toISOString().slice(0, 10)}${fileExtension}`);
  };

  const renderHTML = () => (
    <div className={styles.formGroup}>
      {loading && (
        <div className={styles.gridLoader}>
          <GridLoadingSpinner />
        </div>
      )}

      <Grid
        container
        spacing={2}
        style={{ display: 'flex', justifyContent: 'space-between' }}
        columns={12}
      >

        <Grid
          item
          // sm={12}
          md={4}
          lg={4}
          // style={{ marginTop: '5px', marginBottom: '5px' }}
          className={clsx(styles.flexCenter, styles.alignCenter)}
        >
          <div className={styles.rowDiv}>
            <div
              className={clsx(styles.lableDiv)}
              style={{ width: '40%', display: 'flex', alignItems: 'center' }}
            >
              <label className={styles.label}>Financial Year</label>
            </div>
            <div className={styles.formDiv}>
              <CustomSelect
                name='Fy'
                // options={fyArray}
                options={[
                  { value: '2024-25', label: '2024-25' },
                  { value: '2023-24', label: '2023-24' },
                  { value: '2022-23', label: '2022-23' }
                ]}
                // isDisabled={checkDisable}
                className={clsx(styles.select, styles.sel1)}
                value={financialYear}
                defaultValue={{
                  value: '2023-24',
                  label: '2023-24'
                }}
                isMulti={false}
                // isMandatory = {auth.userRole === ''}
                isClearable
                onChange={selectedOption => {
                  // handleSelect(e, "VC");
                  setFinancialYear(selectedOption)
                }}
              />
            </div>
          </div>
        </Grid>
        <Grid
          item
          md={8}
          lg={8}
          sm={8}
        // style={{ marginTop: '5px', marginBottom: '5px' }}
        >
          <div
            style={{
              marginLeft: '20px',
              marginTop: '1px',
              justifyContent: 'center',
              display: 'flex'
            }}
          >
            <AuthChecker operation="search">
              {isAuthorized => (
                <Button
                  className={clsx(styles.actionButton, styles.primaryActionButton)}
                  variant="primary"
                  onClick={showTable}
                  disabled={!isAuthorized}
                >
                  AFS Summary
                </Button>
              )}
            </AuthChecker>

            <AuthChecker operation="search">
              {isAuthorized => (
                <Button
                  className={clsx(styles.actionButton, styles.primaryActionButton)}
                  variant="primary"
                  style={{ marginLeft: '10px' }}
                  onClick={showTable1}
                  disabled={!isAuthorized}
                >
                  AFS Details
                </Button>
              )}
            </AuthChecker>

            {/* <AuthChecker operation='search'>
              {isAuthorized => (
                <Button
                  className={clsx(
                    styles.actionButton,
                    styles.primaryActionButton
                  )}
                  variant='primary'
                  //   onClick={() => runPreSubmissionChecks() && handleSubmit()}
                  onClick={() => exportExcel(tableArray)}
                  disabled={!isAuthorized}
                  data-testid='confirm-action'
                  style={{ marginLeft: '5px' }}
                >
                  Download
                </Button>
              )}
            </AuthChecker> */}
            <AuthChecker operation="search">
              {isAuthorized => (
                <Button
                  className={clsx(styles.actionButton, styles.primaryActionButton)}
                  variant="primary"
                  onClick={() => exportExcel(tableArray, isDataView)} // Pass the active view state
                  disabled={!isAuthorized}
                  style={{ marginLeft: "10px" }}
                >
                  Download
                </Button>
              )}
            </AuthChecker>
          </div>
        </Grid>


      </Grid>
    </div>
  )

  const getDataByYear = () => {
    const apiFormattedData = {
      financial_year: financialYear?.value,
    };
    const apiFormattedDataJson = JSON.stringify(apiFormattedData);

    let headers = new Headers();
    headers.append('Content-Type', 'application/json');

    fetch(`${baseUrl}AFSDashboardCV/`, {
      method: 'POST',
      headers: headers,
      body: apiFormattedDataJson,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log('responseJson:', responseJson);
        if (responseJson?.error) {
          showPopup({
            type: MESSAGE_TYPE.FAILURE,
            contextText: MESSAGE_TYPE.FAILURE,
            info: responseJson?.error,
          });
        } else {
          const tableData = responseJson || []; // Handle null or undefined response
          console.log('Mapped tableData:', tableData);

          // Map data to the expected table format
          const mappedTableData = tableData.map(
            ({
              commodity,
              Number_of_Suppliers,
              AFS_and_PPE_Received,
              AFS_and_PPE_Pending,
              Total_Number_of_Suppliers,
              Total_AFS_and_PPE_Received,
              Total_AFS_and_PPE_Pending,
            }, id) => ({
              id,
              commodity: commodity || (id === tableData.length - 1 ? 'Grand Total' : ' '),
              Number_of_Suppliers: Number_of_Suppliers ?? Total_Number_of_Suppliers ?? 0,
              AFS_and_PPE_Received: AFS_and_PPE_Received ?? Total_AFS_and_PPE_Received ?? 0,
              AFS_and_PPE_Pending: AFS_and_PPE_Pending ?? Total_AFS_and_PPE_Pending ?? 0,
            })
          );

          // Update the table's data source
          setTableArray(mappedTableData);
        }
      })
      .catch((error) => {
        console.log('Error in getDataByYear:', error);
      });
  };



  const getDataByYear1 = () => {
    const apiFormatedData = {
      financial_year: financialYear?.value,
    };
    const apiFormatedDataJson = JSON.stringify(apiFormatedData);

    let headerss = new Headers();
    headerss.append("Content-Type", "application/json");

    fetch(`${baseUrl}AFSListDashboardCV/`, {
      method: "POST",
      headers: headerss,
      body: apiFormatedDataJson,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log("responseJson:", responseJson);

        if (responseJson?.error) {
          showPopup({
            type: MESSAGE_TYPE.FAILURE,
            contextText: MESSAGE_TYPE.FAILURE,
            info: responseJson?.error,
          });
        } else {
          const tableData = responseJson || [];

          const excelData = tableData.map(
            ({ Vendor_Code, NAME1, commodity, Supplier_Status }, id) => ({
              id,
              Vendor_Code,
              NAME1: NAME1 || "-",
              commodity: commodity || "-",
              Supplier_Status: Supplier_Status || "-",
            })
          );

          setTableArray(excelData);
        }
      })
      .catch((error) => {
        console.error("Error in getDataByYear:", error);
        showPopup({
          type: MESSAGE_TYPE.FAILURE,
          contextText: "Error in API call",
          info: error.message,
        });
      });
  };


  // const renderOther = () => (
  //   <div style={{ margin: '20px' }}>

  //     {/* Table Header */}
  //     <Grid container spacing={2} style={{ borderBottom: '2px solid #ccc', paddingBottom: '10px' }}>
  //       <Grid item xs={3} style={{ textAlign: 'center', fontWeight: 'bold' }}>
  //         <label className={styles.label}>Commodity</label>
  //       </Grid>
  //       <Grid item xs={3} style={{ textAlign: 'center', fontWeight: 'bold' }}>
  //         <label className={styles.label}>Number of Suppliers</label>
  //       </Grid>
  //       <Grid item xs={3} style={{ textAlign: 'center', fontWeight: 'bold' }}>
  //         <label className={styles.label}>AFS and PPE Received</label>
  //       </Grid>
  //       <Grid item xs={3} style={{ textAlign: 'center', fontWeight: 'bold' }}>
  //         <label className={styles.label}>AFS and PPE Pending</label>
  //       </Grid>
  //     </Grid>

  //     {/* Table Data */}
  //     {tableArray?.map((row, index) => (
  //       <Grid
  //         key={`${row.commodity}-${row.Number_of_Suppliers}-${row.AFS_and_PPE_Received}`}
  //         container
  //         spacing={2}
  //         style={{
  //           borderBottom: row.commodity === 'Grand Total' ? 'none' : '1px solid #ccc',
  //           padding: '10px 0',
  //           backgroundColor: row.commodity === 'Grand Total' ? '#f9f9f9' : 'transparent',
  //           borderTop: row.commodity === 'Grand Total' ? '2px solid #2e75b5' : 'none',
  //         }}
  //       >
  //         <Grid
  //           item
  //           xs={3}
  //           style={{
  //             textAlign: 'center',
  //             fontWeight: row.commodity === 'Grand Total' ? 'bold' : 'normal',
  //             color: row.commodity === 'Grand Total' ? '#4E4E4E' : 'inherit', 
  //           }}
  //         >
  //           {row.commodity}
  //         </Grid>
  //         <Grid
  //           item
  //           xs={3}
  //           style={{
  //             textAlign: 'center',
  //             fontWeight: row.commodity === 'Grand Total' ? 'bold' : 'normal',
  //             color: row.commodity === 'Grand Total' ? '#4E4E4E' : 'inherit', 
  //           }}
  //         >
  //           {row.Number_of_Suppliers}
  //         </Grid>
  //         <Grid
  //           item
  //           xs={3}
  //           style={{
  //             textAlign: 'center',
  //             fontWeight: row.commodity === 'Grand Total' ? 'bold' : 'normal',
  //             color: row.commodity === 'Grand Total' ? '#4E4E4E' : 'inherit', 
  //           }}
  //         >
  //           {row.AFS_and_PPE_Received}
  //         </Grid>
  //         <Grid
  //           item
  //           xs={3}
  //           style={{
  //             textAlign: 'center',
  //             fontWeight: row.commodity === 'Grand Total' ? 'bold' : 'normal',
  //             color: row.commodity === 'Grand Total' ? '#4E4E4E' : 'inherit', 
  //           }}
  //         >
  //           {row.AFS_and_PPE_Pending}
  //         </Grid>
  //       </Grid>
  //     ))}
  //   </div>
  // );


  // const renderOther = () => (
  //   <div style={{ margin: '20px' }}>

  //     <div
  //   style={{
  //     maxHeight: '450px', 
  //     overflowY: 'scroll', 
  //     overflowX: 'hidden', 
  //     border: '1px solid #ccc', 
  //     borderRadius: '5px', 
  //     scrollbarWidth: 'thin', 
  //     scrollbarColor: '#2e75b5 #f5f5f5', 
  //   }}
  // >

  //       <Grid container spacing={2} style={{ borderBottom: '2px solid #ccc', paddingBottom: '10px', paddingTop: '10px' }}>
  //         <Grid item xs={3} style={{ textAlign: 'center', fontWeight: 'bold' }}>
  //           <label className={styles.label}>Commodity</label>
  //         </Grid>
  //         <Grid item xs={3} style={{ textAlign: 'center', fontWeight: 'bold' }}>
  //           <label className={styles.label}>Number of Suppliers</label>
  //         </Grid>
  //         <Grid item xs={3} style={{ textAlign: 'center', fontWeight: 'bold' }}>
  //           <label className={styles.label}>AFS and PPE Received</label>
  //         </Grid>
  //         <Grid item xs={3} style={{ textAlign: 'center', fontWeight: 'bold' }}>
  //           <label className={styles.label}>AFS and PPE Pending</label>
  //         </Grid>
  //       </Grid>


  //       {tableArray?.map((row, index) => (
  //         <Grid
  //           key={`${row.commodity}-${row.Number_of_Suppliers}-${row.AFS_and_PPE_Received}`}
  //           container
  //           spacing={2}
  //           style={{
  //             borderBottom: row.commodity === 'Grand Total' ? 'none' : '1px solid #ccc',
  //             padding: '10px 0',
  //             backgroundColor: row.commodity === 'Grand Total' ? '#f9f9f9' : 'transparent',
  //             borderTop: row.commodity === 'Grand Total' ? '2px solid #2e75b5' : 'none',
  //           }}
  //         >
  //           <Grid
  //             item
  //             xs={3}
  //             style={{
  //               textAlign: 'center',
  //               fontWeight: row.commodity === 'Grand Total' ? 'bold' : 'normal',
  //               color: row.commodity === 'Grand Total' ? '#4E4E4E' : 'inherit',
  //             }}
  //           >
  //             {row.commodity}
  //           </Grid>
  //           <Grid
  //             item
  //             xs={3}
  //             style={{
  //               textAlign: 'center',
  //               fontWeight: row.commodity === 'Grand Total' ? 'bold' : 'normal',
  //               color: row.commodity === 'Grand Total' ? '#4E4E4E' : 'inherit',
  //             }}
  //           >
  //             {row.Number_of_Suppliers}
  //           </Grid>
  //           <Grid
  //             item
  //             xs={3}
  //             style={{
  //               textAlign: 'center',
  //               fontWeight: row.commodity === 'Grand Total' ? 'bold' : 'normal',
  //               color: row.commodity === 'Grand Total' ? '#4E4E4E' : 'inherit',
  //             }}
  //           >
  //             {row.AFS_and_PPE_Received}
  //           </Grid>
  //           <Grid
  //             item
  //             xs={3}
  //             style={{
  //               textAlign: 'center',
  //               fontWeight: row.commodity === 'Grand Total' ? 'bold' : 'normal',
  //               color: row.commodity === 'Grand Total' ? '#4E4E4E' : 'inherit',
  //             }}
  //           >
  //             {row.AFS_and_PPE_Pending}
  //           </Grid>
  //         </Grid>
  //       ))}
  //     </div>
  //   </div>
  // );


  const renderOther = () => (
    <div style={{ margin: '0px', border: '1px solid #ccc', borderRadius: '5px', overflow: 'hidden' }}>
      {/* Header */}
      <div
        style={{
          borderBottom: '2px solid #2e75b5',
          padding: '10px 0',
          backgroundColor: '#f9f9f9',
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={1} style={{ textAlign: 'center', fontWeight: 'bold' }}>
            <label className={styles.label}>Sr No</label>
          </Grid>
          <Grid item xs={3} style={{ textAlign: 'center', fontWeight: 'bold' }}>
            <label className={styles.label}>Commodity</label>
          </Grid>
          <Grid item xs={2} style={{ textAlign: 'center', fontWeight: 'bold' }}>
            <label className={styles.label}>Number of Suppliers</label>
          </Grid>
          <Grid item xs={3} style={{ textAlign: 'center', fontWeight: 'bold' }}>
            <label className={styles.label}>AFS and PPE Received</label>
          </Grid>
          <Grid item xs={3} style={{ textAlign: 'center', fontWeight: 'bold' }}>
            <label className={styles.label}>AFS and PPE Pending</label>
          </Grid>
        </Grid>
      </div>
  
      {/* Scrollable Middle Section */}
      <div
        style={{
          maxHeight: '350px',
          overflowY: 'auto',
          overflowX: 'hidden',
          scrollbarWidth: 'thin',
          scrollbarColor: '#2e75b5 #f5f5f5',
        }}
      >
        <style>
          {`
          /* Custom Scrollbar for Webkit browsers */
          div::-webkit-scrollbar {
            width: 8px;
          }
          div::-webkit-scrollbar-track {
            background: #f5f5f5;
            border-radius: 10px;
          }
          div::-webkit-scrollbar-thumb {
            background: #2e75b5;
            border-radius: 10px;
          }
          div::-webkit-scrollbar-thumb:hover {
            background: #1d5a92;
          }
        `}
        </style>
        {tableArray
          ?.filter((row) => row.commodity !== 'Grand Total')
          .map((row, index) => (
            <Grid
              key={`${row.commodity}-${row.Number_of_Suppliers}-${row.AFS_and_PPE_Received}`}
              container
              spacing={2}
              style={{
                borderBottom: '1px solid #ccc',
                padding: '7px 0',
              }}
            >
              <Grid item xs={1} style={{ textAlign: 'center' }}>
                <label className={styles.label1}>{index + 1}</label>
              </Grid>
              <Grid item xs={3} style={{ textAlign: 'center' }}>
                <label className={styles.label1}>{row.commodity}</label>
              </Grid>
              <Grid item xs={2} style={{ textAlign: 'center' }}>
                <label className={styles.label1}>{row.Number_of_Suppliers}</label>
              </Grid>
              <Grid item xs={3} style={{ textAlign: 'center' }}>
                <label className={styles.label1}>{row.AFS_and_PPE_Received}</label>
              </Grid>
              <Grid item xs={3} style={{ textAlign: 'center' }}>
                <label className={styles.label1}>{row.AFS_and_PPE_Pending}</label>
              </Grid>
            </Grid>
          ))}
      </div>
  
      {/* Footer */}
      <div
        style={{
          borderTop: '2px solid #2e75b5',
          padding: '10px 0',
          backgroundColor: '#f9f9f9',
        }}
      >
        {tableArray
          ?.filter((row) => row.commodity === 'Grand Total')
          .map((row, index) => (
            <Grid
              key={`${row.commodity}-${row.Number_of_Suppliers}-${row.AFS_and_PPE_Received}`}
              container
              spacing={2}
              style={{
                fontWeight: 'bold',
                color: '#4E4E4E',
              }}
            >
              <Grid item xs={1} style={{ textAlign: 'center' }}>
                {/* Empty cell for Sr No */}
              </Grid>
              <Grid item xs={3} style={{ textAlign: 'center' }}>
                {row.commodity}
              </Grid>
              <Grid item xs={2} style={{ textAlign: 'center' }}>
                {row.Number_of_Suppliers}
              </Grid>
              <Grid item xs={3} style={{ textAlign: 'center' }}>
                {row.AFS_and_PPE_Received}
              </Grid>
              <Grid item xs={3} style={{ textAlign: 'center' }}>
                {row.AFS_and_PPE_Pending}
              </Grid>
            </Grid>
          ))}
      </div>
    </div>
  );
  
  // const renderList = () => (
  //   <div style={{ margin: '0px', border: '1px solid #ccc', borderRadius: '5px', overflow: 'hidden' }}>
  //     {/* Header */}
  //     <div
  //       style={{
  //         borderBottom: '2px solid #2e75b5',
  //         padding: '10px 0',
  //         backgroundColor: '#f9f9f9',
  //       }}
  //     >
  //       <Grid container spacing={2}>
  //         <Grid item xs={3} style={{ textAlign: 'center', fontWeight: 'bold' }}>
  //           <label className={styles.label}>Commodity</label>
  //         </Grid>
  //         <Grid item xs={3} style={{ textAlign: 'center', fontWeight: 'bold' }}>
  //           <label className={styles.label}>Vendor Code</label>
  //         </Grid>
  //         <Grid item xs={3} style={{ textAlign: 'center', fontWeight: 'bold' }}>
  //           <label className={styles.label}>Name</label>
  //         </Grid>
  //         <Grid item xs={3} style={{ textAlign: 'center', fontWeight: 'bold' }}>
  //           <label className={styles.label}>Supplier Status</label>
  //         </Grid>
  //       </Grid>
  //     </div>

  //     {/* Scrollable Middle Section */}
  //     <div
  //       style={{
  //         maxHeight: '400px',
  //         overflowY: 'auto',
  //         overflowX: 'hidden',
  //         scrollbarWidth: 'thin',
  //         scrollbarColor: '#2e75b5 #f5f5f5',
  //       }}
  //     >
  //       <style>
  //         {`
  //         /* Custom Scrollbar for Webkit browsers */
  //         div::-webkit-scrollbar {
  //           width: 8px;
  //         }
  //         div::-webkit-scrollbar-track {
  //           background: #f5f5f5;
  //           border-radius: 10px;
  //         }
  //         div::-webkit-scrollbar-thumb {
  //           background: #2e75b5;
  //           border-radius: 10px;
  //         }
  //         div::-webkit-scrollbar-thumb:hover {
  //           background: #1d5a92;
  //         }
  //       `}
  //       </style>
  //       {tableArray?.map((row) => (
  //         <Grid
  //           key={row.Vendor_Code} // Use a unique identifier like Vendor_Code
  //           container
  //           spacing={2}
  //           style={{
  //             borderBottom: '1px solid #ccc',
  //             padding: '5px 0',
  //             backgroundColor: row.Supplier_Status === 'Re-Submission Awaited' ? '#fff3cd' : 'transparent',
  //           }}
  //         >
  //           <Grid item xs={3} style={{ textAlign: 'center' }}>
  //             <label className={styles.label1}> {row.commodity || '-'}</label>
  //           </Grid>
  //           <Grid item xs={3} style={{ textAlign: 'center' }}>
  //             <label className={styles.label1}> {row.Vendor_Code || '-'}</label>
  //           </Grid>
  //           <Grid item xs={3} style={{ textAlign: 'center' }}>
  //             <label className={styles.label1}> {row.NAME1 || '-'}</label>
  //           </Grid>
  //           <Grid item xs={3} style={{ textAlign: 'center' }}>
  //             <label className={styles.label1}>{row.Supplier_Status || '-'}</label>
  //           </Grid>
  //         </Grid>
  //       ))}
  //     </div>

  //     {/* Footer */}

  //   </div>
  // );

  const renderList = () => (
    <div style={{ margin: '0px', border: '1px solid #ccc', borderRadius: '5px', overflow: 'hidden' }}>
      {/* Header */}
      <div
        style={{
          borderBottom: '2px solid #2e75b5',
          padding: '10px 0',
          backgroundColor: '#f9f9f9',
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={1} style={{ textAlign: 'center', fontWeight: 'bold' }}>
            <label className={styles.label}>Sr No</label>
          </Grid>
          <Grid item xs={3} style={{ textAlign: 'center', fontWeight: 'bold' }}>
            <label className={styles.label}>Commodity</label>
          </Grid>
          <Grid item xs={2} style={{ textAlign: 'center', fontWeight: 'bold' }}>
            <label className={styles.label}>Vendor Code</label>
          </Grid>
          <Grid item xs={3} style={{ textAlign: 'center', fontWeight: 'bold' }}>
            <label className={styles.label}>Name</label>
          </Grid>
          <Grid item xs={3} style={{ textAlign: 'center', fontWeight: 'bold' }}>
            <label className={styles.label}>Supplier Status</label>
          </Grid>
        </Grid>
      </div>
  
      {/* Scrollable Middle Section */}
      <div
        style={{
          maxHeight: '385px',
          overflowY: 'auto',
          overflowX: 'hidden',
          scrollbarWidth: 'thin',
          scrollbarColor: '#2e75b5 #f5f5f5',
        }}
      >
        <style>
          {`
          /* Custom Scrollbar for Webkit browsers */
          div::-webkit-scrollbar {
            width: 8px;
          }
          div::-webkit-scrollbar-track {
            background: #f5f5f5;
            border-radius: 10px;
          }
          div::-webkit-scrollbar-thumb {
            background: #2e75b5;
            border-radius: 10px;
          }
          div::-webkit-scrollbar-thumb:hover {
            background: #1d5a92;
          }
        `}
        </style>
        {tableArray?.map((row, index) => (
          <Grid
            key={row.Vendor_Code} // Use a unique identifier like Vendor_Code
            container
            spacing={2}
            style={{
              borderBottom: '1px solid #ccc',
              padding: '5px 0',
              backgroundColor: row.Supplier_Status === 'Re-Submission Awaited' ? '#fff3cd' : 'transparent',
            }}
          >
            <Grid item xs={1} style={{ textAlign: 'center' }}>
              <label className={styles.label1}>{index + 1}</label>
            </Grid>
            <Grid item xs={3} style={{ textAlign: 'center' }}>
              <label className={styles.label1}>{row.commodity || '-'}</label>
            </Grid>
            <Grid item xs={2} style={{ textAlign: 'center' }}>
              <label className={styles.label1}>{row.Vendor_Code || '-'}</label>
            </Grid>
            <Grid item xs={3} style={{ textAlign: 'center' }}>
              <label className={styles.label1}>{row.NAME1 || '-'}</label>
            </Grid>
            <Grid item xs={3} style={{ textAlign: 'center' }}>
              <label className={styles.label1}>{row.Supplier_Status || '-'}</label>
            </Grid>
          </Grid>
        ))}
      </div>
    </div>
  );
  

  return (
    <>
      <div className={styles.container} style={{ boxSizing: 'border-box' }}>
        <div>
          <div
            className={styles.backButtonLastRefreshedDateWrapper}
            style={{ marginTop: '10px' }}
          >
            <BackButton
              action='Back'
              handleClick={redirectToApplicationLandingPage}
              style={{ marginBottom: '0px' }}
            />
            <div
              style={{ width: '100%' }}
            // style={{ display: 'flex', flexDirection: 'row-reverse' }}
            >
              <div className={styles.topDiv}>
                <div
                  className={styles.processHeader}
                  style={{ display: 'flex', height: '3em' }}
                >
                  <div style={{ width: '100%' }}>
                    <CustomTab title='AFS Data' withOutCount isSelected />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className={styles.formContainer}>
            <CustomFormGroup body={renderHTML()} />
          </div>
        </div>
        {/* {flag && (
          <div
            className={styles.tableView}
            style={
              {
                // height: '86%',
                // padding: '0 2em'
              }
            }
          >
            <div className={styles.formContainer1}>
              <CustomFormGroup body={renderOther()} />
            </div>
            <div className={styles.formContainer1}>
              <CustomFormGroup body={renderOther1()} />
            </div>
          </div>
        )} */}
        {show1 && (
          <div className={styles.tableView}>
            <div className={styles.formContainer1}>
              {activeTable === 'data' && <CustomFormGroup body={renderOther()} />}
              {activeTable === 'list' && <CustomFormGroup body={renderList()} />}
            </div>
          </div>
        )}
      </div>
      {/* <Button
            className={clsx(
              styles.actionButton,
              styles.primaryActionButton,
            )}
            variant="primary"
            // onClick={onDelete}
          >
            Yes
          </Button> */}
    </>
  )
}

export default withAllowedOperationsProvider(AfsDashboard, RESOURCE_TYPE.PROJECT)
